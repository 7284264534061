import React, { useState, useEffect } from "react";

// Libraries
import { Link, useNavigate } from "react-router-dom";
import { AuthRoute } from "@hybris-software/use-auth";
import validator from "validator";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";

// Components
import Button from "../../Commons/Button/Button";
import AuthLoader from "../../AuthLoader/AuthLoader";
import Invitation from "../Invitation/Invitation";

// Images
import LogoPoliant from "../../../Assets/img/logos/poliant_logo.webp";
import Visible from "../../../Assets/img/icon/visibility.png";
import NotVisible from "../../../Assets/img/icon/not_visibility.png";
import Verified from "../../../Assets/img/icon/verified.png";

// Styles
import "react-phone-number-input/style.css";
import Style from "./Registration.module.css";

import axios from "axios";
import Config from "../../../config.js";

const Registration = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [confirmemail, setConfirmemail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [terms, setTerms] = useState(false);
  const [logged, setLogged] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState(false);
  const numberPhone = formatPhoneNumberIntl(phoneNumber);
  const [checkPassword, setCheckPassword] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [emailOneError, setEmailOneError] = useState("");
  const [emailTwoError, setEmailTwoError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [visibilityCode, setVisibilityCode] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [applyNow, setApplyNow] = useState(false);
  const [singUpwhitCode, setSingUpwhitCode] = useState(false);

  const [checkUsername, setCheckUsername] = useState(null);

  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  var user = {
    email: email,
    username: username,
    password: password,
    password2: confirmPassword,
    first_name: name,
    last_name: surname,
    phone: numberPhone,
    type: "PERSONAL", //COMPANY o PERSONAL case sensitive
    vat: "",
    company_name: "",
    company_registration_country: "",
    company_address: "",
  };

  useEffect(() => {
    document.title = "Poliant | Registration";

    //+ Parameters code referral
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codeExist = urlParams.get("ref");

    if (codeExist !== false) {
      setReferralCode(codeExist);
    } else {
      setReferralCode("");
    }

  }, [navigate]);

  const onSubmit = (e) => {

    setApplyNow(true);

    const url = Config.base_url + "auth/registration/";

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(url, user, config)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          localStorage.setItem("token", response.data.key);
          navigate("/auth/register-survey");
        }
      })
      .catch((error) => {
        if (error.response.request.status === 400) {
          setApplyNow(false);
          turnBack();


          if (error.response.data) {
            setErrors(error.response.data)
          }

        }
      });
  };

  function controlUsername(e) {
    const usernameRegex = /^[A-Za-z0-9._-]+$/;
    return usernameRegex.test(e);
  }

  return (
    <AuthRoute
      forLoggedUser={false}
      loader={<AuthLoader />}
      action={() => { navigate("/") }}
    >
      {logged && (
        <section id="registration_form">
          <img className={Style.logo} src={LogoPoliant} alt="Poliant logo" />

          <div className={Style.registration}>
            <div className={Style.container}>
              <div className={Style.card}>

                <div className={Style.header}>
                  <h2>Create your account</h2>
                  <p>
                    Would you like to register as a Company Account?{" "}
                    <Link to="/auth/register-company">Sign up Here</Link>
                  </p>
                </div>

                {/* Form Registration*/}
                <div>
                  <div className="form">
                    {/* name  surname*/}
                    <label htmlFor="name">Full name</label>
                    <div className="d-flex">
                      <div>
                        <input
                          autoComplete="new-password"
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Mark"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <div className="error_input mt-0">{errors.first_name && errors.first_name}</div>
                      </div>

                      <div>
                        <input
                          autoComplete="new-password"
                          id="surname"
                          name="surname"
                          type="text"
                          className="form-control ms-2"
                          placeholder="Willians"
                          value={surname}
                          onChange={(e) => setSurname(e.target.value)}
                        />
                        <div className="error_input mt-0">{errors.last_name && errors.last_name}</div>

                      </div>

                    </div>
                    {/* username */}
                    <div className="form-group mt-2">
                      <label htmlFor="username">Your username</label>
                      <input
                        autoComplete="new-password"
                        id="username"
                        name="username"
                        type="text"
                        className="form-control"
                        placeholder="Choose Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value.toLowerCase())}
                        onInput={(e) => setCheckUsername(controlUsername(e.target.value))}
                      />
                      <div className="error_input mt-0">{errors.username && errors.username}</div>
                      {checkUsername === false && (
                        <div className="error_input">Allowed all letters, numbers and: '-' , "_" , "."</div>
                      )}

                    </div>
                    {/* email */}
                    <div className="form-group mt-2">
                      <label htmlFor="email">
                        Your email
                        <span className="error_input ms-2">{emailError}</span>
                      </label>
                      <input
                        autoComplete="new-password"
                        id="email"
                        name="email"
                        type="email"
                        required
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="markwillians@site.com"
                        onInput={() => sameEmail()}
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                      <div className="error_input mb-0">{emailOneError}</div>
                      <div className="error_input mt-0">{errors.email && errors.email}</div>
                    </div>
                    <div className="form-group mt-2">
                      <label htmlFor="confirmemail">Confirm your Email</label>
                      <input
                        autoComplete="new-password"
                        id="confirmemail"
                        name="confirmemail"
                        type="email"
                        required
                        className="form-control"
                        placeholder="markwillians@site.com"
                        value={confirmemail}
                        onChange={(e) => setConfirmemail(e.target.value)}
                        onInput={() => sameEmail()}
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                      <div className="error_input"> {emailTwoError}</div>
                    </div>
                    {/* Phone */}
                    <div className=" mt-2 mb-3 ">
                      <div className="flex-grow-2" id="phone_register">
                        <label htmlFor="phone">Your Telephone</label>
                        <PhoneInput
                          id="phone"
                          className=""
                          placeholder="Prefix and Number"
                          value={numberPhone}
                          onChange={setPhoneNumber}
                          error={
                            phoneNumber
                              ? isValidPhoneNumber(phoneNumber)
                                ? undefined
                                : "Invalid phone number"
                              : "Phone number required"
                          }
                        />
                        <div className="error_input mt-0">{errors.phone && errors.phone}</div>

                        {numberPhone !== "" ? (
                          phoneNumber && isPossiblePhoneNumber(phoneNumber) ? (
                            <img
                              className="verified_number_phone"
                              src={Verified}
                              alt=""
                              width={16}
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {/* Separetor */}
                    <div className={Style.separator_border}></div>

                    {/* Password */}
                    {/* input password */}
                    <div className="form-group mt-3">
                      <label
                        className="position-relative d-flex"
                        htmlFor="registration_newpassword"
                      >
                        Password
                        <span
                          className={Style.info_icon}
                          onMouseEnter={() => regseePopUp()}
                          onMouseLeave={() => regremovePopUp()}
                        >
                          i
                        </span>
                        <div
                          id="registration_popup_pass"
                          className={Style.popup_password}
                        >
                          <div className={Style.popup_password_text}>
                            <p className="mb-1">
                              Your password must be a minimum of 10 characters.
                            </p>
                            <p className="mb-1">
                              Your password must contain at least one capital
                              letter (ex: A, B, etc.)
                            </p>
                            <p className="mb-1">
                              Your password must contain at least one lowercase
                              letter.
                            </p>
                            <p className="mb-1">
                              Your password must contain at least one symbol.
                            </p>
                            <p className="mb-1">
                              Your password must contain at least one number digit
                              (ex: 0, 1, 2, 3, etc.)
                            </p>
                          </div>
                        </div>
                      </label>
                      <div className="position-relative">
                        <input
                          autoComplete="new-password"
                          id="registration_newpassword"
                          name="registration_newpassword"
                          type="password"
                          className="form-control"
                          placeholder="10+ character required"
                          minLength={10}
                          onInput={() => regstrongTest()}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />

                        <img
                          id="registration_newtogglePassword"
                          className={Style.visibility}
                          src={NotVisible}
                          alt=""
                          width={16}
                          onClick={() => regshowNewPassword()}
                        />

                        <img
                          className="registration_verified_password"
                          src={Verified}
                          alt=""
                          width={16}
                        />
                      </div>

                      {/* Check password */}
                      <div
                        className={Style.password_efficency}
                        id="registration_password_info"
                      ></div>
                      <p
                        id="registration_password_info_text"
                        className={Style.password_info_text + " mb-1"}
                      ></p>

                      <label htmlFor="registration_newconfirmpassword">
                        Confirm password
                      </label>
                      <div className="position-relative">
                        <input
                          autoComplete="new-password"
                          id="registration_newconfirmpassword"
                          name="registration_newconfirmpassword"
                          type="password"
                          className="form-control"
                          placeholder="10+ character required"
                          minLength={10}
                          onInput={() => regsamePassword()}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                        <img
                          id="registration_newconfirmtogglePassword"
                          className={Style.visibility}
                          src={NotVisible}
                          alt=""
                          width={16}
                          onClick={() => regshowConfirmNewPassword()}
                        />
                        <img
                          className="registration_verified_password"
                          src={Verified}
                          alt=""
                          width={16}
                        />
                      </div>
                    </div>
                    {/* Terms*/}
                    <div className={Style.checkboxInput}>
                      <input
                        autoComplete="new-password"
                        className="me-2"
                        type="checkbox"
                        name="acceptTerms"
                        id="acceptTerms"
                        defaultChecked={terms}
                        onChange={(e) => setTerms(e.target.checked)}
                      />
                      <label htmlFor="acceptTerms">
                        I accept the{" "}
                        <a href="#!">Term and Conditions</a>{" "}
                        and{" "}
                        <a href="#!">Policy Privacy</a>
                      </label>
                    </div>

                    <Button
                      disabled={!(name !== "" &&
                        surname !== "" &&
                        username !== "" &&
                        email !== "" &&
                        confirmemail !== "" &&
                        numberPhone !== "" &&
                        password !== "" &&
                        confirmPassword !== "" &&
                        terms === true &&
                        checkPassword === true &&
                        checkUsername === true &&
                        checkEmail === true)}
                      onClick={() => showCodePopup()}
                    >
                      Sign up
                    </Button>

                    <p className={Style.signIn}>
                      Already have an account?{" "}
                      <Link to="/auth/login">
                        Sign in here
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {visibilityCode && (
        <Invitation
          user={user}
          referralCode={referralCode}
          onSubmit={onSubmit} // Funzione registrazione
          applyNow={applyNow}
          setApplyNow={setApplyNow}
          turnBack={turnBack}
          setVisibilityCode={setVisibilityCode}
          singUpwhitCode={singUpwhitCode}
          setSingUpwhitCode={setSingUpwhitCode}

          setErrors={setErrors}
        />
      )}
    </AuthRoute>
  );

  function showCodePopup() {
    setLogged(false)
    setVisibilityCode(true);
  }

  function turnBack() {
    setVisibilityCode(false);
    setLogged(true)
  }

  function regseePopUp() {
    document.getElementById("registration_popup_pass").style.display = "block";
  }
  function regremovePopUp() {
    document.getElementById("registration_popup_pass").style.display = "none";
  }

  function regshowNewPassword() {
    const password = document.getElementById("registration_newpassword");
    const icon = document.getElementById("registration_newtogglePassword");
    password.type === "password"
      ? (password.type = "text")
      : (password.type = "password");
    password.type === "password"
      ? (icon.src = NotVisible)
      : (icon.src = Visible);
  }

  function regshowConfirmNewPassword() {
    const password = document.getElementById("registration_newconfirmpassword");
    const icon = document.getElementById(
      "registration_newconfirmtogglePassword"
    );
    password.type === "password"
      ? (password.type = "text")
      : (password.type = "password");
    password.type === "password"
      ? (icon.src = NotVisible)
      : (icon.src = Visible);
  }

  /* Controllo pass */
  function regstrongTest() {
    const password = document.getElementById("registration_newpassword");
    const passwordInfo = document.getElementById("registration_password_info");
    const passwordInfoText = document.getElementById(
      "registration_password_info_text"
    );

    if (password.value.length < 10) {
      passwordInfoText.innerHTML = "Weak. Must cotain at least 10 characters";
      passwordInfo.style.backgroundColor = "#EA4479";
      passwordInfo.style.width = "20%";
      passwordInfo.style.display = "block";
    }
    // se contine piu di 10 caratteri
    else if (password.value.length >= 10) {
      //deve contenere una lettera maiuscola
      if (!password.value.match(/[a-z]/)) {
        passwordInfoText.innerHTML =
          "So-so. Must contain at least 1 lowercase letter";
        passwordInfo.style.backgroundColor = "#F0DC57";
        passwordInfo.style.width = "50%";
        passwordInfo.style.display = "block";
      }
      //deve contenere una lettera minuscola
      else if (!password.value.match(/[A-Z]/)) {
        passwordInfoText.innerHTML =
          "So-so. Must contain at least 1 capital letter";
        passwordInfo.style.backgroundColor = "#F0DC57";
        passwordInfo.style.width = "50%";
        passwordInfo.style.display = "block";
      }
      //deve contenere un carattere speciale
      else if (!password.value.match(/[^a-zA-Z0-9]/g)) {
        passwordInfoText.innerHTML = "Almost. Must contain special symbol.";
        passwordInfo.style.backgroundColor = "#55ADD9";
        passwordInfo.style.width = "70%";
        passwordInfo.style.display = "block";
      }
      //deve contenere un numeros
      else if (!password.value.match(/[0-9]/)) {
        passwordInfoText.innerHTML = "Good. Must contain at least 1 number";
        passwordInfo.style.backgroundColor = "#55ADD9";
        passwordInfo.style.width = "90%";
        passwordInfo.style.display = "block";
      } else {
        passwordInfoText.innerHTML = "Awesome! You have a secure password";
        passwordInfo.style.backgroundColor = "#5BC142";
        passwordInfo.style.width = "100%";
        passwordInfo.style.display = "block";
      }
    }

    regsamePassword();
  }

  /* Verificato pass con sblocco bottone */
  function regsamePassword() {
    const password = document.getElementById("registration_newpassword");
    const passwordConfirm = document.getElementById(
      "registration_newconfirmpassword"
    );
    const verified = document.querySelectorAll(
      ".registration_verified_password"
    );
    const newPassEye = document.getElementById(
      "registration_newtogglePassword"
    );
    const confirmPassEye = document.getElementById(
      "registration_newconfirmtogglePassword"
    );


    //se le due password sono uguali
    if (
      password.value === passwordConfirm.value &&
      password.value.length >= 10 &&
      password.value.match(/[0-9]/) &&
      password.value.match(/[A-Z]/) &&
      password.value.match(/[a-z]/i) &&
      password.value.match(/[^a-zA-Z0-9]/g)
    ) {
      newPassEye.style.right = "25px";
      confirmPassEye.style.right = "25px";
      verified.forEach((element) => {
        element.style.display = "block";
      });
      setCheckPassword(true);
    } else {
      newPassEye.style.right = "15px";
      confirmPassEye.style.right = "15px";
      verified.forEach((element) => {
        element.style.display = "none";
      });
      setCheckPassword(false);
    }
  }

  function sameEmail() {
    var primaMail = document.getElementById("email").value;
    var secondaMail = document.getElementById("confirmemail").value;

    if (email !== "") {
      !validator.isEmail(primaMail)
        ? setEmailOneError("Invalid Email")
        : setEmailOneError("");
    }
    if (confirmemail !== "") {
      !validator.isEmail(secondaMail)
        ? setEmailTwoError("Invalid Email")
        : setEmailTwoError("");
    }

    if (email === "" && confirmemail === "") {
      setEmailError("");
    }

    if (
      email !== "" &&
      confirmemail !== "" &&
      validator.isEmail(primaMail) &&
      validator.isEmail(secondaMail)
    ) {
      if (primaMail === secondaMail) {
        setCheckEmail(true);
      }
      if (primaMail !== secondaMail) {
        setCheckEmail(false);
        setEmailError("Email not match");
      } else {
        setEmailError("");
      }
    }
  }
};

export default Registration;
