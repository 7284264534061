import React, { useState, useEffect } from "react";

// Libraries
import AuthCode from "react-auth-code-input";
import { useNavigate } from "react-router-dom";
import useQuery from "@hybris-software/use-query";

// Components
import Button from "../../Commons/Button/Button";

// Styles
import Style from "./TwoFactor.module.css";

// Images
import TwoFactorIcon from "../../../Assets/img/two-factor.png";
import LogoPoliant from "../../../Assets/img/logos/poliant_logo.webp";

const TwoFactor = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const handleOnChange = (res) => { setCode(res); };

  useEffect(() => {
    document.title = "Poliant | 2FA";
  }, []);

  //+ Call RESEND OTP CODE 
  const verifyCode = useQuery({
    url: "accounts/otp-verification/",
    method: "POST",
    executeImmediately: false,
    onSuccess: () => {
      navigate("/")
    },
    onError: () => {
      localStorage.setItem("otp_verified", "false");
    }
  })

  //+ Call RESEND OTP CODE 
  const resendNewCode = useQuery({
    url: "accounts/otp-verification/",
    method: "GET",
    executeImmediately: false,
  })

  return (
    <section>
      <img className={Style.logo} src={LogoPoliant} alt="Poliant logo" />
      <div className={Style.authCode}>
        <div className={Style.container}>
          <div className={Style.header}>
            <img src={TwoFactorIcon} alt="Icon two factor" />
            <h2>2-step Verification</h2>
            <p> We sent a verification code to your email. Enter the code from the email in the field below.</p>
          </div>

          <div className={resendNewCode.isSuccess ? Style.success : Style.error}>
            {resendNewCode.isSuccess
              ? resendNewCode.response.data.OK
              : resendNewCode.isError && "New Code: Something went wrong"
            }
          </div>

          <div className={Style.inputTwoFactor}>
            <AuthCode allowedCharacters='numeric' onChange={handleOnChange} />
          </div>

          <div className={Style.buttonBox}>
            <Button
              className={Style.button}
              disabled={code.length !== 6}
              isLoading={verifyCode.isLoading}
              onClick={() => { verifyCode.executeQuery({ otp: code, method: "LOGIN", }) }}
            >
              Verify Code
            </Button>
            <div className={Style.error}>{verifyCode.isError && (verifyCode.error.response.data.Error || "Something went wrong")}</div>

            <p className={Style.resendCode}>
              Haven’t received it?{" "}
              <span className="text-primary" onClick={() => resendNewCode.executeQuery()}>Resend a new code.</span>
            </p>
          </div>
        </div>
      </div>
    </section >
  );
};

export default TwoFactor;
