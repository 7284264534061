import React, { useState } from "react";
import Style from "./EmailProfile.module.css";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import validator from "validator";

import Config from "../../../config";

const EmailProfile = (props) => {
  const [newEmail, setNewEmail] = useState("");

  //* Cambiare endpoint
  const datas = {
    title: "Confirm email change with 2-step Verification",
    subtitle: "We have sent you an email with the two-factor authentication code to confirm the change",
    button: "Confirm Email Change",
    endpoint: Config.base_url + "accounts/otp-verification/",
    method: "CHANGE_EMAIL",
  };

  function startEmailVerification() {
    var emailData = {
      new_email: newEmail,
    };

    const url = Config.base_url + "my-profile/change-email/";

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(url, emailData, config)
      .then((response) => {
        if (response.status === 200) {
          const verificationBox = document.getElementById("two_step_verified");
          const layover = document.getElementById("layover");
          verificationBox.style.display = "block";
          layover.style.display = "block";
          props.setPopupState(datas);
        }
      })
      .catch((error) => {
      });
  }

  return (
    <>
      <section id="email_profile">
        <div style={{ paddingTop: 24 }}></div>
        <div className={Style.card_profile}>
          <h2 className={Style.card_title}>Email</h2>
          <p className={Style.card_info_text}>
            Your current email address is <strong>{props.email}</strong>
            {props.isPending === null && props.newEmail !== null ? (
              <>
                <span className="badge badge-fill bg-danger-badge text-danger">
                  New email is Pending
                </span>
              </>
            ) : (
              <></>
            )}
          </p>

          {/* //? if not confirmed */}
          {/* //! TODO button resend email trigger */}
          {/* <p className={Style.card_info_text}>Your current email address is <strong>mark@site.com</strong> and <span className='unconfirmed'><strong>has been confirmed.</strong></span> <a href="
                #!" className='text-primary'>Send confirmation e-mail</a</p>  */}

          <Row className="mb-4">
            <Col sm={3} className={Style.label_box}>
              <label htmlFor="newEmailAdress" className={Style.col_form_label}>
                New email address
              </label>
            </Col>
            <Col sm={9}>
              <div className="input-group input-group-sm-vertical">
                <input
                  type="email"
                  className={Style.form_control}
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  name="newEmailAdress"
                  id="newEmailAdress"
                  placeholder="Enter new email address"
                  aria-label="Enter new email address"
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-4 mb-2">
            <Col sm={12} className={Style.position_button}>
              {newEmail !== "" ? (
                validator.isEmail(newEmail) && newEmail !== props.email ? (
                  <button
                    className={Style.button}
                    onClick={() => startEmailVerification()}
                  >
                    Save changes
                  </button>
                ) : (
                  <button disabled className={Style.button}>
                    Save changes
                  </button>
                )
              ) : (
                <button disabled className={Style.button}>
                  Save changes
                </button>
              )}
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default EmailProfile;
