import React from 'react'
import Style from './AnalyticsReferral.module.css'
import { Row, Col, Container } from 'react-bootstrap'


const AnalyticsReferral = () => {
    return (
        <>
            <section className={Style.referral_analytics}>
                <Container>
                    <h2>Analytics</h2>
                    <div className={Style.filter_analytics}>
                        <button>Arbitrage Pool</button>
                        <button>Always</button>
                        <button>Yesterday</button>
                        <button>This Week</button>
                        <button>This Month</button>
                    </div>

                    <Row className={Style.statistics}>
                        <Col xs={12} sm={4}>
                            <div className='p-4'>
                                <div className={Style.title_box}>Your Total Rewards</div>
                                <div className={Style.number_box}>******* POLI</div>
                                <div className={Style.increment_box}>****** POLI</div>

                            </div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <div className='p-4'>
                                <div className={Style.title_box}>Club Member with Active Pools</div>
                                <div className={Style.number_box}>******</div>
                                <div className={Style.increment_box}>*******</div>
                            </div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <div className="p-4">
                                <div className={Style.title_box}>Your Direct Member</div>
                                <div className={Style.number_box}>******</div>
                                <div className={Style.increment_box}>*******</div>
                            </div>
                        </Col>
                    </Row>
                    <p>
                        * Data update time refers to the time zone UTC+4. The data maintenance time is from 3:00 to 5:00 (UTC+4) every day. During this period, the calculation of the day's data is based on the previous day's assets. After maintenance, all data will be displayed correctly.
                    </p>
                    <p>
                        * Statement: Due to the complexity of the financial data, discrepancies and delays may occur. The data displayed above is provided for information purposes only. We apologise for any inconvenience.
                    </p>
                </Container>
            </section>

        </>
    )
}

export default AnalyticsReferral