import React from "react";
import Sidebar from "../Components/Commons/Sidebar/Sidebar";
import Header from "../Components/Commons/Header/Header";
import { Outlet, useNavigate } from "react-router-dom";

const MainLayout = () => {
  const navigate = useNavigate();
  return (
    <>
      <div id="layover" style={{ overflow: 'scroll' }} onClick={(e) => {
        let contract = document.getElementById("sign_contract");
        if (contract) {
          e.target.style.display = "none";
          contract.style.display = "none";
        }
      }}>
        <div id="popupGoSign">

          <div className="body_popup">

            <h2>INFORMATION</h2>

            <p>To be able to use the platform's functionalities, you must sign the 'Intermediary Agreement'. Click 'Confirm' to proceed.</p>

            <div className='d-flex'>
              <button className="button_cancel" onClick={() => {
                const layover = document.getElementById("layover");
                layover.style.display = "none";
              }}>Cancel</button>

              <button className="button_confirm" onClick={() => navigate('/auth/sign-intermediary')}>Confirm</button>

            </div>

          </div>


        </div>
      </div>

      <div className="position-relative" onMouseUp={(e) => {
        const menu_header = document.getElementById("menu_header");
        const menu_link = document.getElementById("codelink");
        const menuLanguage = document.getElementById("language");
        const menuLanguageMobile = document.getElementById("language_mobile");
        const legal = document.getElementById("terms");
        const legalMobile = document.getElementById("terms_mobile");
        const mode = document.getElementById("mode");
        const modeMobile = document.getElementById("mode_mobile");
        const menu_single_sign = document.getElementsByClassName('setting_sign');
        const club_menus = document.getElementsByClassName('opened_menu');

        const general_contract = document.getElementById("general_contract");
        const pool_contract = document.getElementById("pool_contract");
        const custom_contract = document.getElementById("custom_contract");

        if (e.target.id !== "laguage_menu_mobile") {
          if (menuLanguageMobile.classList.contains("show_language")) {
            menuLanguageMobile.classList.remove("show_language");
          }
        }
        if (e.target.id !== "mode_menu_mobile") {
          if (modeMobile.classList.contains("show_language")) {
            modeMobile.classList.remove("show_language");
          }
        }
        if (e.target.id !== "legal_menu_mobile") {
          if (legalMobile.classList.contains("show_language")) {
            legalMobile.classList.remove("show_language");
          }
        }
        if (e.target.id !== "mode_menu") {
          if (mode.classList.contains("show_language")) {
            mode.classList.remove("show_language");
          }
        }
        if (e.target.id !== "legal_menu") {
          if (legal.classList.contains("show_language")) {
            legal.classList.remove("show_language");
          }
        }
        if (e.target.id !== "laguage_menu") {
          if (menuLanguage.classList.contains("show_language")) {
            menuLanguage.classList.remove("show_language");
          }
        }

        if (e.target.id !== "codelink_menu" && e.target.id !== "svg_codelink") {
          if (menu_link.classList.contains("show_header")) {
            menu_link.classList.remove("show_header");
          }
        }
        if (e.target.id !== "profile_avatar" && e.target.id !== "status_account") {
          if (menu_header.classList.contains("show_header")) {
            menu_header.classList.remove("show_header");
          }
        }

        if (!e.target.classList.contains("close_all_sing")) {
          for (let i = 0; i < menu_single_sign.length; i++) {
            if (menu_single_sign[i].classList.contains("show")) {
              menu_single_sign[i].classList.remove("show");
            }
          }
        }

        if (!e.target.classList.contains("opened_menu")) {
          for (let i = 0; i < club_menus.length; i++) {
            if (club_menus[i].classList.contains("show_folder_club")) {
              club_menus[i].classList.remove("show_folder_club");
            }
          }
        }


        if (e.target.id !== "svg_general" && e.target.id !== "general_circle" && e.target.id !== "general_box_menu" && general_contract) {
          if (general_contract.classList.contains("show_folder")) {
            general_contract.classList.remove("show_folder");
          }
        }

        if (e.target.id !== "svg_pool" && e.target.id !== "pool_circle" && e.target.id !== "pool_box_menu" && pool_contract) {
          if (pool_contract.classList.contains("show_folder")) {
            pool_contract.classList.remove("show_folder");
          }
        }

        if (e.target.id !== "svg_custom" && e.target.id !== "custom_circle" && e.target.id !== "custom_box_menu" && custom_contract) {
          if (custom_contract.classList.contains("show_folder")) {
            custom_contract.classList.remove("show_folder");
          }
        }
      }
      }>
        <div className="laybase">
          <div className="lay-left"></div>
          <div className="lay-left-mobile"></div>
          <div className="lay-right">
            <div className="head-fake"></div>
          </div>
        </div>

        <div className="new-container">
          <div className="fullpage_container">
            <main className="main_container">
              <Sidebar />
              <div className="main_container_box">
                <Header />
                <div className="main_content scrollbar">
                  <div id="layover_general"></div>
                  <Outlet />
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
