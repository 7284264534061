import React, { useState } from 'react'
import Style from './ChangeRatio.module.css'
import { Row, Col } from 'react-bootstrap'
import CloseIcon from '../../../Assets/img/icon/close-icon.png'
import axios from 'axios'
import Config from '../../../config'
import AvatarMember from "../../../Assets/img/avatar-member.png";

const ChangeRatio = (props) => {

    const [rangeValue, setRangeValue] = useState(0);
    var minPerc = (props.maxPercGain - props.percentageSingle).toFixed(1) + ' %';
    const [error, setError] = useState('');
    const [irreversible, setIrreversible] = useState(false);
    const [afterChange, setAfterChange] = useState(false);
    const [displayed, setDisplayed] = useState(false);

    function closeChanger() {
        const mainContent = document.getElementsByClassName("main_content");
        mainContent[0].classList.remove('unset_scroll');
        document.getElementById('change_box_rewards').style.display = 'none';
        document.getElementById('layover').style.display = 'none';
        setError('');
        document.getElementById('member_percentage').value = '';
    }

    const datas = {
        title: "Confirm Change Ratio with 2-step Verification",
        subtitle:
            "We have sent you an email with the two-factor authentication code to confirm the changes",
        button: "Confirm Change Ratio",
        endpoint: Config.base_url + "accounts/otp-verification/",
        method: "CHANGE_PERCENTAGE",
    };


    function changeGain(id, gain) {

        var data = {
            id_first_line: id,
            perc_gain_value: gain,
        }

        const url =
            Config.base_url + "my-profile/change-perc-gain-value/";
        const token = localStorage.getItem("token");

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Token ${token}`,
            },
        };
        axios
            .post(url, data, config)
            .then((response) => {
                if (response.status === 200) {
                    setError('');
                    const verificationBox = document.getElementById("two_step_verified");
                    const changeRatio = document.getElementById("change_box_rewards");
                    const layover = document.getElementById("layover");
                    changeRatio.style.display = "none";
                    verificationBox.style.display = "block";
                    layover.style.display = "block";
                    props.setPopupState(datas);
                }
            })
            .catch((error) => {
                setError(error.response.data.error);
            });
    }

    return (
        <>
            <div id='change_box_rewards' className={Style.box_change_ratio}>
                <div className={Style.box_change_ratio_inner}>

                    <div className='text-right pb-2'>
                        <img style={{ cursor: 'pointer' }} src={CloseIcon} alt="Close" onClick={() => closeChanger()} />
                    </div>

                    <div className={Style.info_Ratio_rewards}>

                        <h2>Change Ratio Rewards Arbitrage Pool</h2>
                        <span>These changes will apply to:</span>

                        <div className={Style.box_data_user}>
                            <div className={Style.avatar_and_info}>
                                {props.singleAvatar
                                    ?
                                    <div className={Style.avatar} style={{ backgroundImage: `url(${props.singleAvatar})` }}></div>
                                    :
                                    <div className={Style.avatarPlaceholder} style={{ backgroundImage: `url(${AvatarMember})` }}></div>

                                }
                                <div className={Style.info}>
                                    <small>MEMBER:</small>
                                    <div className={Style.name}>{props.singleName}</div>
                                    <div className={Style.username}>@{props.singleUsername}</div>
                                    <div className={Style.id_user}>ID: {props.singlePolIdUser}  {props.singleId}</div>
                                </div>
                            </div>
                            <div className={Style.ratio_reward}>
                                <small>Ratio Rewards:</small>
                                <div className={Style.percentage}>
                                    {props.percentageSingle} %
                                    <div>in POLI</div>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <p className={Style.info_attention}>
                            This user's current Ratio Reward is <strong>{props.percentageSingle} %</strong> in POLI.<br />
                            You can increase the RATIO Rewards of members directly linked to your club.<br />
                            ATTENTION: This change is final and unique, once the RATIO of Rewards of this user has been changed and increased, there will be no turning back.<br />
                            ***You will only be able to increase a member's Rewards RATIO and not decrease it.
                        </p>

                        <div className={Style.title_choose}>Update the Rewards Ratio Arbitrage Pool:</div>

                        <div>
                            <Row className='mb-0 mt-3'>
                                <Col sm={12} className='d-flex align-items-center'>
                                    <div className="input-group input-group-sm-vertical">
                                        <input type="range" className={Style.range_input} step={0.1} min='0' max={props.maxPercGain} defaultValue={rangeValue} onChange={(e) => {
                                            setRangeValue(e.target.value);
                                            props.setPercentageSingle(e.target.value);
                                        }} name="referralrewards" id="referralrewards" />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mb-2'>
                                <Col sm={12} className={Style.inputBox}>
                                    <div className='d-flex align-items-center text-silver'>
                                        Mine:
                                        <input type="number" className={Style.input_set_percentage + ' form-control mx-2 text-silver bold-700'} readOnly placeholder={minPerc} />
                                        in POLI
                                    </div>
                                    <div className='d-flex align-items-center text-silver'>
                                        Member:
                                        <input type="number" id='member_percentage' placeholder={props.percentageSingle + ' %'} className={Style.input_set_percentage + ' form-control mx-2 bold-700 '} onChange={(e) => setRangeValue(e.target.value).replace(',', '.')} />
                                        in POLI
                                    </div>
                                </Col>
                                <div className='error_input mt-3'>{error}</div>
                            </Row>
                        </div>


                        <div className='mt-3'>
                            <div className={Style.checkbox_label}>
                                <input type="checkbox" id='irreversible' defaultChecked={irreversible} onChange={(e) => setIrreversible(e.target.checked)} />
                                <label className={Style.label_rewards} htmlFor="irreversible" >I am aware that this is an irreversible change *</label>
                            </div>
                            <div className={Style.checkbox_label}>
                                <input type="checkbox" id='change_value' defaultChecked={afterChange} onChange={(e) => setAfterChange(e.target.checked)} />
                                <label className={Style.label_rewards} htmlFor="change_value">I am aware that after making these changes the value of my rewards will change. *</label>
                            </div>
                            <div className={Style.checkbox_label}>
                                <input type="checkbox" id='displayed' defaultChecked={displayed} onChange={(e) => setDisplayed(e.target.checked)} />
                                <label className={Style.label_rewards} htmlFor="displayed">I am aware that this change will be made public from the beginning of the next accounting month. For this accounting month the changes will not be displayed. *</label>
                            </div>
                        </div>

                        <div className='mt-4 d-flex justify-content-end'>
                            <button className={Style.discard} onClick={() => closeChanger()}>Discard</button>
                            {irreversible && afterChange && displayed
                                ? <button className={Style.confirm} onClick={() => changeGain(props.singleId, Number(rangeValue))}>Confirm</button>
                                : <button disabled className={Style.confirm}>Confirm</button>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangeRatio