import React from "react";
import Style from "./StatsClub.module.css";
import Visible from "../../../Assets/img/icon/visibility.png";
import NotVisible from "../../../Assets/img/icon/not_visibility.png";
import { Row, Col } from "react-bootstrap";
import ClubSize from "../../../Assets/img/club-size.png";
import ClubMember from "../../../Assets/img/club-member.png";
import ClubRatio from "../../../Assets/img/club-ratio.png";
import ClubBlocked from "../../../Assets/img/club-blocked-pool.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faCopy } from "@fortawesome/free-solid-svg-icons";
import CoinSmall from "../../../Assets/img/icon/poliant-coin-small.png";
import CoinBig from "../../../Assets/img/icon/poliant-coin-big.png";
// import BasicMember from "../../../Assets/img/base-member.png";
import NormalMember from "../../../Assets/img/member.png";
import SilverMember from "../../../Assets/img/silver-member.png";
import GoldMember from "../../../Assets/img/gold-member.png";
import PlatinumMember from "../../../Assets/img/platinum-member.png";
import DaoMember from "../../../Assets/img/dao-member.png";
import Config from "../../../config";

const StatsClub = (props) => {

	return (
		<>
			<Row>
				<Col md={7} className="mt-3">
					<h2 className={Style.title_section}>
						Welcome to your Club
						{
							props.first_name && props.last_name
								? ' ' + props.first_name + " " + props.last_name
								: "User"
						}
					</h2>
					<small className={Style.subtitle_function}>Set Privacy Mode</small>
					<span className={Style.eye} onClick={() => props.setPrivateMode(!props.privateMode)}>
						{props.privateMode ? <img src={NotVisible} alt="Not Visible" /> : <img src={Visible} alt="Visible" />}
					</span>
				</Col>
				<Col sm={7} md={5} className="mt-3">
					<div className={Style.box_referral_title}>Referral Link</div>
					<div id="link_referral_to_copy" className={Style.box_referral_link}>
						{props.referral_code_default !== 'No Default Referral Code'
							?
							<>
								{Config.base_url_referral + props.referral_code_default}
								<span className={Style.separator} style={{ height: 20 }}></span>

								<div className={Style.tooltip}>
									<span className={Style.tooltiptext} id="myTooltip">
										Copy to clipboard
									</span>
									<FontAwesomeIcon
										icon={faCopy}
										onClick={() => copyToClipboard()}
										onMouseOut={() => outFunc()}
									/>
								</div>
							</>
							: props.referral_code_default
						}
					</div>
				</Col>
			</Row>

			<div className={Style.row_stat}>
				<Row>
					<Col xs={12} sm={6} md={3} className={Style.column_stat}>
						<img className={Style.box_icon} src={ClubSize} alt="section-icon" />

						<div className={Style.stat_title}>Your club size</div>
						<div className={Style.stat_number}>
							{props.privateMode === true
								? '*****'
								: props.club_size
							}
						</div>
						<div className={Style.increment_stat}>
							<div className={Style.chart_section}>
								<div className={Style.chart}>
									<FontAwesomeIcon icon={faChartLine} />
									<span className="ms-1">*****</span>
								</div>
								<div className={Style.subtitle_chart}>Change</div>
							</div>

							<span className={Style.separator}></span>

							<div className={Style.chart_section_week}>
								<div className={Style.chart_week}>
									<span>*****</span>
								</div>
								<div className={Style.subtitle_chart}>Last Week</div>
							</div>
						</div>
					</Col>
					<Col xs={12} sm={6} md={3} className={Style.column_stat}>
						<img
							className={Style.box_icon}
							src={ClubBlocked}
							alt="section-icon"
						/>

						<div className={Style.stat_title}>AMOUNT BLOCKED IN POOLS</div>
						<div className={Style.stat_number}>
							{props.privateMode === true
								? '*****'
								: '*****'
							}
							<img src={CoinBig} alt="poliant-coin" />
						</div>
						<div className={Style.increment_stat}>
							<div className={Style.chart_section}>
								<div className={Style.chart}>
									<FontAwesomeIcon icon={faChartLine} />
									<span className="ms-1">*****</span>
								</div>
								<div className={Style.subtitle_chart}>Change</div>
							</div>

							<span className={Style.separator}></span>

							<div className={Style.chart_section_week}>
								<div className={Style.chart_week}>
									<span>*****</span>
									<img src={CoinSmall} alt="poliant-coin" />
								</div>
								<div className={Style.subtitle_chart}>Last Week</div>
							</div>
						</div>
					</Col>
					<Col xs={12} sm={6} md={3} className={Style.column_stat}>
						<img className={Style.box_icon} src={ClubMember} alt="" />

						<div className={Style.stat_title}>MEDIA MEMBER</div>
						<div className={Style.stat_number}>
							{props.privateMode === true
								? '*****'
								: '*****'
							}
							<img src={CoinBig} alt="poliant-coin" />
						</div>
						<div className={Style.increment_stat}>
							<div className={Style.chart_section}>
								<div className={Style.chart}>
									<FontAwesomeIcon icon={faChartLine} />
									<span className="ms-1">*****</span>
								</div>
								<div className={Style.subtitle_chart}>Change</div>
							</div>

							<span className={Style.separator}></span>

							<div className={Style.chart_section_week}>
								<div className={Style.chart_week}>
									<span>*****</span>
									<img src={CoinSmall} alt="poliant-coin" />
								</div>
								<div className={Style.subtitle_chart}>Last Week</div>
							</div>
						</div>
					</Col>
					<Col xs={12} sm={6} md={3} className={Style.column_stat}>
						<img className={Style.box_icon} src={ClubRatio} alt="" />

						<div className={Style.stat_title}>MEDIA MEMBER</div>
						<div className={Style.stat_number}>
							{props.privateMode === true
								? '*****'
								: '*****'
							} in
							<img src={CoinBig} alt="poliant-coin" />
						</div>
						<div className={Style.increment_stat}>
							<div className={Style.chart_section}>
								<div className={Style.chart}>
									<FontAwesomeIcon icon={faChartLine} />
									<span className="ms-1">*****</span>
								</div>
								<div className={Style.subtitle_chart}>Change</div>
							</div>

							<span className={Style.separator}></span>

							<div className={Style.chart_section_week}>
								<div className={Style.chart_week}>
									<span>*****</span>
								</div>
								<div className={Style.subtitle_chart}>Last Week</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>

			<div className={Style.section_rank_member}>
				<Row>
					<Col
						sm={12}
						md={3}
						className="d-flex align-items-center justify-content-center mb-sm-4 mb-md-0"
					>
						<span className={Style.rank_title}>RANK PRESENT IN YOUR CLUB:</span>
					</Col>
					<Col sm={12} md={9}>
						<div className={Style.rank_coin}>
							<div className={Style.box_rank}>
								<div className="text-center">
									<img src={NormalMember} alt="Normal Coin" />
									<div className={Style.rank_text}>
										{/* NORMAL MEMBER */}
										**** ****
									</div>
								</div>
								<div className={Style.rank_number}>
									{props.privateMode === true
										? '***'
										: '**'
									}
								</div>
							</div>

							<div className={Style.box_rank}>
								<div className="text-center">
									<img src={SilverMember} alt="Silver Coin" />
									<div className={Style.rank_text}>
										{/* SILVER MEMBER */}
										**** ****
									</div>
								</div>
								<div className={Style.rank_number}>
									{props.privateMode === true
										? '***'
										: '**'
									}
								</div>
							</div>

							<div className={Style.box_rank}>
								<div className="text-center">
									<img src={GoldMember} alt="Gold Member" />
									<div className={Style.rank_text}>
										{/* GOLD MEMBER */}
										**** ****
									</div>
								</div>
								<div className={Style.rank_number}>
									{props.privateMode === true
										? '***'
										: '**'
									}
								</div>
							</div>

							<div className={Style.box_rank}>
								<div className="text-center">
									<img src={PlatinumMember} alt="Platinum Member" />
									<div className={Style.rank_text}>
										{/* PLATINUM MEMBER */}
										**** ****
									</div>
								</div>
								<div className={Style.rank_number}>
									{props.privateMode === true
										? '***'
										: '**'
									}
								</div>
							</div>

							<div className={Style.box_rank}>
								<div className="text-center">
									<img src={DaoMember} alt="DAO Member" />
									<div className={Style.rank_text}>
										{/* DAO MEMBER */}
										**** ****
									</div>
								</div>
								<div className={Style.rank_number}>
									{props.privateMode === true
										? '***'
										: '**'
									}
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</>
	);

	function copyToClipboard() {
		var copyText = document.getElementById("link_referral_to_copy").firstChild
			.nodeValue;
		navigator.clipboard.writeText(copyText);

		var tooltip = document.getElementById("myTooltip");
		tooltip.innerHTML = "Copied";
	}

	function outFunc() {
		var tooltip = document.getElementById("myTooltip");
		tooltip.innerHTML = "Copy to clipboard";
	}
};

export default StatsClub;
