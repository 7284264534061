import React, { useState } from 'react'
import Style from './TwoFactorPopup.module.css'
import TwoFactorIcon from '../../../Assets/img/icon/icon_step_two_factor.png'
import AuthCode from 'react-auth-code-input';
import axios from 'axios';

import Config from '../../../config';

const TwoFactorPopup = (props) => {
    const [result, setResult] = useState('');
    const [error, setError] = useState('');
    const [codeResend, setCodeResend] = useState('');

    const handleOnChange = (res) => {
        setResult(res);
        setError('');
        setCodeResend('')
    };

    function verify() {
        if (result.length === 6) {
            getApiRequest();
        }
    }

    function getApiRequest() {

        var data = {
            otp: result,
            method: props.popupState.method,
        }
        const url = props.popupState.endpoint;
        const token = localStorage.getItem("token");
        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Token ${token}`,
            },
        };

        axios
            .post(url, data, config)
            .then((response) => {
                if (response.status === 200) {
                    if (props.popupState.method === "DELETE_ACCOUNT") {
                        localStorage.clear('')
                        window.location.reload();
                    } else if (props.popupState.method === "CHANGE_PASSWORD") {
                        localStorage.clear('')
                        window.location.reload();
                    } else {
                        window.location.reload();
                    }
                }
            })
            .catch((error) => {
                setError(error.response.data.Error);
            });

    }
    function resendNewCode() {

        const url = Config.base_url + 'accounts/otp-verification/';
        const token = localStorage.getItem("token");
        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Token ${token}`,
            },
        };

        axios
            .get(url, config)
            .then((response) => {
                if (response.status === 200) {
                    setCodeResend(response.data.OK)
                }
            })
            .catch((error) => {
            });
    }
    function closePop() {
        let twoStepVerification = document.getElementById('two_step_verified');
        let layover = document.getElementById('layover');
        twoStepVerification.style.display = 'none';
        layover.style.display = 'none';

        if (props.popupState.method === "DELETE_ACCOUNT") {
            props.setInDelete(false);
        }

        window.location.reload();
    }

    return (
        <>
            {/* Il popup verra richiamato onclick per l'autenticazione e in base al button che avvia la chiamata andra a scegliere il button corretto per effettuare la chiamata */}
            <div id='two_step_verified' className={Style.position_auth_popup}>

                <div className={Style.body_popup}>
                    <img src={TwoFactorIcon} alt="" />

                    <h2>{props.popupState.title}</h2>

                    <p>{props.popupState.subtitle}</p>

                    <div className='error_input'>{error}</div>
                    <div className='error_input'>{codeResend}</div>

                    <div className={Style.two_factor_box}>
                        <AuthCode allowedCharacters='numeric' onChange={handleOnChange} />
                    </div>

                    {result.length === 6
                        ? <button className={Style.button} onClick={() => verify()}>{props.popupState.button}</button>
                        : <button disabled className={Style.button} >{props.popupState.button}</button>
                    }

                    <p className={Style.p_two_factor}>Haven’t received it?
                        <a href='#!' className='text-primary' onClick={() => resendNewCode()}> Resend a new code.</a>
                    </p>
                    <span className={Style.p_two_factor_pop} onClick={() => closePop()}>Discard Operation</span>
                </div>

            </div>
        </>
    )
}

export default TwoFactorPopup