import React from "react";
import Style from "./RecentDevicesProfile.module.css";

const RecentDevicesProfile = (props) => {
  return (
    <>
      <section id="devices">
        <div style={{ paddingTop: 24 }}></div>

        <div className={Style.card_profile}>
          <h2 className={Style.card_title}>Recent devices</h2>
          <p className={Style.card_info_text}>
            View and manage devices where you’re currently logged in.
          </p>

          {props.devices.length > 0 ? (
            <>
              <div className="table-responsive">
                <table className="table table-thead-bordered table-nowrap table-align-middle card-table">
                  <thead className="thead-light">
                    <tr>
                      <th>Browser</th>
                      <th>Device</th>
                      <th>Location</th>
                      <th>Last activity</th>
                    </tr>
                  </thead>

                  <tbody>
                    {props.devices.map((device) => {
                      return (
                        <tr key={device.id}>
                          <td className="align-items-center">
                            {device.browser}
                          </td>
                          <td>{device.device}</td>
                          <td>{device.location}</td>
                          <td>{device.lastActivity}</td>
                        </tr>
                      );
                    })}

                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <p className={Style.card_info_text}>
              There are no properly configured deviced.
            </p>
          )}
        </div>
      </section>
    </>
  );
};

export default RecentDevicesProfile;
