import React, { useState, useEffect } from "react";

//Librairies
import { AuthRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";

// Components
import AuthLoader from "../../AuthLoader/AuthLoader";

// Images
import LogoPoliant from "../../../Assets/img/logos/poliant_logo.webp";

// Styles
import Style from "./CompleteRegistration.module.css";



import { Form } from "react-bootstrap";
import Config from "../../../config";
import axios from "axios";

const CompleteRegistration = () => {
  const navigate = useNavigate();
  const [terms, setTerms] = useState(false);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [address, setAddress] = useState("");
  const [billingCheck, setBillingCheck] = useState(false);
  const [billingCountry, setBillingCountry] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingState, setBillingState] = useState("");
  const [billingZip, setBillingZip] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [sendComplete, setSendComplete] = useState(false);

  const [citizenship, setCitizenship] = useState("");
  const [profession, setProfession] = useState("");
  const [documentType, setDocumentType] = useState("PASSPORT");
  const [documentNumber, setDocumentNumber] = useState("");
  const [documentIssuedBy, setDocumentIssuedBy] = useState("");
  const [documentIssuingDate, setDocumentIssuingDate] = useState("");
  const [documentExpirationDate, setDocumentExpirationDate] = useState("");
  const [politicallyExposed, setPoliticallyExposed] = useState(false);


  useEffect(() => {
    document.title = "Poliant | Complete Registration";
  }, []);

  const completeRegistrationData = () => {

    let data = {
      state: state,
      country: country,
      city: city,
      address: address,
      zipcode: zip,
      billing_address: billingAddress,
      state_billing: billingState,
      country_billing: billingCountry,
      city_billing: billingCity,
      zipcode_billing: billingZip,
      citizenship: citizenship,
      profession: profession,
      document_type: documentType,
      document_number: documentNumber,
      issued_by: documentIssuedBy,
      issuing_date: documentIssuingDate,
      expire_date: documentExpirationDate,
      politically_exposed: politicallyExposed,
    };
    setSendComplete(true);

    const url = Config.base_url + "accounts/extra-data/";
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };

    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          localStorage.clear('');
          navigate('/auth/login');
        }
      })
      .catch((error) => {
        if (error) {
          setSendComplete(false);
        }
      });
  }

  return (
    <AuthRoute
      forLoggedUser={true}
      loader={<AuthLoader />}
      action={() => { navigate("/") }}
    >
      <section>
        <img className={Style.logo} src={LogoPoliant} alt="Poliant logo" />

        <div className={Style.regConfirm} id="registration_form">
          <div className={Style.container}>

            <div className={Style.card}>
              <div className="text-center">
                <h2>Registration Complete</h2>
              </div>

              <div>
                <div className="form">
                  {/* country */}
                  <label htmlFor="country" className={Style.label}>
                    Country
                  </label>
                  <div className="d-flex">
                    <input
                      id="country"
                      type="text"
                      className="form-control"
                      placeholder="Insert your country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </div>
                  {/* City */}
                  <label htmlFor="city" className={Style.label}>
                    City
                  </label>
                  <div className="d-flex">
                    <input
                      id="city"
                      type="text"
                      className="form-control"
                      placeholder="Insert your city"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                  {/* State */}
                  <label htmlFor="state" className={Style.label}>
                    State
                  </label>
                  <div className="d-flex">
                    <input
                      id="state"
                      type="text"
                      className="form-control"
                      placeholder="Insert your state"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>
                  {/* Address  */}
                  <label htmlFor="address" className={Style.label}>
                    Address
                  </label>
                  <div className="d-flex">
                    <input
                      id="address"
                      type="text"
                      className="form-control"
                      placeholder="Insert your address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  {/* Address  */}
                  <label htmlFor="zipcode" className={Style.label}>
                    Zip Code
                  </label>
                  <div className="d-flex">
                    <input
                      id="zipcode"
                      type="text"
                      className="form-control"
                      placeholder="Insert your zip code"
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                    />
                  </div>

                  <div>
                    <input
                      className="mt-4 m-2 mb-3 ms-0"
                      type="checkbox"
                      name=""
                      defaultChecked={billingCheck}
                      onChange={(e) => setBillingCheck(e.target.checked)}
                    />
                    <span className={Style.remember}>
                      Invoicing data are difference as in residence
                    </span>
                  </div>

                  <div
                    id="billing_different"
                    className={billingCheck ? "d-block mb-3" : " d-none"}
                  >
                    <label htmlFor="billingcountry" className={Style.label}>
                      Country
                    </label>
                    <div className="d-flex">
                      <input
                        id="billingcountry"
                        type="text"
                        className="form-control"
                        placeholder="Insert your billing country"
                        value={billingCountry}
                        onChange={(e) => setBillingCountry(e.target.value)}
                      />
                    </div>

                    <label htmlFor="billingCity" className={Style.label}>
                      City
                    </label>
                    <div className="d-flex">
                      <input
                        id="billingCity"
                        type="text"
                        className="form-control"
                        placeholder="Insert your billing city"
                        value={billingCity}
                        onChange={(e) => setBillingCity(e.target.value)}
                      />
                    </div>

                    <label htmlFor="billingState" className={Style.label}>
                      State
                    </label>
                    <div className="d-flex">
                      <input
                        id="billingState"
                        type="text"
                        className="form-control"
                        placeholder="Insert your billing state"
                        value={billingState}
                        onChange={(e) => setBillingState(e.target.value)}
                      />
                    </div>

                    <label htmlFor="billingaddress" className={Style.label}>
                      Address
                    </label>
                    <div className="d-flex">
                      <input
                        id="billingaddress"
                        type="text"
                        className="form-control"
                        placeholder="Insert your billing address"
                        value={billingAddress}
                        onChange={(e) => setBillingAddress(e.target.value)}
                      />
                    </div>

                    <label htmlFor="billingzip" className={Style.label}>
                      Zip Code
                    </label>
                    <div className="d-flex">
                      <input
                        id="billingzip"
                        type="text"
                        className="form-control"
                        placeholder="Insert your billing zip code"
                        value={billingZip}
                        onChange={(e) => setBillingZip(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* Separetor */}
                  <div className={Style.separator}>
                    <div className={Style.separator_border}></div>
                  </div>

                  <div className="mb-3">

                    <label htmlFor="citizen" className={Style.label}>
                      Citizenship
                    </label>
                    <div className="d-flex">
                      <input
                        id="citizen"
                        type="text"
                        className="form-control"
                        placeholder="Insert Citizenship"
                        value={citizenship}
                        onChange={(e) => setCitizenship(e.target.value)}
                      />
                    </div>

                    <label htmlFor="profession" className={Style.label}>
                      Profession
                    </label>
                    <div className="d-flex">
                      <input
                        id="profession"
                        type="text"
                        className="form-control"
                        placeholder="Insert Profession"
                        value={profession}
                        onChange={(e) => setProfession(e.target.value)}
                      />
                    </div>

                    <label htmlFor="documentType" className={Style.label}>
                      Document Type
                    </label>
                    <div className="d-flex">
                      <Form.Select style={{ fontSize: 14 }} onChange={(e) => setDocumentType(e.target.value)} defaultValue={documentType}>
                        <option value='PASSPORT'>Passport</option>
                        <option value='ID_CARD'>ID Card</option>
                      </Form.Select>
                    </div>

                    <label htmlFor="documentNumber" className={Style.label}>
                      Document Number
                    </label>
                    <div className="d-flex">
                      <input
                        id="documentNumber"
                        type="text"
                        className="form-control"
                        placeholder="Insert Document Number"
                        value={documentNumber}
                        onChange={(e) => setDocumentNumber(e.target.value)}
                      />
                    </div>

                    <label htmlFor="issuedBy" className={Style.label}>
                      Issued by
                    </label>
                    <div className="d-flex">
                      <input
                        id="issuedBy"
                        type="text"
                        className="form-control"
                        placeholder="Insert Issued by"
                        value={documentIssuedBy}
                        onChange={(e) => setDocumentIssuedBy(e.target.value)}
                      />
                    </div>


                    <label htmlFor="issuingDate" className={Style.label}>
                      Issuing Date
                    </label>
                    <div className="d-flex">
                      <input
                        id="issuingDate"
                        type="date"
                        className="form-control"
                        placeholder="Year-Month-Day"
                        value={documentIssuingDate}
                        onChange={(e) => setDocumentIssuingDate(e.target.value)}
                      />
                    </div>

                    <label htmlFor="expireDate" className={Style.label}>
                      Expire Date
                    </label>
                    <div className="d-flex">
                      <input
                        id="expireDate"
                        type="date"
                        className="form-control"
                        placeholder="Year-Month-Day"
                        value={documentExpirationDate}
                        onChange={(e) => setDocumentExpirationDate(e.target.value)}
                      />
                    </div>

                    <div className="input-group input-group-sm-vertical">
                      <label className={Style.form_control + ' mb-0 p-0 mt-4'} style={{ border: 'none', fontSize: 14 }} htmlFor="politically-exposed">
                        <span className="form-check">
                          <input type="checkbox" className="form-check-input" name="politically-exposed" id="politically-exposed" defaultChecked={politicallyExposed} onChange={(e) => setPoliticallyExposed(e.target.checked)} />
                          <span className={Style.remember_billing}>If checked you are politically exposed</span>
                        </span>
                      </label>
                    </div>
                  </div>

                  {/* Separetor */}
                  <div className={Style.separator}>
                    <div className={Style.separator_border}></div>
                  </div>

                  {/* Terms*/}
                  <div>
                    <input
                      className="mt-4 m-2 mb-0 ms-0"
                      type="checkbox"
                      name=""
                      defaultChecked={terms}
                      onChange={(e) => setTerms(e.target.checked)}
                    />
                    <span className={Style.remember}>
                      I accept the{" "}
                      <a href="#!" className="text-primary">
                        Term and Conditions
                      </a>{" "}
                      and{" "}
                      <a href="#!" className="text-primary">
                        Policy Privacy
                      </a>
                    </span>
                  </div>

                  {billingCheck
                    ? (
                      address !== "" &&
                        city !== "" &&
                        state !== "" &&
                        zip !== "" &&
                        country !== "" &&
                        billingAddress !== "" &&
                        billingCity !== "" &&
                        billingState !== "" &&
                        billingZip !== "" &&
                        billingCountry !== "" &&
                        citizenship !== "" &&
                        profession !== "" &&
                        documentType !== "" &&
                        documentNumber !== "" &&
                        documentIssuedBy !== "" &&
                        documentIssuingDate !== "" &&
                        documentExpirationDate !== "" &&
                        terms === true
                        ? sendComplete === false
                          ? <button onClick={() => completeRegistrationData()} className={Style.sign_in_button}>Complete Registration</button>
                          : <button disabled className={Style.sign_in_button}>Completing Rigistration...</button>
                        : <button
                          disabled
                          className={Style.sign_in_button}
                          type="submit"
                        >
                          Complete Registration
                        </button>

                    )
                    : address !== "" &&
                      city !== "" &&
                      country !== "" &&
                      zip !== "" &&
                      state !== "" &&
                      citizenship !== "" &&
                      profession !== "" &&
                      documentType !== "" &&
                      documentNumber !== "" &&
                      documentIssuedBy !== "" &&
                      documentIssuingDate !== "" &&
                      documentExpirationDate !== "" &&
                      terms === true
                      ? sendComplete === false
                        ? <button onClick={() => completeRegistrationData()} className={Style.sign_in_button}>Complete Registration</button>
                        : <button disabled className={Style.sign_in_button}>Completing Rigistration...</button>
                      : (
                        <button
                          disabled
                          className={Style.sign_in_button}
                          type="submit"
                        >
                          Complete Registration
                        </button>
                      )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AuthRoute>
  );
};

export default CompleteRegistration;
