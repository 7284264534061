import React, { useState } from 'react'

// Libraries
import { Row, Col, Form } from 'react-bootstrap'
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'

// Styles
import 'react-phone-number-input/style.css'
import Style from './BasicInfoUser.module.css'

import Config from '../../../config';

const BasicInfoUser = (props) => {
    const [checked, setChecked] = useState(props.type !== null ? props.type : '');
    const [address, setAddress] = useState(props.address !== null ? props.address : '');
    const [email, setEmail] = useState(props.email !== null ? props.email : '');
    const [state, setState] = useState(props.state !== null ? props.state : '');
    const [firstName, setFirstName] = useState(props.first_name !== null ? props.first_name : '');
    const [lastName, setLastName] = useState(props.last_name !== null ? props.last_name : '');
    const [firstNameCompany, setFirstNameCompany] = useState(props.first_name !== null ? props.first_name : '');
    const [lastNameCompany, setLastNameCompany] = useState(props.last_name !== null ? props.last_name : '');
    const [zipCode, setZipCode] = useState(props.zipcode !== null ? props.zipcode : '');
    const [country, setCountry] = useState(props.country !== null ? props.country : '');
    const [companyName, setCompanyName] = useState(props.companyName !== null ? props.companyName : '');
    const [vat, setVat] = useState(props.vat !== null ? props.vat : '');
    const [companyCountryRegistration, setCompanyCountryRegistration] = useState(props.companyCountryRegistration !== null ? props.companyCountryRegistration : '');
    const [city, setCity] = useState(props.city !== null ? props.city : '');
    const [companyAddress, setCompanyAddress] = useState(props.companyAddress !== null ? props.companyAddress : '');

    const [billingCountry, setBillingCountry] = useState(props.countryBilling !== null ? props.countryBilling : '');
    const [billingState, setBillingState] = useState(props.stateBilling !== null ? props.stateBilling : '');
    const [billingCity, setBillingCity] = useState(props.cityBilling !== null ? props.cityBilling : '');
    const [billingAddress, setBillingAddress] = useState(props.billingAddress !== null ? props.billingAddress : '');
    const [billingZipCode, setBillingZipCode] = useState(props.zipcodeBilling !== null ? props.zipcodeBilling : '');

    const [phoneNumber, setPhoneNumber] = useState(props.phone !== null ? props.phone : '');
    const numberPhone = formatPhoneNumberIntl(phoneNumber);

    const [checkedBilling, setCheckedBilling] = useState(billingAddress !== '' ? true : false);

    const billingDifferentAddress = document.getElementById('billingaddressinfo');
    const newAddress = document.getElementById('billing_different');
    const error_billing = document.getElementById('error_billing');

    const [citizenship, setCitizenship] = useState(props.citizenship !== null ? props.citizenship : '');
    const [profession, setProfession] = useState(props.profession !== null ? props.profession : '');
    const [documentType, setDocumentType] = useState(props.document_type !== null ? props.document_type : '');
    const [documentNumber, setDocumentNumber] = useState(props.document_number !== null ? props.document_number : '');
    const [documentIssuedBy, setDocumentIssuedBy] = useState(props.issued_by !== null ? props.issued_by : '');
    const [documentIssuingDate, setDocumentIssuingDate] = useState(props.issuing_date !== null ? props.issuing_date : '');
    const [documentExpirationDate, setDocumentExpirationDate] = useState(props.expire_date !== null ? props.expire_date : '');
    const [politicallyExposed, setPoliticallyExposed] = useState(props.politically_exposed !== null ? props.politically_exposed : '');

    function submitEventKyc(checkStatus) {

        const basicInfo = {
            first_name: firstName,
            last_name: lastName,
            phone: numberPhone,
            type: checked,
            company_name: companyName,
            company_registration_country: companyCountryRegistration,
            vat: vat,
            companyAddress: companyAddress,
            country: country,
            city: city,
            state: state,
            address: address,
            zipcode: zipCode,
            country_billing: '',
            city_billing: '',
            state_billing: '',
            billing_address: '',
            zipcode_billing: '',
            citizenship: citizenship,
            profession: profession,
            document_type: documentType,
            document_number: documentNumber,
            issued_by: documentIssuedBy,
            issuing_date: documentIssuingDate,
            expire_date: documentExpirationDate,
            politically_exposed: politicallyExposed,
            title: "CONTRACT & KYC ALERT",
            subtitle: "Are you sure you want to edit your additional information? If you have already done the KYC & CONTRACT this will be cancelled.",
            endpoint: Config.base_url + "my-profile/update-profile/",
        }

        if (checkStatus) {
            basicInfo.country_billing = billingCountry;
            basicInfo.city_billing = billingCity;
            basicInfo.state_billing = billingState;
            basicInfo.billing_address = billingAddress;
            basicInfo.zipcode_billing = billingZipCode;
        }

        const kycBox = document.getElementById("kyc_confirm");
        const layover = document.getElementById("layover");
        kycBox.style.display = "block";
        layover.style.display = "block"
        props.setPopupState(basicInfo);

    }

    function companyChangeProfileKyc() {
        const basicInfo = {
            first_name: firstNameCompany,
            last_name: lastNameCompany,
            phone: numberPhone,
            type: checked,
            company_name: companyName,
            company_registration_country: companyCountryRegistration,
            vat: vat,
            company_address: companyAddress,
            country: country,
            city: city,
            state: state,
            zipcode: zipCode,
            citizenship: citizenship,
            profession: profession,
            document_type: documentType,
            document_number: documentNumber,
            issued_by: documentIssuedBy,
            issuing_date: documentIssuingDate,
            expire_date: documentExpirationDate,
            politically_exposed: politicallyExposed,
            title: "CONTRACT & KYC ALERT",
            subtitle: "Are you sure you want to edit your additional information? If you have already done the KYC & CONTRACT this will be cancelled.",
            endpoint: Config.base_url + "my-profile/update-profile/",
        }
        const kycBox = document.getElementById("kyc_confirm");
        const layover = document.getElementById("layover");
        kycBox.style.display = "block";
        layover.style.display = "block"
        props.setPopupState(basicInfo);
    }

    const handleChangePersonal = () => {
        setChecked('PERSONAL');
    }
    const handleChangeCompany = () => {
        setChecked('COMPANY');
    }
    // Imposta la visualizzazione di un nuovo indirizzo di pagamento
    function newAd() {

        //se è checheck billingDifferentAddress allora rimuove la classe e lo mette block
        if (billingDifferentAddress.checked) {
            newAddress.classList.remove('d-none');
            newAddress.classList.add('d-block');
        } else {
            newAddress.classList.remove('d-block');
            newAddress.classList.add('d-none');
        }
    }

    return (
        <>
            <section id='information'>
                <div style={{ paddingTop: 24 }}></div>
                <div className={Style.card_profile}>
                    <h2 className={Style.card_title}>Basic information</h2>

                    <Row className='mb-4'>
                        <Col sm={3} className={Style.label_box}>
                            {checked === 'COMPANY' ? (
                                <label htmlFor="firstNameLabel" className={Style.col_form_label}>CEO Full name</label>
                            ) : <label htmlFor="firstNameLabel" className={Style.col_form_label}>Full name</label>}
                        </Col>
                        {checked === 'COMPANY'
                            ? <Col sm={9}>
                                <div className="input-group input-group-sm-vertical">
                                    <input type="text" className={Style.form_control} value={firstNameCompany} onChange={(e) => setFirstNameCompany(e.target.value)} name="firstName" id="firstNameLabel" placeholder="Your first name" aria-label="Your first name" />
                                    <input type="text" className={Style.form_control + ' ms-3'} value={lastNameCompany} onChange={(e) => setLastNameCompany(e.target.value)} name="lastName" id="lastNameLabel" placeholder="Your last name" aria-label="Your last name" />
                                </div>
                            </Col>
                            : <Col sm={9}>
                                <div className="input-group input-group-sm-vertical">
                                    <input readOnly type="text" className={Style.form_control_new} value={firstName} onChange={(e) => setFirstName(e.target.value)} name="firstName" id="firstNameLabel" placeholder="Your first name" aria-label="Your first name" />
                                    <input readOnly type="text" className={Style.form_control_new + ' ms-3'} value={lastName} onChange={(e) => setLastName(e.target.value)} name="lastName" id="lastNameLabel" placeholder="Your last name" aria-label="Your last name" />
                                </div>
                            </Col>
                        }
                    </Row>

                    <Row className='mb-4'>
                        <Col sm={3} className={Style.label_box}>
                            <label htmlFor="emailinformation" className={Style.col_form_label}>Email</label>
                        </Col>
                        <Col sm={9}>
                            <div className="input-group input-group-sm-vertical">
                                <input readOnly type="email" className={Style.form_control_new} value={email} onChange={(e) => setEmail(e.target.value)} name="emailinformation" id="emailinformation" placeholder="mark@site.com" aria-label="mark@site.com" />
                            </div>
                        </Col>
                    </Row>

                    <Row className='mb-4'>
                        <Col sm={3} className={Style.label_box}>
                            <label htmlFor="phoneinfo" className={Style.col_form_label}>Phone</label>
                        </Col>
                        <Col sm={9}>
                            <PhoneInput
                                id='phone'
                                className=''
                                placeholder="Prefix and Number"
                                value={phoneNumber}
                                onChange={setPhoneNumber}
                                error={phoneNumber ? (isValidPhoneNumber(phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'} />
                        </Col>
                    </Row>

                    <Row className='mb-4'>
                        <Col sm={3} className={Style.label_box}>
                            <label className={Style.col_form_label}>Account type</label>
                        </Col>
                        <Col sm={9}>
                            <div className="input-group input-group-sm-vertical">
                                <label className={Style.form_control + ' mb-0'} htmlFor="personal">
                                    <span className="form-check">
                                        {props.type === 'COMPANY'
                                            ?
                                            <>
                                                <input disabled type="radio" className="form-check-input" defaultChecked={checked === 'PERSONAL' ? true : false} name="userAccountTypeRadio" id="personal" onClick={() => handleChangePersonal()} />
                                                <span className="form-check-label">Individual</span>
                                            </>
                                            :
                                            <>
                                                <input type="radio" className="form-check-input" defaultChecked={checked === 'PERSONAL' ? true : false} name="userAccountTypeRadio" id="personal" onClick={() => handleChangePersonal()} />
                                                <span className="form-check-label">Individual</span>
                                            </>
                                        }
                                    </span>
                                </label>
                                <label className={Style.form_control + ' mb-0'} htmlFor="company">
                                    <span className="form-check">
                                        <input type="radio" className="form-check-input" defaultChecked={checked === 'COMPANY' ? true : false} name="userAccountTypeRadio" id="company" onClick={() => handleChangeCompany()} />
                                        <span className="form-check-label">Company</span>
                                    </span>
                                </label>
                            </div>
                        </Col>
                    </Row>

                    {checked === 'COMPANY' ? (
                        <>

                            <Row className='mb-4'>
                                <Col sm={3} className={Style.label_box}>
                                    <label htmlFor="companynameinformation" className={Style.col_form_label}>Company Name</label>
                                </Col>
                                <Col sm={9}>
                                    <div className="input-group input-group-sm-vertical">
                                        <input type="text" className={Style.form_control} value={companyName} onChange={(e) => setCompanyName(e.target.value)} name="companynameinformation" id="companynameinformation" placeholder="Company LTD" aria-label="Company LTD" />
                                    </div>
                                </Col>
                            </Row>


                            <Row className='mb-4'>
                                <Col sm={3} className={Style.label_box}>
                                    <label htmlFor="companycountryreg" className={Style.col_form_label}>Company Country Registration</label>
                                </Col>
                                <Col sm={9}>
                                    <div className="input-group input-group-sm-vertical">
                                        <input type="text" className={Style.form_control} value={companyCountryRegistration} onChange={(e) => setCompanyCountryRegistration(e.target.value)} name="companycountryreg" id="companycountryreg" placeholder="Insert your country registration" aria-label="Insert your country registration" />
                                    </div>
                                </Col>
                            </Row>


                            <Row className='mb-4'>
                                <Col sm={3} className={Style.label_box}>
                                    <label htmlFor="vatnumberinfo" className={Style.col_form_label}>VAT Number</label>
                                </Col>
                                <Col sm={9}>
                                    <div className="input-group input-group-sm-vertical">
                                        <input type="text" className={Style.form_control} value={vat} onChange={(e) => setVat(e.target.value)} name="vatnumberinfo" id="vatnumberinfo" placeholder="Insert your vat number" aria-label="Insert your vat number" />
                                    </div>
                                </Col>
                            </Row>


                            <Row className='mb-3'>
                                <Col sm={3} className={Style.label_box}>
                                    <label htmlFor="nationinfocompany" className={Style.col_form_label}>Company Legal Address</label>
                                </Col>
                                <Col sm={9}>
                                    <div className="input-group input-group-sm-vertical">
                                        <input type="text" className={Style.form_control} value={country} onChange={(e) => setCountry(e.target.value)} name="nationinfocompany" id="nationinfocompany" placeholder="Insert your country" aria-label="Insert your country" />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col sm={3} className={Style.label_box}></Col>
                                <Col sm={9}>
                                    <div className="input-group input-group-sm-vertical">
                                        <input type="text" className={Style.form_control} value={city} onChange={(e) => setCity(e.target.value)} name="cityinfocompany" id="cityinfocompany" placeholder="Insert your city" aria-label="Insert your city" />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mb-4'>
                                <Col sm={3} className={Style.label_box}></Col>
                                <Col sm={9}>
                                    <div className="input-group input-group-sm-vertical">
                                        <input type="text" className={Style.form_control} value={state} onChange={(e) => setState(e.target.value)} name="stateinfocompany" id="stateinfocompany" placeholder="Insert your state" aria-label="Insert your state" />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    ) :
                        <>
                            <Row className='mb-3'>
                                <Col sm={3} className={Style.label_box}>
                                    <label htmlFor="nationinfo" className={Style.col_form_label}>Residence Detail</label>
                                </Col>
                                <Col sm={9}>
                                    <div className="input-group input-group-sm-vertical">
                                        <input type="text" className={Style.form_control} value={country} onChange={(e) => setCountry(e.target.value)} name="nationinfo" id="nationinfo" placeholder="Insert your country" aria-label="Insert your country" />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col sm={3} className={Style.label_box}></Col>
                                <Col sm={9}>
                                    <div className="input-group input-group-sm-vertical">
                                        <input type="text" className={Style.form_control} value={city} onChange={(e) => setCity(e.target.value)} name="cityinfo" id="cityinfo" placeholder="Insert your city" aria-label="Insert your city" />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mb-4'>
                                <Col sm={3} className={Style.label_box}></Col>
                                <Col sm={9}>
                                    <div className="input-group input-group-sm-vertical">
                                        <input type="text" className={Style.form_control} value={state} onChange={(e) => setState(e.target.value)} name="stateinfo" id="stateinfo" placeholder="Insert your state" aria-label="Insert your state" />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    }

                    <Row className='mb-4'>
                        <Col sm={3} className={Style.label_box}>
                            <label htmlFor="addressinfo" className={Style.col_form_label}>Address line 1</label>
                        </Col>
                        <Col sm={9}>
                            <div className="input-group input-group-sm-vertical">
                                {checked === 'COMPANY'
                                    ? <input type="text" className={Style.form_control} value={companyAddress} onChange={(e) => setCompanyAddress(e.target.value)} name="addressinfo" id="addressinfo" placeholder="insert your address" aria-label="insert your address" />
                                    : <input type="text" className={Style.form_control} value={address} onChange={(e) => setAddress(e.target.value)} name="addressinfo" id="addressinfo" placeholder="insert your address" aria-label="insert your address" />
                                }
                            </div>
                        </Col>
                    </Row>

                    <Row className='mb-4'>
                        <Col sm={3} className={Style.label_box}>
                            <label htmlFor='zipinfo' className={Style.col_form_label}>Zip code</label>
                        </Col>
                        <Col sm={9}>
                            <div className="input-group input-group-sm-vertical">
                                <input type="text" className={Style.form_control} value={zipCode} onChange={(e) => setZipCode(e.target.value)} name="zipinfo" id="zipinfo" placeholder="Insert your zip code" aria-label="Insert your zip code" />
                            </div>
                        </Col>
                    </Row>


                    {checked === 'COMPANY' ?
                        (
                            <></>
                        )
                        :
                        <>
                            <Row className='mb-0'>
                                <Col sm={3} className={Style.label_box}>
                                    <label htmlFor='billingaddressinfo' className={Style.col_form_label}>Billing Address</label>
                                </Col>
                                <Col sm={9}>
                                    <div>
                                        <input className='mt-3 m-2 ms-0' defaultChecked={checkedBilling} onChange={(e) => setCheckedBilling(e.target.checked)} type="checkbox" name="billingaddressinfo" id="billingaddressinfo" onClick={() => newAd()} />
                                        <span className={Style.remember_billing}>Invoicing data are difference as in residence</span>
                                    </div>
                                </Col>
                            </Row>

                            <div id='billing_different' className={checkedBilling ? 'd-block' : ' d-none'}>
                                <Row>
                                    <Col sm={{ offset: 3, span: 9 }}>
                                        <hr className='mb-4' />
                                        <div id='error_billing' className='error_input d-none'>All fields must be filled in</div>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col sm={3} className={Style.label_box}>
                                        <label htmlFor="newnationinfo" className={Style.col_form_label}>Location</label>
                                    </Col>
                                    <Col sm={9}>
                                        <div className="input-group input-group-sm-vertical">
                                            <input type="text" className={Style.form_control} value={billingCountry} onChange={(e) => setBillingCountry(e.target.value)} name="newnationinfo" id="newnationinfo" placeholder="Insert your country" aria-label="Insert your country" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col sm={3} className={Style.label_box}></Col>
                                    <Col sm={9}>
                                        <div className="input-group input-group-sm-vertical">
                                            <input type="text" className={Style.form_control} value={billingCity} onChange={(e) => setBillingCity(e.target.value)} name="newcityinfo" id="newcityinfo" placeholder="Insert your city" aria-label="Insert your city" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mb-4'>
                                    <Col sm={3} className={Style.label_box}></Col>
                                    <Col sm={9}>
                                        <div className="input-group input-group-sm-vertical">
                                            <input type="text" className={Style.form_control} value={billingState} onChange={(e) => setBillingState(e.target.value)} name="newstateinfo" id="newstateinfo" placeholder="Insert your state" aria-label="Insert your state" />
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='mb-4'>
                                    <Col sm={3} className={Style.label_box}>
                                        <label htmlFor="newaddressinfo" className={Style.col_form_label}>Address line 1</label>
                                    </Col>
                                    <Col sm={9}>
                                        <div className="input-group input-group-sm-vertical">
                                            <input type="text" className={Style.form_control} value={billingAddress} onChange={(e) => setBillingAddress(e.target.value)} name="newaddressinfo" id="newaddressinfo" placeholder="Insert your Address" aria-label="Insert your Address" />
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='mb-4'>
                                    <Col sm={3} className={Style.label_box}>
                                        <label htmlFor='newzipinfo' className={Style.col_form_label}>Zip code</label>
                                    </Col>
                                    <Col sm={9}>
                                        <div className="input-group input-group-sm-vertical">
                                            <input type="text" className={Style.form_control} value={billingZipCode} onChange={(e) => setBillingZipCode(e.target.value)} name="newzipinfo" id="newzipinfo" placeholder="Insert your zip code" aria-label="Insert your zip code" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    }

                    <hr className='mb-4' />

                    <Row className="mb-4">
                        <Col sm={3} className={Style.label_box}>
                            <label htmlFor="citizen_ship" className={Style.col_form_label}>
                                Citizenship
                            </label>
                        </Col>
                        <Col sm={9}>
                            <div className="input-group input-group-sm-vertical">
                                <input
                                    type="text"
                                    className={Style.form_control}
                                    value={citizenship}
                                    onChange={(e) => setCitizenship(e.target.value)}
                                    name="citizen_ship"
                                    id="citizen_ship"
                                    placeholder="Insert Citizenship"
                                    aria-label="Insert Citizenship"
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col sm={3} className={Style.label_box}>
                            <label htmlFor="profession" className={Style.col_form_label}>
                                Profession
                            </label>
                        </Col>
                        <Col sm={9}>
                            <div className="input-group input-group-sm-vertical">
                                <input
                                    type="text"
                                    className={Style.form_control}
                                    value={profession}
                                    onChange={(e) => setProfession(e.target.value)}
                                    name="profession"
                                    id="profession"
                                    placeholder="Insert Your Profession"
                                    aria-label="Insert Your Profession"
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className='mb-4'>
                        <Col sm={3} className={Style.label_box}>
                            <label htmlFor="document_type" className={Style.col_form_label}>Document Type</label>
                        </Col>
                        <Col sm={9}>
                            <Form.Select style={{ fontSize: 14 }} onChange={(e) => setDocumentType(e.target.value)} defaultValue={documentType}>
                                <option value='PASSPORT'>Passport</option>
                                <option value='ID_CARD'>ID Card</option>
                            </Form.Select>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col sm={3} className={Style.label_box}>
                            <label htmlFor="document_number" className={Style.col_form_label}>
                                Document Number
                            </label>
                        </Col>
                        <Col sm={9}>
                            <div className="input-group input-group-sm-vertical">
                                <input
                                    type="text"
                                    className={Style.form_control}
                                    value={documentNumber}
                                    onChange={(e) => setDocumentNumber(e.target.value)}
                                    name="document_number"
                                    id="document_number"
                                    placeholder="Insert Document Number"
                                    aria-label="Insert Document Number"
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col sm={3} className={Style.label_box}>
                            <label htmlFor="issued_by" className={Style.col_form_label}>
                                Issued by
                            </label>
                        </Col>
                        <Col sm={9}>
                            <div className="input-group input-group-sm-vertical">
                                <input
                                    type="text"
                                    className={Style.form_control}
                                    value={documentIssuedBy}
                                    onChange={(e) => setDocumentIssuedBy(e.target.value)}
                                    name="issued_by"
                                    id="issued_by"
                                    placeholder="Insert Issued By"
                                    aria-label="Insert Issued By"
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col sm={3} className={Style.label_box}>
                            <label htmlFor="issued_date" className={Style.col_form_label}>
                                Issuing Date
                            </label>
                        </Col>
                        <Col sm={9}>
                            <div className="input-group input-group-sm-vertical">
                                <input
                                    type="date"
                                    className={Style.form_control}
                                    value={documentIssuingDate}
                                    onChange={(e) => setDocumentIssuingDate(e.target.value)}
                                    name="issued_date"
                                    id="issued_date"
                                    placeholder="Year-Month-Day"
                                    aria-label="Year-Month-Day"
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col sm={3} className={Style.label_box}>
                            <label htmlFor="expire_date" className={Style.col_form_label}>
                                Expire Date
                            </label>
                        </Col>
                        <Col sm={9}>
                            <div className="input-group input-group-sm-vertical">
                                <input
                                    type="date"
                                    className={Style.form_control}
                                    value={documentExpirationDate}
                                    onChange={(e) => setDocumentExpirationDate(e.target.value)}
                                    name="expire_date"
                                    id="expire_date"
                                    placeholder="Year-Month-Day"
                                    aria-label="Year-Month-Day"
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col sm={3} className={Style.label_box}>
                            <label className={Style.col_form_label}>Politically Exposed</label>
                        </Col>
                        <Col sm={9}>
                            <div className="input-group input-group-sm-vertical">
                                <label className={Style.form_control + ' mb-0'} style={{ border: 'none' }} htmlFor="politically-exposed">
                                    <span className="form-check">
                                        <input type="checkbox" className="form-check-input" name="politically-exposed" id="politically-exposed" defaultChecked={politicallyExposed} onChange={(e) => setPoliticallyExposed(e.target.checked)} />
                                        <span className={Style.remember_billing}>If checked you are politically exposed</span>
                                    </span>
                                </label>
                            </div>
                        </Col>
                    </Row>

                    <Row className='mt-4 mb-2'>
                        <Col sm={12} className={Style.position_button}>
                            {/* //! TODO On click put change data  */}

                            {props.KycStatus === 'PENDING'
                                ? <button disabled className={Style.button}>KYC in Pending</button>
                                : props.KycStatus !== 'PENDING'
                                    ? checked === 'COMPANY' // POSSO SALVARE LE INFO MA PASSO DA POPUP
                                        ?
                                        firstNameCompany !== '' && lastNameCompany !== '' && email !== '' && phoneNumber !== '' && zipCode !== '' && country !== '' && city !== '' && state !== '' && companyAddress !== '' && companyCountryRegistration !== '' && companyName !== '' && vat !== '' && profession !== '' && documentType !== '' && documentNumber !== '' && documentIssuedBy !== '' && documentIssuingDate !== '' && documentExpirationDate !== ''
                                            ?
                                            <button id='save_button_basic' className={Style.button} onClick={() => companyChangeProfileKyc()}>
                                                <span id='text_basic'>Save changes</span>
                                                <span id='text_saved_basic' className={Style.saved_text}>Saved</span>
                                            </button>
                                            :
                                            <button disabled className={Style.button}>Save changes</button>
                                        :
                                        firstName !== '' && lastName !== '' && email !== '' && phoneNumber !== '' && address !== '' && zipCode !== '' && country !== '' && city !== '' && state !== '' && profession !== '' && documentType !== '' && documentNumber !== '' && documentIssuedBy !== '' && documentIssuingDate !== '' && documentExpirationDate !== ''
                                            ?
                                            <button id='save_button_basic' className={Style.button} onClick={() => {

                                                const billingDifferentAddress = document.getElementById('billingaddressinfo');

                                                if (!billingDifferentAddress.checked) {
                                                    submitEventKyc(false);
                                                } else {
                                                    if (billingAddress !== '' && billingZipCode !== '' && billingCountry !== '' && billingCity !== '' && billingState !== '') {
                                                        submitEventKyc(true);
                                                    } else {
                                                        error_billing.classList.remove('d-none');
                                                        error_billing.classList.add('d-block');
                                                    }
                                                }


                                            }}>
                                                <span id='text_basic'>Save changes</span>
                                                <span id='text_saved_basic' className={Style.saved_text}>Saved</span>
                                            </button>
                                            :
                                            <button disabled className={Style.button}>Save changes</button>
                                    : null
                            }
                        </Col>
                    </Row>



                </div>
            </section>
        </>
    )
}

export default BasicInfoUser