import React, { useState } from "react";
import Style from "./ChangePasswordProfile.module.css";
import { Row, Col } from "react-bootstrap";
import Visible from "../../../Assets/img/icon/visibility.png";
import NotVisible from "../../../Assets/img/icon/not_visibility.png";
import Verified from "../../../Assets/img/icon/verified.png";
import axios from "axios";

import Config from "../../../config";

const ChangePasswordProfile = (props) => {

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const [clickedSave, setClickedSave] = useState(false);


  //! Cambiare endpoint and Testi
  const datas = {
    title: "Confirm password change with 2-step Verification",
    subtitle:
      "We have sent you an email with the two-factor authentication code to confirm the change",
    button: "Confirm Password Change",
    endpoint: Config.base_url + "accounts/otp-verification/",
    method: "CHANGE_PASSWORD",
  };

  function startPasswordVerification() {

    if (
      newPassword === confirmPassword &&
      newPassword.length >= 10 &&
      newPassword.match(/[a-z]/) &&
      newPassword.match(/[A-Z]/) &&
      newPassword.match(/[0-9]/) &&
      newPassword.match(/[^a-zA-Z0-9]/g) &&
      oldPassword !== ""
    ) {
      //! dISPLAY ERROR
      if (newPassword !== oldPassword) {
        var data = {
          old_password: oldPassword,
          new_password: newPassword,
        };

        setClickedSave(true);
        const url = Config.base_url + "my-profile/change-password/";
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        };
        axios
          .post(url, data, config)
          .then((response) => {

            const verificationBox = document.getElementById("two_step_verified");
            const layover = document.getElementById("layover");
            verificationBox.style.display = "block";
            layover.style.display = "block";
            props.setPopupState(datas);

          })
          .catch((error) => {
            if (error.response.data.old_password) {
              setErrorPassword(error.response.data.old_password[0])
            }
            if (error.response.data.new_password) {
              setErrorPassword(error.response.data.new_password[0])
            }
            if (error.response.data.confirm_password) {
              setErrorPassword(error.response.data.confirm_password[0])
            }
            setClickedSave(false);
          });
      }
    }
  }

  return (
    <>
      <section id="password_profile">
        <div style={{ paddingTop: 24 }}></div>
        <div id="password_profile" className={Style.card_profile}>
          <h2 className={Style.card_title}>Change your Password <span className="error_input ms-3">{errorPassword}</span></h2>

          <Row className="mb-4">
            <Col sm={3} className={Style.label_box}>
              <label
                htmlFor="currentpasswordchange"
                className={Style.col_form_label}
              >
                Current password
              </label>
            </Col>
            <Col sm={9}>
              <div className="input-group input-group-sm-vertical">
                <input
                  type="password"
                  className={Style.form_control}
                  name="currentpasswordchange"
                  id="currentpasswordchange"
                  onChange={(e) => setOldPassword(e.target.value)}
                  placeholder="Enter current password"
                  aria-label="Enter current password"
                />
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={3} className={Style.label_box}>
              <label
                htmlFor="newpasswordchange"
                className={Style.col_form_label}
              >
                New password
              </label>
            </Col>
            <Col sm={9}>
              <div className="input-group input-group-sm-vertical">
                <input
                  type="password"
                  className={Style.form_control}
                  name="newpasswordchange"
                  id="newpasswordchange"
                  placeholder="Enter your new password"
                  aria-label="Enter your new password"
                  minLength={10}
                  onChange={(e) => setPassword(e.target.value)}
                  onInput={() => strongTest()}
                />
                <img
                  id="newpasswordchangeprofile"
                  className={Style.visibility}
                  src={NotVisible}
                  alt=""
                  width={16}
                  onClick={() => showNewPassword()}
                />
                <img
                  className="verified_password"
                  src={Verified}
                  alt=""
                  width={16}
                />
              </div>

              {/* Check password */}
              <div
                className={Style.password_efficency}
                id="password_info_profile"
              ></div>
              <p
                id="password_info_text_profile"
                className={Style.password_info_text}
              ></p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={3} className={Style.label_box}>
              <label
                htmlFor="confirmnewpasswordchange"
                className={Style.col_form_label}
              >
                Confirm new password
              </label>
            </Col>
            <Col sm={9}>
              <div className="input-group input-group-sm-vertical">
                <input
                  type="password"
                  className={Style.form_control}
                  name="confirmnewpasswordchange"
                  id="confirmnewpasswordchange"
                  placeholder="Confirm your new password"
                  aria-label="Confirm your new password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onInput={() => samePassword()}
                />
                <img
                  id="confirmnewpasswordchangeprofile"
                  className={Style.visibility}
                  src={NotVisible}
                  alt=""
                  width={16}
                  onClick={() => showConfirmNewPassword()}
                />
                <img
                  className="verified_password"
                  src={Verified}
                  alt=""
                  width={16}
                />
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={{ span: 9, offset: 3 }}>
              <div className="mb-3">
                <p className={Style.col_form_label_p}>Password requirements</p>
                <p className={Style.requiremend_password}>
                  Ensure that these requirements are met:
                </p>
              </div>
              <p className={Style.requiremend_password}>
                Minimum 10 characters long - the more, the better
              </p>
              <p className={Style.requiremend_password}>
                At least one lowercase character
              </p>
              <p className={Style.requiremend_password}>
                At least one uppercase character
              </p>
              <p className={Style.requiremend_password}>
                At least contain one symbol character
              </p>
              <p className={Style.requiremend_password}>
                At least contain one number character
              </p>
            </Col>
          </Row>

          <Row className="mt-4 mb-2">
            <Col sm={12} className={Style.position_button}>
              {clickedSave === false
                ?
                oldPassword !== '' && newPassword !== '' && confirmPassword !== '' && newPassword !== oldPassword && newPassword === confirmPassword && newPassword.length >= 10 && newPassword.match(/[a-z]/i) && newPassword.match(/[A-Z]/) && newPassword.match(/[0-9]/) && newPassword.match(/[^a-zA-Z0-9]/g)
                  ? <button className={Style.button} onClick={() => startPasswordVerification()} >Save changes</button>
                  : <button disabled className={Style.button} >Save changes</button>
                : <button disabled className={Style.button} >Waiting...</button>
              }

            </Col>
          </Row>
        </div>
      </section>
    </>
  );

  function showNewPassword() {
    const password = document.getElementById("newpasswordchange");
    const icon = document.getElementById("newpasswordchangeprofile");
    password.type === "password"
      ? (password.type = "text")
      : (password.type = "password");
    password.type === "password"
      ? (icon.src = NotVisible)
      : (icon.src = Visible);
  }

  function showConfirmNewPassword() {
    const password = document.getElementById("confirmnewpasswordchange");
    const icon = document.getElementById("confirmnewpasswordchangeprofile");
    password.type === "password"
      ? (password.type = "text")
      : (password.type = "password");
    password.type === "password"
      ? (icon.src = NotVisible)
      : (icon.src = Visible);
  }

  /* Controllo pass */
  function strongTest() {
    const password = document.getElementById("newpasswordchange");
    const passwordInfo = document.getElementById("password_info_profile");
    const passwordInfoText = document.getElementById(
      "password_info_text_profile"
    );

    if (password.value.length < 10) {
      passwordInfoText.innerHTML = "Weak. Must cotain at least 10 characters";
      passwordInfo.style.backgroundColor = "#EA4479";
      passwordInfo.style.width = "20%";
      passwordInfo.style.display = "block";
    }
    // se contine piu di 10 caratteri
    else if (password.value.length >= 10) {
      //deve contenere una lettera maiuscola
      if (!password.value.match(/[a-z]/)) {
        passwordInfoText.innerHTML =
          "So-so. Must contain at least 1 lowercase letter";
        passwordInfo.style.backgroundColor = "#F0DC57";
        passwordInfo.style.width = "50%";
        passwordInfo.style.display = "block";
      }
      //deve contenere una lettera minuscola
      else if (!password.value.match(/[A-Z]/)) {
        passwordInfoText.innerHTML =
          "So-so. Must contain at least 1 capital letter";
        passwordInfo.style.backgroundColor = "#F0DC57";
        passwordInfo.style.width = "50%";
        passwordInfo.style.display = "block";
      }
      //deve contenere un carattere speciale
      else if (!password.value.match(/[^a-zA-Z0-9]/g)) {
        passwordInfoText.innerHTML = "Almost. Must contain special symbol.";
        passwordInfo.style.backgroundColor = "#55ADD9";
        passwordInfo.style.width = "70%";
        passwordInfo.style.display = "block";
      }
      //deve contenere un numeros
      else if (!password.value.match(/[0-9]/)) {
        passwordInfoText.innerHTML = "Good. Must contain at least 1 number";
        passwordInfo.style.backgroundColor = "#55ADD9";
        passwordInfo.style.width = "90%";
        passwordInfo.style.display = "block";
      } else {
        passwordInfoText.innerHTML = "Awesome! You have a secure password";
        passwordInfo.style.backgroundColor = "#5BC142";
        passwordInfo.style.width = "100%";
        passwordInfo.style.display = "block";
      }
    }

    samePassword();
  }

  /* Verificato pass con sblocco bottone */
  function samePassword() {
    const password = document.getElementById("newpasswordchange");
    const passwordConfirm = document.getElementById("confirmnewpasswordchange");
    const verified = document.querySelectorAll(".verified_password");
    const newPassEye = document.getElementById("newpasswordchangeprofile");
    const confirmPassEye = document.getElementById(
      "confirmnewpasswordchangeprofile"
    );

    //se le due password sono uguali
    if (
      password.value === passwordConfirm.value &&
      password.value.length >= 10 &&
      password.value.match(/[0-9]/) &&
      password.value.match(/[A-Z]/) &&
      password.value.match(/[a-z]/i) &&
      password.value.match(/[^a-zA-Z0-9]/g)
    ) {
      newPassEye.style.right = "25px";
      confirmPassEye.style.right = "25px";
      verified.forEach((element) => {
        element.style.display = "block";
      });
    } else {
      newPassEye.style.right = "15px";
      confirmPassEye.style.right = "15px";
      verified.forEach((element) => {
        element.style.display = "none";
      });
    }
  }
};

export default ChangePasswordProfile;
