import React from "react";

// Libraries
import ThreeDots from "react-loading-icons/dist/esm/components/three-dots";

// Utils
import classNames from "../../../Utils/classNames";

// Styles
import Style from "./Button.module.css";

const Button = ({
    children,
    className,
    disabled = false,
    isLoading = false,
    loader = <ThreeDots height={10} />,
    onClick,
    style,
}) => {
    return (
        <div
            style={style}
            className={classNames(
                Style.buttonClass,
                (disabled || isLoading) && Style.buttonDisabled,
                className
            )}
            onClick={() => {
                if (disabled === false && isLoading === false) {
                    if (onClick) {
                        onClick();
                    }
                }
            }}
        >
            {isLoading ? loader : children}
        </div>
    );
};

export default Button