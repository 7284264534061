import React, { useState } from 'react'
import Style from './PreferencesProfile.module.css'
import { Row, Col, Form } from 'react-bootstrap'
import axios from 'axios'

import Config from '../../../config'

const PreferencesProfile = (props) => {

    const [clubVisibility, setClubVisibility] = useState(props.clubVisibility);
    const [emailUpdates, setEmailUpdates] = useState(props.emailUpdates);
    // const [time, setTime] = useState(props.timezone);
    const [language, setLanguage] = useState(props.language);


    function sendPreferences() {
        const data = {
            club_visibility: clubVisibility,
            email_updates: emailUpdates,
            timezone: "GMT+4", //hardcoded perchè al momento è rimosso
            language: language,
        };
        callPreferences(data)
    }

    function callPreferences(data) {
        const url = Config.base_url + "my-profile/change-preferences/";
        const token = localStorage.getItem("token");

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Token ${token}`,
            },
        };

        axios
            .post(url, data, config)
            .then((response) => {
                successSave();

            })
            .catch((error) => {
                errorSave();
            });
    }

    function successSave() {
        let saveButton = document.getElementById('save_button_preferencies');
        let text = document.getElementById('text_preferencies');
        let secondText = document.getElementById('text_saved_preferencies');

        text.style.opacity = '0';
        saveButton.classList.add('success_call');
        secondText.replaceChildren('Saved');
        secondText.style.opacity = '100';

        setTimeout(() => {
            secondText.style.opacity = '0';
            saveButton.classList.remove('success_call');
            text.style.opacity = '100';
        }, 1500);

    }

    function errorSave() {
        let saveButton = document.getElementById('save_button_preferencies');
        let text = document.getElementById('text_preferencies');
        let secondText = document.getElementById('text_saved_preferencies');

        saveButton.classList.add('error_call');
        text.style.opacity = '0';
        secondText.replaceChildren('Error');
        secondText.style.opacity = '100';

        setTimeout(() => {
            secondText.style.opacity = '0';
            saveButton.classList.remove('error_call');
            text.style.opacity = '100';
        }, 1500);

    }

    return (
        <>
            <section id='preferences'>
                <div style={{ paddingTop: 24 }}></div>
                <div className={Style.card_profile}>
                    <h2 className={Style.card_title}>Preferences</h2>

                    <Row className='mb-4'>
                        <Col sm={3} className={Style.label_box}>
                            <label htmlFor="languageprofile" className={Style.col_form_label}>Language</label>
                        </Col>
                        <Col sm={9}>
                            <div className="input-group input-group-sm-vertical">
                                <input type="text" className={Style.form_control} name="languageprofile" id="languageprofile" placeholder="English (UK)" aria-label="English (UK)" value={language} readOnly />
                            </div>
                        </Col>
                    </Row>

                    <Row className='mb-4'>
                        <Col sm={3} className={Style.label_box}>
                            <label htmlFor="timezoneprofile" className={Style.col_form_label}>Change Language</label>
                        </Col>
                        <Col sm={9}>
                            <Form.Select onChange={(e) => setLanguage(e.target.value)}>
                                {props.languageList.map(el => {
                                    return (
                                        <option className={Style.option_gtm} key={el.id} value={el.value}>{el.label + '...'}</option>
                                    )
                                })}
                            </Form.Select>
                        </Col>
                    </Row>

                    {/* <Row className='mb-4'>
                        <Col sm={3} className={Style.label_box}>
                            <label htmlFor="timezoneprofile" className={Style.col_form_label}>Time zone</label>
                        </Col>
                        <Col sm={9}>
                            <div className="input-group input-group-sm-vertical">
                                <input type="text" className={Style.form_control} name="timezoneprofile" id="timezoneprofile" placeholder="UTC + 04:00" aria-label="UTC + 04:00" value={time} readOnly />
                            </div>
                        </Col>
                    </Row>

                    <Row className='mb-4'>
                        <Col sm={3} className={Style.label_box}>
                            <label htmlFor="timezoneprofile" className={Style.col_form_label}>Change Time zone</label>
                        </Col>
                        <Col sm={9}>
                            <Form.Select onChange={(e) => setTime(e.target.value)}>
                                {props.timezoneList.map(timezone => {
                                    return (
                                        <option className={Style.option_gtm} key={timezone.id} value={timezone.value}>{timezone.label.slice(0, 70) + '...'}</option>
                                    )
                                })}
                            </Form.Select>
                        </Col>
                    </Row> */}

                    <label htmlFor="notifyprofile" className={Style.label_checkbox}>
                        <Row>
                            <Col sm={9} className={Style.col_label}>
                                <p>Notify me if there are any changes</p>
                                <p>Get included on early releases for new Poliant features.</p>
                            </Col>
                            <Col sm={3} className={Style.col_switch}>
                                <label className={Style.form_switch}>
                                    <input className={Style.form_check_input} type="checkbox" defaultChecked={emailUpdates} onChange={(e) => setEmailUpdates(e.target.checked)} name="notifyprofile" id="notifyprofile" />
                                    <i></i>
                                </label>

                            </Col>
                        </Row>
                    </label>

                    <label htmlFor="showinfoinclubprofile" className={Style.label_checkbox}>
                        <Row>
                            <Col sm={9} className={Style.col_label}>
                                <p>Show all info to your club members</p>
                                <a href="#!" className='text-primary ps-1'>More about your Profile Info.</a>
                            </Col>
                            <Col sm={3} className={Style.col_switch}>
                                <label className={Style.form_switch}>
                                    <input className={Style.form_check_input} type="checkbox" defaultChecked={clubVisibility} onChange={(e) => setClubVisibility(e.target.checked)} name="showinfoinclubprofile" id="showinfoinclubprofile" />
                                    <i></i>
                                </label>
                            </Col>
                        </Row>
                    </label>


                    <Row className='mt-3 mb-2'>
                        <Col sm={12} className={Style.position_button}>

                            <button id='save_button_preferencies' className={Style.button} onClick={() => sendPreferences()}>
                                <span id='text_preferencies'>Save changes</span>
                                <span id='text_saved_preferencies' className={Style.saved_text}>Saved</span>
                            </button>
                        </Col>
                    </Row>



                </div>
            </section>
        </>
    )
}

export default PreferencesProfile