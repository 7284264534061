import React, { useState, useEffect } from 'react'

// Libraires
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useQuery from "@hybris-software/use-query"
import { PermissionRoute } from '@hybris-software/use-auth';

// Components
import Button from '../../Commons/Button/Button';
import AuthLoader from '../../AuthLoader/AuthLoader';

// Images
import LogoPoliant from "../../../Assets/img/logos/poliant_logo.webp";

// Utils
import axios from 'axios';

// Styles
import Style from './SignIntermediary.module.css'

//! TO CHANGE
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const SignIntermediary = () => {
    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(false);
    const [stepIntermediaty, setStepIntermediaty] = useState(1);
    const [dataContract, setDataContract] = useState(null);
    const [readContract, setReadContract] = useState(false);
    const [signContract, setSignContract] = useState(false);
    const [contractUrl, setContractUrl] = useState(null);

    const getIntermediary = useQuery({
        url: "contract/intermediary/",
        method: "GET",
        executeImmediately: false,
        onSuccess: (response) => {
            setDataContract(response.data)
            setAuthenticated(true);
            axios
                .get(response.data.original_file, { responseType: 'blob' })
                .then(response => {
                    return new File([response.data], `${response.data.name}.pdf`, { type: "application/pdf" })
                })
                .then(file => {
                    setContractUrl(URL.createObjectURL(file));
                })
        },
        onError: (error) => {
            setStepIntermediaty(2);
            setAuthenticated(true);
        }
    })

    useQuery({
        url: "auth/user/",
        method: "GET",
        executeImmediately: true,
        onSuccess: (response) => {
            if (response.data.status === "ACTIVE") {
                getIntermediary.executeQuery()
            }
        }
    })

    useEffect(() => { document.title = "Poliant | Intermediary Agreement"; }, [])

    const signIntermediary = useQuery({
        url: "contract/intermediary/",
        method: "POST",
        executeImmediately: false,
        onSuccess: (response) => {
            navigate("/my-profile");
        },
        onError: (error) => {
            setStepIntermediaty(2);
        }
    })

    return (
        <PermissionRoute
            forLoggedUser={true}
            loader={<AuthLoader />}
            unAuthorizedAction={() => navigate("/auth/login")}
        >
            <section>
                <img className={Style.logo} src={LogoPoliant} alt="Poliant logo" />
                <div className={Style.SignIntermediary}>
                    <div className={Style.container}>

                        {authenticated // Cambaire lo step con il risultato success o error della chiamata
                            ? stepIntermediaty === 1 ?

                                <div className={Style.box_sign}>

                                    <h2 className={Style.sign_text}>To sign this contract correctly, you should follow these guidelines:</h2>

                                    <hr />
                                    <h2 className={Style.sign_text}>Document to be signed</h2>
                                    <Row>
                                        <Col sm={12} className={Style.column}>
                                            <div className={Style.single_file}>
                                                <div className='d-flex align-items-center'>
                                                    <div>
                                                        <span>{dataContract.name} - Poliant</span>
                                                        <span className={Style.information}>{dataContract.last_update} / {dataContract.original_file_size}</span>
                                                    </div>
                                                </div>
                                                <div className={Style.download_contract}>
                                                    <a href={contractUrl} download={dataContract.name + '.pdf'} target='_blank' rel="noopener noreferrer">
                                                        <FontAwesomeIcon icon={faDownload} />
                                                    </a>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row >
                                    <hr />

                                    <h3 className={Style.sign_text}>View the entire contract to accept the Intermediary Agreement:</h3>

                                    <div className={Style.miniReaderContainer}>
                                        <object data={contractUrl} type="application/pdf" className={Style.miniReaderContent}>
                                        </object>
                                    </div>

                                    <div className='d-flex align-content-center mb-2 mt-3'>
                                        <input type="checkbox" name="read_contract" id="read_contract" defaultChecked={readContract} onChange={(e) => setReadContract(e.target.checked)} />
                                        <label className={Style.label_sign} htmlFor="read_contract">I declare that I have read the Intermediary Agreement</label>
                                    </div>

                                    <div className='d-flex align-content-center mb-2 mt-1'>
                                        <input type="checkbox" name="sign_contract" id="sign_contract" defaultChecked={signContract} onChange={(e) => setSignContract(e.target.checked)} />
                                        <label className={Style.label_sign} htmlFor="sign_contract">I declare that I wish to digitally sign the contract Intermediary Agreement.</label>
                                    </div>


                                    <Button
                                        isLoading={signIntermediary.isLoading}
                                        onClick={() => signIntermediary.executeQuery()}
                                        disabled={readContract && signContract ? false : true}
                                    >
                                        Sign Document Digitally
                                    </Button>

                                </div>
                                : stepIntermediaty === 2 &&
                                <div className={Style.box_allow}>
                                    <div className='text-center'>
                                        <h1 className='mb-4'>Error!</h1>
                                        <p className='mb-2'>An error occurred during the procedure, or it was interrupted. Please try again later.</p>
                                        <button className={Style.sign_in_button} style={{ margin: '15px 5px 5px' }} onClick={() => window.location.reload()}>
                                            Try Again
                                        </button>
                                        <div>or</div>
                                        <button className={Style.sign_in_button} style={{ margin: '5px' }} onClick={() => navigate("/my-profile")}>
                                            Back to Profile
                                        </button>
                                    </div>
                                </div>
                            :
                            <AuthLoader text={"Generating contract..."} />
                        }
                    </div>
                </div>
            </section>
        </PermissionRoute >
    )
}

export default SignIntermediary