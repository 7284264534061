import React, { useState, useEffect } from "react";

// Libraries
import { PermissionRoute } from "@hybris-software/use-auth"
import useQuery from "@hybris-software/use-query";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// Components
import Loader from "../Components/Loader/Loader";
import Wallets from "../Components/MyProfile/Wallets/Wallets";
import MenuProfile from "../Components/MyProfile/MenuProfile/MenuProfile";
import CoverProfile from "../Components/MyProfile/CoverProfile/CoverProfile";
import EmailProfile from "../Components/MyProfile/EmailProfile/EmailProfile";
import BasicInfoUser from "../Components/MyProfile/BasicInfoUser/BasicInfoUser";
import PopupKycReset from "../Components/MyProfile/PopupKycReset/PopupKycReset";
import TwoFactorPopup from "../Components/MyProfile/TwoFactorPopup/TwoFactorPopup";
import PreferencesProfile from "../Components/MyProfile/PreferencesProfile/PreferencesProfile";
import NotificationProfile from "../Components/MyProfile/NotificationProfile/NotificationProfile";
import RecentDevicesProfile from "../Components/MyProfile/RecendDevicesProfile/RecentDevicesProfile";
import ChangePasswordProfile from "../Components/MyProfile/ChangePasswordProfile/ChangePasswordProfile";
// import DeleteProfile from "../Components/MyProfile/DeleteProfile/DeleteProfile";
// import TwoStepProfile from "../Components/MyProfile/TwoStepProfile/TwoStepProfile";
// import ConnectedAccountProfile from "../Components/MyProfile/ConnectedAccountProfile/ConnectedAccountProfile";

const MyProfile = () => {
  const navigate = useNavigate();
  const [popupState, setPopupState] = useState("");
  const [viewData, setViewData] = useState(null);
  // const [inDelete, setInDelete] = useState(false);
  // const [KycStatus, setKycStatus] = useState("");

  useEffect(() => { document.title = "Poliant | My Profile"; }, []);

  //+ Call Data page My Profile 
  const viewDataApi = useQuery({
    url: "my-profile/my-profile/",
    method: "GET",
    executeImmediately: false,
    onSuccess: (data) => {
      setViewData(data.data);
      // setKycStatus(response.data.KYC.KYCStatus);
    }
  })

  return (
    <PermissionRoute
      forLoggedUser={true}
      loader={<Loader />}
      unAuthorizedAction={() => navigate("/auth/login")}
      permissionController={(data) => {
        if (data.data.status === "ACTIVE" && data.data.is_valid === true) {
          viewDataApi.executeQuery();
          return { value: true }
        }
        else {
          return { value: false, status: data.data.status, is_valid: data.data.is_valid }
        }
      }}
      forbiddenAction={(data) => {
        if (data.is_valid === false) { localStorage.clear(''); navigate("/auth/login") }
        if (data.status === "PENDING") { navigate("/auth/register-survey"); }
        if (data.status === "PENDING_SURVEYED") { navigate("/auth/register-survey-sent"); }
        if (data.status === "PENDING_EXTRA_DATA") { navigate("/auth/register-complete"); }
        if (data.status === "PENDING_TERMS") { navigate("/auth/accept-terms-and-conditions"); }
        if (data.status === "PENDING_NDA") { navigate("/auth/sign-non-disclosure-agreement"); }
        if (data.status === "DELETED") { navigate("/auth/login"); }
      }}
    >
      {viewData && (
        <>
          <TwoFactorPopup popupState={popupState} /* setInDelete={setInDelete} */ />
          <PopupKycReset popupState={popupState} />

          <Row>
            <MenuProfile />
            <Col sm={12} md={12} lg={8} className="mb-3">
              <CoverProfile {...viewData.profileHeader} />
              <BasicInfoUser
                setPopupState={setPopupState}
                {...viewData.basicInformation}
              /* KycStatus={KycStatus} */
              />
              <Wallets setPopupState={setPopupState} {...viewData.wallet} />
              <EmailProfile setPopupState={setPopupState} {...viewData.email} />
              <ChangePasswordProfile setPopupState={setPopupState} />
              <PreferencesProfile {...viewData.preferences} />
              {/* <TwoStepProfile {...viewData.KYC} /> */}
              <RecentDevicesProfile {...viewData.recentDevices} />
              <NotificationProfile {...viewData.notificationPreferences} />
              {/* <ConnectedAccountProfile /> */}
              {/* <DeleteProfile
                inDelete={inDelete}
                setInDelete={setInDelete}
                setPopupState={setPopupState}
              /> */}
            </Col>
          </Row>
        </>
      )}
    </PermissionRoute>
  );
};

export default MyProfile;
