import React, { useState, useEffect } from "react";
import Style from "./QrCodeBackground.module.css";
import CloseIcon from "../../../Assets/img/icon/close-icon.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faFacebook, faTelegram, faDiscord, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faLink, faDownload } from "@fortawesome/free-solid-svg-icons";

import Config from "../../../config";

const QrCodeBackground = () => {
  const navigate = useNavigate();

  const [lanscape, setLanscape] = useState(null);
  const [squared, setSquared] = useState(null);
  const [portrait, setPortrait] = useState(null);
  const [seePortrait, setSeePortrait] = useState(true);
  const [seeSquared, setSeeSquared] = useState(false);
  const [seeLandscape, setSeeLandscape] = useState(false);

  useEffect(() => {
    if (localStorage.token) {
      const getViewData = async () => {
        const response = await axios.get(
          Config.base_url + "referral/referral-page/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        );
        setLanscape(response.data.referralCard.referral_banner_landscape);
        setSquared(response.data.referralCard.referral_banner_squared);
        setPortrait(response.data.referralCard.referral_banner_portrait);
      };
      getViewData();
    } else {
      navigate("/auth/login");
    }
  }, [navigate]);

  function closeQrBack() {
    const mainContent = document.getElementsByClassName("main_content");
    mainContent[0].classList.remove('unset_scroll');
    document.getElementById("qr_code_background").style.display = "none";
    document.getElementById("layover").style.display = "none";
  }

  return (
    <>
      <div id="qr_code_background" className={Style.qr_code_background}>
        <span className={Style.close_button} onClick={() => closeQrBack()}>
          <img src={CloseIcon} alt="Close" />
        </span>

        <div className="text-center mt-4">
          {seePortrait && (
            <img
              className={Style.qrcode_img}
              src={portrait}
              alt="Qr Portrait"
              width={300}
            />
          )}
          {seeSquared && (
            <img
              className={Style.qrcode_img}
              src={squared}
              alt="Qr Sqared"
              width={300}
            />
          )}
          {seeLandscape && (
            <img
              className={Style.qrcode_img}
              src={lanscape}
              alt="Qr Landscape"
              width={300}
            />
          )}
        </div>

        <div className={Style.container_banner}>
          <div
            className={Style.set_dimension}
            onClick={() => {
              setSeePortrait(true);
              setSeeSquared(false);
              setSeeLandscape(false);
            }}
          >
            <div className="d-flex">
              <div className="d-flex">
                <div className="rettangolo_laterale_portrait"></div>
                <div className="rettangolo_centrale_portrait"></div>
                <div className="rettangolo_laterale_portrait"></div>
              </div>
              <div className="mx-3">9:16</div>
              <div>ok</div>
            </div>
          </div>
          <div
            className={Style.set_dimension}
            onClick={() => {
              setSeePortrait(false);
              setSeeSquared(true);
              setSeeLandscape(false);
            }}
          >
            <div className="d-flex">
              <div className="d-flex">
                <div className="rettangolo_laterale_squared"></div>
                <div className="rettangolo_centrale_squared"></div>
                <div className="rettangolo_laterale_squared"></div>
              </div>
              <div className="mx-3">1:1</div>
              <div>ok</div>
            </div>
          </div>
          <div
            className={Style.set_dimension}
            onClick={() => {
              setSeePortrait(false);
              setSeeSquared(false);
              setSeeLandscape(true);
            }}
          >
            <div className="d-flex">
              <div className="d-flex">
                <div className="rettangolo_centrale_landscape"></div>
              </div>
              <div className="mx-3">16:9</div>
              <div>ok</div>
            </div>
          </div>
        </div>

        <div className={Style.social_box}>
          <div className={Style.circle}>
            <FontAwesomeIcon icon={faTwitter} className={Style.twitter} />
          </div>
          <div className={Style.circle}>
            <FontAwesomeIcon icon={faFacebook} className={Style.facebook} />
          </div>
          <div className={Style.circle}>
            <FontAwesomeIcon icon={faTelegram} className={Style.telegram} />
          </div>
          <div className={Style.circle}>
            <FontAwesomeIcon icon={faDiscord} className={Style.discord} />
          </div>
          <div className={Style.circle}>
            <FontAwesomeIcon icon={faWhatsapp} className={Style.whatsapp} />
          </div>
          <div className={Style.circle}>
            <FontAwesomeIcon icon={faLink} className={Style.link} />
          </div>
          <div className={Style.circle}>
            <FontAwesomeIcon icon={faDownload} className={Style.download} />
          </div>
        </div>
      </div>
    </>
  );
};

export default QrCodeBackground;
