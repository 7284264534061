import React, { useState, useEffect } from 'react'
import Style from './QrCode.module.css'
import CloseIcon from '../../../Assets/img/icon/close-icon.png'
import PoliantLogo from '../../../Assets/img/logos/poliant_logo.webp'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import Config from '../../../config.js'

const QrCode = () => {
    const navigate = useNavigate();

    const [qrcode, setQrcode] = useState(null);
    const [idReferral, setIdReferral] = useState([]);

    useEffect(() => {
        if (localStorage.token) {
            const getViewData = async () => {
                const response = await axios.get(
                    Config.base_url + "referral/referral-page/",
                    { headers: { Authorization: `Token ${localStorage.getItem("token")}` } }
                );
                setQrcode(response.data.referralCard.referral_qr_code);
                setIdReferral(response.data.referralCard.referral_id);
            };
            getViewData();
        } else {
            navigate('/auth/login');
        }
    }, [navigate]);

    function closeQr() {
        const mainContent = document.getElementsByClassName("main_content");
        mainContent[0].classList.remove('unset_scroll');
        document.getElementById('qr_code').style.display = 'none';
        document.getElementById('layover').style.display = 'none';
    }

    return (
        <>
            <div id='qr_code' className={Style.qr_code}>

                <span className={Style.close_button} onClick={() => closeQr()}>
                    <img src={CloseIcon} alt="Close" />
                </span>

                <img className={Style.logo} src={PoliantLogo} alt="" />

                <h2 className={Style.text}>Register now and join in the Best Crypto Club in the World</h2>


                <div className={Style.body_qr}>
                    <img className={Style.qrcode_img} src={qrcode} alt="" />
                    <div className='text-center pb-3'>
                        <div className={Style.code_referral}>Referral ID</div>
                        <div className={Style.referral_id}>{idReferral}</div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default QrCode