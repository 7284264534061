import React, { /* useState, */ useEffect } from "react";

// Libraries
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useQuery from "@hybris-software/use-query";
import { PermissionRoute } from "@hybris-software/use-auth";

// Components
import Loader from "../Components/Loader/Loader";
import AddNewUser from "../Components/AddNewUser/AddNewUser";

const AddUser = () => {
  const navigate = useNavigate();
  // const [isBroker, setIsBroker] = useState(null);

  useEffect(() => { document.title = "Poliant | Add New User"; }, []);

  //+ Call check broker account 
  const isBrokerApi = useQuery({
    url: "contract/contract-page/",
    method: "GET",
    executeImmediately: false,
    onSuccess: (data) => {
      // setIsBroker(data.data.isBroker);
    }
  })

  return (
    <PermissionRoute
      forLoggedUser={true}
      loader={<Loader />}
      unAuthorizedAction={() => navigate("/auth/login")}
      permissionController={(data) => {
        if (data.data.status === "ACTIVE") {
          isBrokerApi.executeQuery();
          return { value: true }
        }
        else {
          return { value: false, status: data.data.status }
        }
      }}
      forbiddenAction={(data) => {
        if (data.status === "PENDING") { navigate("/auth/register-survey"); }
        if (data.status === "PENDING_SURVEYED") { navigate("/auth/register-survey-sent"); }
        if (data.status === "PENDING_EXTRA_DATA") { navigate("/auth/register-complete"); }
        if (data.status === "PENDING_TERMS") { navigate("/auth/accept-terms-and-conditions"); }
        if (data.status === "PENDING_NDA") { navigate("/auth/sign-non-disclosure-agreement"); }
        if (data.status === "DELETED") { navigate("/auth/login"); }
      }}
    >
      {/* {isBroker && ( */}
      <Row>
        <AddNewUser />
      </Row>
      {/* )} */}
    </PermissionRoute>
  );
};

export default AddUser;
