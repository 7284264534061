import React, { useState } from 'react'
import Style from './NotificationProfile.module.css'
import EmailIcon from '../../../Assets/img/icon/icon_email_notify.png'
import BrowserIcon from '../../../Assets/img/icon/icon_browser_notify.png'
import { Row, Col, Form } from 'react-bootstrap'
// import axios from 'axios'

// import Config from '../../../config'


const NotificationProfile = (props) => {

    const [accountActivityBrowser, setAccountActivityBrowser] = useState(props.accountActivityBrowser);
    const [accountActivityEmail, setAccountActivityEmail] = useState(props.accountActivityEmail);
    const [newBrowserBrowser, setNewBrowserBrowser] = useState(props.newBrowserBrowser);
    const [newBrowserEmail, setNewBrowserEmail] = useState(props.newBrowserEmail);
    const [newDeviceConnectedBrowser, setNewDeviceConnectedBrowser] = useState(props.newDeviceConnectedBrowser);
    const [newDeviceConnectedEmail, setNewDeviceConnectedEmail] = useState(props.newDeviceConnectedEmail);
    const [newDeviceLinkedBrowser, setNewDeviceLinkedBrowser] = useState(props.newDeviceLinkedBrowser);
    const [newDeviceLinkedEmail, setNewDeviceLinkedEmail] = useState(props.newDeviceLinkedEmail);
    const [newsForYouBrowser, setNewsForYouBrowser] = useState(props.newsForYouBrowser);
    const [newsForYourEmail, setNewsForYourEmail] = useState(props.newsForYourEmail);
    const [whenNotification, setWhenNotification] = useState(props.whenNotification);


    // function callSetNotification() {
    //     const data = {
    //         "news_for_you_email": newsForYourEmail,
    //         "news_for_you_browser": newsForYouBrowser,
    //         "account_activity_email": accountActivityEmail,
    //         "account_activity_browser": accountActivityBrowser,
    //         "new_browser_email": newBrowserEmail,
    //         "new_browser_browser": newBrowserBrowser,
    //         "new_device_linked_email": newDeviceLinkedEmail,
    //         "new_device_linked_browser": newDeviceLinkedBrowser,
    //         "new_device_connected_email": newDeviceConnectedEmail,
    //         "new_device_connected_browser": newDeviceConnectedBrowser,
    //         "when_notification": whenNotification,
    //     }
    //     callNotifications(data);
    // }

    // function callNotifications(data) {
    //     const url = Config.base_url + "my-profile/change-notification-preferences/";
    //     const token = localStorage.getItem("token");

    //     const config = {
    //         headers: {
    //             "Content-Type": "application/json",
    //             'Authorization': `Token ${token}`,
    //         },
    //     };

    //     axios
    //         .post(url, data, config)
    //         .then((response) => {
    //             successSave();

    //         })
    //         .catch((error) => {
    //             errorSave();
    //         });
    // }

    // function successSave() {
    //     let saveButton = document.getElementById('save_button_notification');
    //     let text = document.getElementById('text_notification');
    //     let secondText = document.getElementById('text_saved_notification');

    //     text.style.opacity = '0';
    //     saveButton.classList.add('success_call');
    //     secondText.replaceChildren('Saved');
    //     secondText.style.opacity = '100';

    //     setTimeout(() => {
    //         secondText.style.opacity = '0';
    //         saveButton.classList.remove('success_call');
    //         text.style.opacity = '100';
    //     }, 1500);

    // }

    // function errorSave() {
    //     let saveButton = document.getElementById('save_button_notification');
    //     let text = document.getElementById('text_notification');
    //     let secondText = document.getElementById('text_saved_notification');

    //     saveButton.classList.add('error_call');
    //     text.style.opacity = '0';
    //     secondText.replaceChildren('Error');
    //     secondText.style.opacity = '100';

    //     setTimeout(() => {
    //         secondText.style.opacity = '0';
    //         saveButton.classList.remove('error_call');
    //         text.style.opacity = '100';
    //     }, 1500);

    // }


    return (
        <>
            <section id='notification'>
                <div style={{ paddingTop: 24 }}></div>

                <div className={Style.card_profile}>
                    <div className={Style.coming_soon_nofification}></div>
                    <h2 className={Style.card_title}>
                        Notifications
                        <span className='badge bg-info-2'>Coming Soon</span>
                    </h2>
                    <p className={Style.card_info_text}>View and manage devices where you’re currently logged in.</p>

                    <div className="table-responsive datatable-custom">
                        <table className="table table-thead-bordered table-nowrap table-align-middle table-first-col-px-0">
                            <thead className="thead-light">
                                <tr>
                                    <th>Type</th>
                                    <th className="text-center">
                                        <div className="mb-1">
                                            <img className="avatar avatar-xs" src={EmailIcon} alt="Description" data-hs-theme-appearance="default" />
                                        </div>
                                        Email
                                    </th>
                                    <th className="text-center">
                                        <div className="mb-1">
                                            <img className="avatar avatar-xs" src={BrowserIcon} alt="Description" data-hs-theme-appearance="default" />
                                        </div>
                                        Browser
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>New for you</td>
                                    <td className="text-center">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" defaultChecked={newsForYourEmail} onChange={(e) => setNewsForYourEmail(e.target.checked)} id="editUserModalAlertsCheckbox1" />
                                            <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox1"></label>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" defaultChecked={newsForYouBrowser} onChange={(e) => setNewsForYouBrowser(e.target.checked)} id="editUserModalAlertsCheckbox2" />
                                            <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox2"></label>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Account activity <i className="bi-question-circle text-body ms-1" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Get important notifications about you or activity you've missed" aria-label="Get important notifications about you or activity you've missed"></i></td>
                                    <td className="text-center">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" defaultChecked={accountActivityEmail} onChange={(e) => setAccountActivityEmail(e.target.checked)} id="editUserModalAlertsCheckbox4" />
                                            <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox4"></label>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" defaultChecked={accountActivityBrowser} onChange={(e) => setAccountActivityBrowser(e.target.checked)} id="editUserModalAlertsCheckbox5" />
                                            <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox5"></label>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>A new browser used to sign in</td>
                                    <td className="text-center">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" defaultChecked={newBrowserEmail} onChange={(e) => setNewBrowserEmail(e.target.checked)} id="editUserModalAlertsCheckbox7" />
                                            <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox7"></label>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" defaultChecked={newBrowserBrowser} onChange={(e) => setNewBrowserBrowser(e.target.checked)} id="editUserModalAlertsCheckbox8" />
                                            <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox8"></label>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>A new device is linked</td>
                                    <td className="text-center">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" defaultChecked={newDeviceLinkedEmail} onChange={(e) => setNewDeviceLinkedEmail(e.target.checked)} id="editUserModalAlertsCheckbox10" />
                                            <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox10"></label>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" defaultChecked={newDeviceLinkedBrowser} onChange={(e) => setNewDeviceLinkedBrowser(e.target.checked)} id="editUserModalAlertsCheckbox11" />
                                            <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox11"></label>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>A new device connected <i className="bi-question-circle text-body ms-1" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Email me when a new device connected" aria-label="Email me when a new device connected"></i></td>
                                    <td className="text-center">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" defaultChecked={newDeviceConnectedEmail} onChange={(e) => setNewDeviceConnectedEmail(e.target.checked)} id="editUserModalAlertsCheckbox13" />
                                            <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox13"></label>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" defaultChecked={newDeviceConnectedBrowser} onChange={(e) => setNewDeviceConnectedBrowser(e.target.checked)} id="editUserModalAlertsCheckbox14" />
                                            <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox14"></label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <Row>
                        <div className="mb-4">
                            <p className={Style.card_title_inner}>When should we send you notifications?</p>

                            <Col sm={5}>
                                <Form.Select
                                    value={whenNotification}
                                    onChange={(e) => {
                                        setWhenNotification(e.target.value)
                                    }}
                                >
                                    <option value={'ALWAYS'}>Always</option>
                                    <option value={'ONLY_WHEN_IM_ONLINE'}>Only when I'm online</option>
                                </Form.Select>
                            </Col>
                        </div>

                        <p className={Style.card_title_inner}>In order to cut back on noise, email notifications are grouped together and only sent when you're idle or offline.</p>

                    </Row>

                    <Row className='mt-4 mb-2'>
                        <Col sm={12} className={Style.position_button}>

                            <button disabled id='save_button_notification' className={Style.button} /* onClick={() => callSetNotification()} */>
                                <span id='text_notification'>Save changes</span>
                                <span id='text_saved_notification' className={Style.saved_text}>Saved</span>
                            </button>
                        </Col>
                    </Row>

                </div>
            </section >
        </>
    )
}

export default NotificationProfile