import React, { useState, useEffect } from 'react'

// Libraries
import useQuery from "@hybris-software/use-query";
import useForm from "@hybris-software/use-ful-form";
import { useNavigate } from 'react-router-dom'
import { InputField } from "@hybris-software/ui-kit";

// Componets
import Button from '../../Commons/Button/Button';

// Images
import LogoPoliant from "../../../Assets/img/logos/poliant_logo.webp";

// Styles
import Style from './Invitation.module.css'

const Invitation = (props) => {
    const navigate = useNavigate();
    const referralProp = props.referralCode;
    const [loadingAplly, setLoadingAplly] = useState(false);

    var data = props.user;
    data.code = referralProp || '';

    useEffect(() => { document.title = "Poliant | Referral Code registration"; }, []);

    //+ Form datas  
    const form = useForm({
        inputs: {
            code: {
                required: true,
                value: data.code || ""
            },
        }
    })

    //+ Call for CheckCode 
    const checkReferralCode = useQuery({
        url: "referral/code-verification/",
        method: "POST",
        executeImmediately: false,
        onSuccess: (response) => {
            if (response.data !== 400 && response.status === 200) {
                data.code = form.values["code"]
                registerWithCode.executeQuery(data);
            }
        },
        onError: (error) => { }
    })

    //+ Call for Register whit Code 
    const registerWithCode = useQuery({
        url: "auth/registration/",
        method: "POST",
        executeImmediately: false,
        onSuccess: (response) => {
            localStorage.setItem("token", response.data.key);
            navigate("/auth/register-complete");
        },
        onError: (error) => {
            if (error.response.request.status === 400) {
                if (error.response.data) {
                    props.setErrors(error.response.data)
                }
                props.turnBack();
            }
        }

    })

    return (
        <section>
            <img className={Style.logo} src={LogoPoliant} alt="Poliant logo" />

            <div className={Style.referral}>
                <div className={Style.container}>
                    <div className={Style.card}>

                        <div className={Style.header}>
                            <h2>Enter the ID of the user who invited you or the Poliant invitation ID.</h2>
                            <p>Don't you have an invitation?{" "}</p>
                            <Button
                                isLoading={loadingAplly}
                                className={loadingAplly === false ? Style.applyNowButton : Style.applyNowButtonLoading}
                                onClick={(e) => {
                                    setLoadingAplly(true);
                                    props.onSubmit(e)
                                }}
                            >
                                Apply Now
                            </Button>
                        </div>

                        <InputField
                            label={"Poliant ID Invitation"}
                            placeholder={"Referral Code"}
                            showError={false}
                            errorIconVisibility={true}
                            successIconVisibility={true}
                            maxLength={10}
                            {...form.getInputProps("code")}
                        />

                        <Button
                            disabled={!form.isValid()}
                            isLoading={checkReferralCode.isLoading || registerWithCode.isLoading}
                            onClick={() => checkReferralCode.executeQuery(form.getApiBody())}
                        >
                            Code Check &amp; Sing up
                        </Button>

                        <div className={Style.error}>
                            {checkReferralCode.isError && (checkReferralCode.error.response.data.code || "Something went wrong")}
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Invitation