import React from 'react'
import Style from './Sidebar.module.css'
import LogoPoliant from '../../../Assets/img/logos/poliant_logo.webp'
import DashboardIcon from '../../../Assets/img/icon/dashboard.png'
import WalletIcon from '../../../Assets/img/icon/wallet.png'
import SwapIcon from '../../../Assets/img/icon/swap.png'
import RewardIcon from '../../../Assets/img/icon/reward.png'
import LaunchIcon from '../../../Assets/img/icon/launch.png'
import ShopIcon from '../../../Assets/img/icon/shop.png'
import ClubIcon from '../../../Assets/img/icon/club.png'
import RankIcon from '../../../Assets/img/icon/rank.png'
import BonusIcon from '../../../Assets/img/icon/bonus.png'
import ReferralIcon from '../../../Assets/img/icon/referral.png'
import DaoIcon from '../../../Assets/img/icon/dao.png'
import ProposalIcon from '../../../Assets/img/icon/proposal.png'
import HistoryIcon from '../../../Assets/img/icon/history.png'
import ContractIcon from '../../../Assets/img/icon/contract.png'
import MarketingIcon from '../../../Assets/img/icon/marketing.png'
import FaqIcon from '../../../Assets/img/icon/faq.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faInfoCircle, faSun, faWandSparkles } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import UkFlag from '../../../Assets/img/icon/uk-lang.png'

const SidebarMobile = (props) => {


    function dropdown() {
        const dropdown = document.getElementById('reward_drop_mobile');
        const arrow = document.getElementById('arrow_icon_reward_mobile');
        var x = window.matchMedia("(max-width: 1024px)")
        if (x.matches) {
            dropdown.classList.toggle(Style.active)
            arrow.classList.toggle(Style.active_arrow)
        }
    }

    function showMenuProfile(e) {

        if (e.currentTarget.parentNode.children[1].classList.contains("show_language")) {
            e.currentTarget.parentNode.children[1].classList.remove("show_language")
        }
        else {
            e.currentTarget.parentNode.children[1].classList.add("show_language")
        }

    }

    return (
        <>
            <nav id='sidebar_mobile' className={Style.sidebar_container}>
                <div id='sidebar_content_mobile'>

                    <div className={Style.logo_sidebar}>
                        <img className='ms-2' src={LogoPoliant} alt="Poliant Logo" width={110} />
                    </div>

                    <div className='sidebar_scroll_mobile'>
                        <ul className={Style.side_ul}>
                            <Link onClick={() => props.setIsOpen(false)} to={'/my-profile'} className={Style.side_route}>
                                <li className='mt-4'>
                                    <img src={DashboardIcon} alt="" />
                                    <span className={Style.side_menu_title}>Dashboards</span>
                                    <span className='badge badge-pill to-d-none bg-info-2'>New</span>
                                </li>
                            </Link>
                        </ul>

                        <h6 className={Style.etichetta}>coins</h6>
                        <ul className={Style.side_ul}>
                            <a href="#!" className={Style.side_route}>
                                <li className='mt-0'>
                                    <img src={WalletIcon} alt="" />
                                    <span className={Style.side_menu_title}>Wallet</span>
                                    <span className="badge badge-pill to-d-none bg-badge-secondary">
                                        Coming Soon
                                    </span>
                                </li>
                            </a>

                            <a href="#!" className={Style.side_route}>
                                <li className='mt-0'>
                                    <img src={SwapIcon} alt="" />
                                    <span className={Style.side_menu_title}>Swap</span>
                                    <span className="badge badge-pill to-d-none bg-badge-secondary">
                                        Coming Soon
                                    </span>
                                </li>
                            </a>
                            <li className='mt-0 position-relative' onClick={() => dropdown()}>
                                <img src={RewardIcon} alt="" />
                                <span className={Style.side_menu_title}>Rewards</span>
                                <span className="badge badge-pill to-d-none bg-badge-secondary">
                                    Coming Soon
                                </span>
                                <FontAwesomeIcon id='arrow_icon_reward_mobile' icon={faChevronDown} className='arrow' />
                            </li>
                            <ul id='reward_drop_mobile' className={Style.side_ul_drop}>
                                <a href="#!" className={Style.side_route}>
                                    <li className='mb-1 mt-1'>
                                        <span className={Style.side_menu_title}>Arbitrage Pool</span>
                                    </li>
                                </a>
                                <a href="#!" className={Style.side_route}>
                                    <li className='mb-1'>
                                        <span className={Style.side_menu_title}>Liquidity Pool</span>
                                    </li>
                                </a>
                                <a href="#!" className={Style.side_route}>
                                    <li className='mb-1'>
                                        <span className={Style.side_menu_title}>FollowTheWhale Pool</span>
                                    </li>
                                </a>
                            </ul>
                        </ul>

                        <h6 className={Style.etichetta}>modules</h6>
                        <ul className={Style.side_ul}>
                            <a href="#!" className={Style.side_route}>
                                <li className='mt-0'>
                                    <img src={LaunchIcon} alt="" />
                                    <span className={Style.side_menu_title}>Launchpad</span>
                                    <span className="badge badge-pill to-d-none bg-badge-secondary">
                                        Coming Soon
                                    </span>
                                </li>
                            </a>
                        </ul>

                        <h6 className={Style.etichetta}>shop</h6>
                        <ul className={Style.side_ul}>
                            <a href="#!" className={Style.side_route}>
                                <li className='mt-0'>
                                    <img src={ShopIcon} alt="" />
                                    <span className={Style.side_menu_title}>My Shop</span>
                                    <span className="badge badge-pill to-d-none bg-badge-secondary">
                                        Coming Soon
                                    </span>
                                </li>
                            </a>
                        </ul>

                        <h6 className={Style.etichetta}>club</h6>
                        <ul className={Style.side_ul}>
                            <Link onClick={() => props.setIsOpen(false)} to={'/your-club'} className={Style.side_route}>
                                <li className='mt-0'>
                                    <img src={ClubIcon} alt="" />
                                    <span className={Style.side_menu_title}>Your Club</span>
                                    <span className='badge badge-pill to-d-none bg-info-2'>New</span>
                                </li>
                            </Link>
                            <a href="#!" className={Style.side_route}>
                                <li className='mt-0'>
                                    <img src={RankIcon} alt="" />
                                    <span className={Style.side_menu_title}>Your Rank</span>
                                    <span className='badge badge-pill to-d-none bg-badge-secondary'>Coming Soon</span>
                                </li>
                            </a>
                            <a href="#!" className={Style.side_route}>
                                <li className='mt-0'>
                                    <img src={BonusIcon} alt="" />
                                    <span className={Style.side_menu_title}>Bonus</span>
                                    <span className='badge badge-pill to-d-none bg-badge-secondary'>Coming Soon</span>
                                </li>
                            </a>
                            <Link onClick={() => props.setIsOpen(false)} to={'/referral'} className={Style.side_route}>
                                <li className='mt-0'>
                                    <img src={ReferralIcon} alt="" />
                                    <span className={Style.side_menu_title}>Referral Link</span>
                                    <span className='badge badge-pill to-d-none bg-info-2'>New</span>
                                </li>
                            </Link>
                        </ul>

                        <h6 className={Style.etichetta}>dao</h6>
                        <ul className={Style.side_ul}>
                            <a href="#!" className={Style.side_route}>
                                <li className='mt-0'>
                                    <img src={DaoIcon} alt="" />
                                    <span className={Style.side_menu_title}>Your DAO Ranking</span>
                                    <span className="badge badge-pill to-d-none bg-badge-secondary">
                                        Coming Soon
                                    </span>
                                </li>
                            </a>
                            <a href="#!" className={Style.side_route}>
                                <li className='mt-0'>
                                    <img src={ProposalIcon} alt="" />
                                    <span className={Style.side_menu_title}>Proposal</span>
                                    <span className="badge badge-pill to-d-none bg-badge-secondary">
                                        Coming Soon
                                    </span>
                                </li>
                            </a>
                        </ul>

                        <h6 className={Style.etichetta}>docs</h6>
                        <ul className={Style.side_ul}>
                            <li className='mt-0'>
                                <a href="#!" className={Style.side_route}>
                                    <img src={HistoryIcon} alt="" />
                                    <span className={Style.side_menu_title}>History</span>
                                    <span className='badge badge-pill to-d-none bg-badge-secondary'>Coming Soon</span>
                                </a>
                            </li>
                            <Link onClick={() => props.setIsOpen(false)} to={'/contract'} className={Style.side_route}>
                                <li className='mt-0'>
                                    <img src={ContractIcon} alt="" />
                                    <span className={Style.side_menu_title}>Contract</span>
                                    <span className='badge badge-pill to-d-none bg-info-2'>New</span>
                                </li>
                            </Link>
                            <a href="#!" className={Style.side_route}>
                                <li className='mt-0'>
                                    <img src={MarketingIcon} alt="" />
                                    <span className={Style.side_menu_title}>Marketing</span>
                                    <span className='badge badge-pill to-d-none bg-badge-secondary'>Coming Soon</span>
                                </li>
                            </a>
                            <a href="#!" className={Style.side_route}>
                                <li className='mt-0'>
                                    <img src={FaqIcon} alt="" />
                                    <span className={Style.side_menu_title}>FAQ</span>
                                    <span className='badge badge-pill to-d-none bg-badge-secondary'>Coming Soon</span>
                                </li>
                            </a>
                        </ul>

                    </div>

                    <div className={Style.bottom_sidebar}>
                        <div className="position-relative">
                            <FontAwesomeIcon id="mode_menu_mobile" icon={faSun} className={Style.button_effect} onClick={(e) => showMenuProfile(e)} />
                            <div className={Style.setting_popup_sidebar} id='mode_mobile'>
                                <h2 className="mb-3">THEME</h2>
                                <p>
                                    <FontAwesomeIcon icon={faWandSparkles} className='me-2' />
                                    Auto (System Default)
                                </p>
                            </div>
                        </div>
                        <div className="position-relative">
                            <FontAwesomeIcon id="legal_menu_mobile" onClick={(e) => showMenuProfile(e)}
                                icon={faInfoCircle}
                                className={Style.button_effect}
                            />
                            <div className={Style.setting_popup_sidebar_center} id='terms_mobile'>
                                <h2 className="mb-3">LEGAL</h2>
                                <Link to={"/terms-and-condition"} className={Style.side_route}>
                                    <p>
                                        Terms &amp; Conditions
                                    </p>
                                </Link>
                                <p>
                                    Privacy Policy
                                </p>
                            </div>
                        </div>
                        <div className="position-relative">
                            <img id="laguage_menu_mobile" className={Style.button_effect} src={UkFlag} alt="" onClick={(e) => showMenuProfile(e)} />
                            <div className={Style.setting_popup_sidebar_mobile} id='language_mobile'>
                                <h2 className="mb-3">LANGUAGES</h2>
                                <p>
                                    <img className={Style.button_effect + ' me-2'} src={UkFlag} alt="" />
                                    English (UK)
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </nav>
        </>
    )
}

export default SidebarMobile