import React from 'react'

const Page502 = () => {
    return (
        <>
            <div>502</div>
        </>
    )
}

export default Page502