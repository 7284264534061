import React from "react";
import CustomLoader from "../../Assets/img/loader.gif";

const Loader = () => {
  return (
    <div className="w-100 h-100 d-flex">
      <img src={CustomLoader} className="m-auto w-50" alt="" />
    </div>
  );
};

export default Loader
