import React, { useState, useEffect } from 'react'

// Libraries
import useQuery from "@hybris-software/use-query";
import useForm from "@hybris-software/use-ful-form";
import { AuthRoute } from "@hybris-software/use-auth"
import { Link, useNavigate } from 'react-router-dom'
import { InputField } from "@hybris-software/ui-kit";

// Components
import Button from '../../Commons/Button/Button'
import AuthLoader from '../../AuthLoader/AuthLoader'

// Icons
import { HiChevronLeft } from 'react-icons/hi'

// Images
import LogoPoliant from "../../../Assets/img/logos/poliant_logo.webp";

// Styles
import Style from './ForgotPassword.module.css'

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [isSent, setIsSent] = useState(false);

    useEffect(() => { document.title = "Poliant | Forgot password?" }, [])

    //+ Form datas  
    const form = useForm({
        inputs: {
            username: {
                required: true,
                formatter: (v) => (v)
            }
        }
    })

    //+ Call Reset Password 
    const resetPassword = useQuery({
        url: "accounts/forgot-password/",
        method: "POST",
        executeImmediately: false,
        onSuccess: () => {
            setIsSent(true);
        }
    });

    return (
        <AuthRoute
            forLoggedUser={false}
            loader={<AuthLoader />}
            action={() => { navigate("/") }}
        >
            <section>
                <img className={Style.logo} src={LogoPoliant} alt="Poliant logo" />

                <div className={Style.forgotPassword}>
                    <div className={Style.container}>
                        <div className={Style.card}>
                            {!isSent
                                ?
                                <>
                                    <div className={Style.header}>
                                        <h2>Forgot password?</h2>
                                        <p>Enter the email address you used when you joined and we’ll send you instructions to reset your password.</p>
                                    </div>

                                    <InputField
                                        id={"email"}
                                        label={"Your Email / Username"}
                                        placeholder={"email@address.com"}
                                        errorIconVisibility={true}
                                        successIconVisibility={true}
                                        required={true}
                                        {...form.getInputProps("username")}
                                    />

                                    <Button
                                        disabled={!form.isValid()}
                                        isLoading={resetPassword.isLoading}
                                        onClick={() => resetPassword.executeQuery(form.getApiBody())}
                                    >
                                        Submit
                                    </Button>
                                    <div className={Style.error}>{resetPassword.isError && (resetPassword.error.response.data.Error || "Something went wrong")}</div>

                                    <div className={Style.backLink}>
                                        <HiChevronLeft />
                                        <Link to='/auth/login'>Back to Sign in</Link>
                                    </div>

                                </>
                                :
                                <>
                                    <div className={Style.header}>
                                        <h2>Forgot password?</h2>
                                        <p>If your email is in our systems, a recovery email will be sent to you.</p>
                                    </div>
                                    <div className={Style.backLink}>
                                        <HiChevronLeft />
                                        <Link to='/auth/login'>Back to Sign in</Link>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </AuthRoute>
    )
}

export default ForgotPassword