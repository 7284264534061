import React from 'react'
import Style from './MenuProfile.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faAt, faKey, faGear, /* faChartPie */ faMobileScreenButton, faBell, /* faCheckCircle, */ /* faTrashCan, */ faBars, faWallet } from '@fortawesome/free-solid-svg-icons'
import { Col } from 'react-bootstrap'

const MenuProfile = () => {

    //Focus colore ed altro sul menu
    const handleClick = (event) => {

        const arrayIds = ['information_menu', 'wallets_menu', 'email_profile_menu', 'password_profile_menu', 'preferences_menu', /*'kyc_menu',*/ 'devices_menu', 'notification_menu', /* 'accounts_menu', */ /* 'delete_menu' */];
        const identification = event.currentTarget;

        arrayIds.forEach(element => {
            if (element === identification.id) {
                identification.classList.add('active');
            } else {
                document.getElementById(element).classList.remove('active');
            }
        });
    }

    return (
        <>
            <Col sm={12} md={12} lg={4} className={Style.resp_max_menu + ' mb-3'}>
                <div className={Style.display_menu_mobile} onClick={() => displayMenu()}>
                    Menu
                    <FontAwesomeIcon icon={faBars} />
                </div>
                <div id='profile_menu' className={Style.list_menu}>
                    <ul className='mb-0'>
                        <a id='information_menu' href="#information" onClick={(e) => handleClick(e)}>
                            <li>
                                <FontAwesomeIcon icon={faUser} />
                                <span>Basic Information</span>
                            </li>
                        </a>
                        <a id='wallets_menu' href="#wallets_profile" onClick={(e) => handleClick(e)}>
                            <li>
                                <FontAwesomeIcon icon={faWallet} />
                                <span>Wallets</span>
                            </li>
                        </a>
                        <a id='email_profile_menu' href="#email_profile" onClick={(e) => handleClick(e)}>
                            <li>
                                <FontAwesomeIcon icon={faAt} />
                                <span>Email</span>
                            </li>
                        </a>
                        <a id='password_profile_menu' href="#password_profile" onClick={(e) => handleClick(e)}>
                            <li>
                                <FontAwesomeIcon icon={faKey} />
                                <span>Password</span>
                            </li>
                        </a>
                        <a id='preferences_menu' href="#preferences" onClick={(e) => handleClick(e)}>
                            <li>
                                <FontAwesomeIcon icon={faGear} />
                                <span>Preferences</span>
                            </li>
                        </a>
                        {/* <a id='kyc_menu' href="#kyc" onClick={(e) => handleClick(e)}>
                            <li>
                                <FontAwesomeIcon icon={faChartPie} />
                                <span>KYC</span>
                            </li>
                        </a> */}
                        <a id='devices_menu' href="#devices" onClick={(e) => handleClick(e)}>
                            <li>
                                <FontAwesomeIcon icon={faMobileScreenButton} />
                                <span>Recent devices</span>
                            </li>
                        </a>
                        <a id='notification_menu' href="#notification" onClick={(e) => handleClick(e)}>
                            <li>
                                <FontAwesomeIcon icon={faBell} />
                                <span>Notifications</span>
                            </li>
                        </a>
                        {/* <a id='accounts_menu' href="#accounts" onClick={(e) => handleClick(e)}>
                            <li>
                                <FontAwesomeIcon icon={faCheckCircle} />
                                <span>Connected accounts</span>
                            </li>
                        </a> */}
                        {/* <a id='delete_menu' href="#delete" onClick={(e) => handleClick(e)}>
                            <li className='mb-0'>
                                <FontAwesomeIcon icon={faTrashCan} />
                                <span>Delete account</span>
                            </li>
                        </a> */}
                    </ul>
                </div>
            </Col>
        </>
    )

    function displayMenu() {
        const menu = document.getElementById('profile_menu');
        menu.classList.toggle(Style.active);
    }
}

export default MenuProfile