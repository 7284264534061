import React, { useEffect } from "react";

// Libraries
import { useNavigate, Link } from "react-router-dom";

// Images
import LogoutImage from "../../../Assets/img/logout.png";
import LogoPoliant from "../../../Assets/img/logos/poliant_logo.webp";

// Styles
import Style from "./Logout.module.css";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Poliant | Logout";
    // Pulire tutte le informazioni di autenticazione
    localStorage.removeItem("token");
    localStorage.removeItem("user_status");
    localStorage.removeItem("otp_verified");
  }, [navigate]);

  return (
    <section>
      <img className={Style.logo} src={LogoPoliant} alt="Poliant logo" />
      <div className={Style.logout}>
        <div className={Style.container}>
          <div className={Style.card}>
            <img src={LogoutImage} alt="Logout-icon" />
            <h2>See you Again</h2>
            <p>Thanks for using Poliant.<br />You are now successfully signed out</p>
            <Link to="/auth/login">
              <button className={Style.button}>Back to Sign in</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Logout
