import React, { useState, useEffect } from "react";
import Style from "./ClubMember.module.css";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faEllipsisVertical, faUser, faPercent } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Config from "../../../config";

import AvatarMember from "../../../Assets/img/avatar-member.png";
import PoliantCoinSmall from "../../../Assets/img/icon/poliant-coin-small.png";
import BasicMember from "../../../Assets/img/base-member.png";
// import NormalMember from "../../../Assets/img/member.png";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function showMember(randomId, randomIdSvg, randomIdSvgTwo, width, setWidth) {
  const el = document.getElementById(randomId);
  const elSvg = document.getElementById(randomIdSvg);
  const elSvgTwo = document.getElementById(randomIdSvgTwo);

  if (el.classList.contains("row_none")) {
    el.classList.remove("row_none");
    elSvgTwo.style.display = "block";
    elSvg.style.display = "none";
    setWidth(width + 40);
  } else {
    el.classList.add("row_none");
    elSvg.style.display = "block";
    elSvgTwo.style.display = "none";
    setWidth(width - 40);
  }
}

function seeMenuFolder(e) {
  var element = e.currentTarget.children[0];
  element.classList.toggle('show_folder_club');
}


const InfoRow = (props) => {
  const randomId = props.POL_ID + "_" + props.tier + "_" + makeid(7);

  return (
    <>
      <div
        style={{
          backgroundColor: "#fafafa",
          padding: "10px 0",
          display: "flex",
          width: "max-content",
        }}
      >
        <div style={{ minWidth: props.width, display: "flex" }}></div>
        <div className={Style.coins} style={{ fontWeight: 600, fontSize: 12, color: '#748194' }}>RANK</div>
        <div className={Style.personal_poll} style={{ fontWeight: 600, fontSize: 12, color: '#748194' }}>PERSONAL POOL LOCK</div>
        <div className={Style.ratio} style={{ fontWeight: 600, fontSize: 12, color: '#748194' }}>
          <span>RATIO REWARD</span>
        </div>
        <div className={Style.status} style={{ fontWeight: 600, fontSize: 12, color: '#748194' }}>STATUS</div>
        <div className={Style.member} style={{ fontWeight: 600, fontSize: 12, color: '#748194' }}>CLUB SIZE</div>
        <div className={Style.active_pools} style={{ fontWeight: 600, fontSize: 12, color: '#748194' }}>
          <span>ACTIVE POOLS</span>
        </div>
        <div className={Style.member_since} style={{ fontWeight: 600, fontSize: 12, color: '#748194' }}>
          <span>MEMBER SINCE</span>
        </div>
      </div>

      <div
        id={randomId}
        className="row_none bg-white"
        style={{ width: "max-content" }}
      ></div>
    </>
  );
};

const ClubRow = (props) => {

  const randomId = props.POL_ID + "_" + props.tier + "_" + makeid(7);
  const randomIdSvg = props.POL_ID + "_" + props.tier + "_" + makeid(9);
  const randomIdSvgTwo = props.POL_ID + "_" + props.tier + "_2_" + makeid(9);

  function openChangeReward(props) {
    if (props.contractSigned === true) {
      props.setPercentageSingle(props.perc_gain_value);
      props.setSingleId(props.id);
      props.setSingleName(props.first_name + " " + props.last_name);
      props.setSingleUsername(props.username);
      props.setSingleAvatar(props.avatar);
      props.setSinglePolIdUser(props.POL_ID);

      const layover = document.getElementById("layover");
      const changeBoxRewards = document.getElementById("change_box_rewards");
      const mainContent = document.getElementsByClassName("main_content");
      mainContent[0].classList.add('unset_scroll');
      layover.style.display = "block";
      changeBoxRewards.style.display = "block";
    } else {
      const layover = document.getElementById('layover');
      const popupGoSign = document.getElementById("popupGoSign");
      popupGoSign.style.display = "block";
      layover.style.display = 'block';

    }
  }

  return (
    <>
      <div style={{ overflowX: "scroll", borderRadius: 20 }}>
        <div className={Style.container_max} >
          {props.index === 0 && (
            <InfoRow width={props.width} setWidth={props.setWidth} {...props} />
          )}
          <div
            style={{
              height: 100,
              display: "flex",
              marginTop: 10,
              width: "max-content",
            }}
          >
            <div style={{ minWidth: props.width, display: "flex" }}>
              <div className="d-flex align-items-center w-100">
                {props.club !== null ? (
                  <div
                    className={Style.plus_border_first}
                    onClick={() =>
                      showMember(
                        randomId,
                        randomIdSvg,
                        randomIdSvgTwo,
                        props.width,
                        props.setWidth
                      )
                    }
                  >
                    {props.tier === 1 ? (
                      <div className={Style.vertical_left_initial}></div>
                    ) : (
                      <div className={Style.vertical_left}></div>
                    )}
                    <FontAwesomeIcon
                      id={randomIdSvg}
                      icon={faPlus}
                      className={Style.text_icon_plus}
                    />
                    <FontAwesomeIcon
                      style={{ display: "none" }}
                      id={randomIdSvgTwo}
                      icon={faMinus}
                      className={Style.text_icon_plus}
                    />
                  </div>
                ) : (
                  <div className={Style.plus_border_first}>
                    {props.tier === 1 ? (
                      <div className={Style.vertical_left_initial}></div>
                    ) : (
                      <div className={Style.vertical_left}></div>
                    )}
                    <div className={Style.icon_circle}>
                      <div className={Style.circle_final}></div>
                    </div>
                    {(() => {
                      let elements = [];
                      for (let i = 0; i < props.tier; i++) {
                        elements.push(
                          <div
                            className={Style.orizontal_circle}
                            style={{ transform: `translateX(${-40 * i}px)` }}
                            key={i}
                          ></div>
                        );
                      }
                      return elements;
                    })()}
                  </div>
                )}
                <div>
                  {
                    props.avatar !== null
                      ?
                      <div className={Style.avatar} style={{ backgroundImage: `url(${props.avatar})` }}></div>
                      :
                      <div className={Style.avatarPlaceholder} style={{ backgroundImage: `url(${AvatarMember})` }}></div>
                  }
                </div>
                <div className="d-flex flex-column ms-4 position-relative">
                  <span className={Style.title_member}>

                    {props.privateMode === true
                      ? '***************'
                      : (props.first_name + " " + props.last_name).length > 20
                        ? (props.first_name + " " + props.last_name).slice(0, 20) +
                        "..."
                        : props.first_name + " " + props.last_name
                    }
                    {/* MENU Da ripetere per gli altri componenti  */}
                    <div className={Style.button_more_folder + " opened_menu"} onClick={(e) => seeMenuFolder(e)} onMouseUp={(e) => e.stopPropagation()}>
                      <div className={Style.setting_popup_folder + " opened_menu"}>
                        <h2 className='mb-3'>SETTINGS</h2>
                        <p style={{ cursor: 'not-allowed' }}><FontAwesomeIcon icon={faUser} className='me-2' />Visit Profile Member</p>
                        <p onClick={(e) => openChangeReward(props, e)}><FontAwesomeIcon icon={faPercent} className='me-2' />Change Ratio Rewards</p>
                      </div>
                      <div className={Style.circle_folder + " opened_menu"}>
                        <FontAwesomeIcon className="opened_menu" icon={faEllipsisVertical} />
                      </div>
                    </div>

                  </span>

                  <span className={Style.username_member}>
                    {props.privateMode === true
                      ? '@*************'
                      : '@' + props.username
                    }
                  </span>

                  <span className={Style.id_member}>
                    {props.privateMode === true
                      ? 'ID: **********'
                      : 'ID: ' + props.POL_ID
                    }
                  </span>
                </div>
              </div>
            </div>

            <div className={Style.coins}>
              <img src={BasicMember} alt="" />
            </div>
            <div className={Style.personal_poll}>
              {props.privateMode === true
                ? '*******'
                : '*****'
              }
              <img src={PoliantCoinSmall} alt="" />
            </div>
            <div className={Style.ratio}>
              <span>
                {props.privateMode === true
                  ? '******* '
                  : '***** '
                }
                in POLI</span>
            </div>
            <div className={Style.status}>
              <span className="badge-table bg-success mb-1 mt-1 ms-0">
                {props.privateMode === true
                  ? '*******'
                  : '*****'
                }
              </span>
            </div>
            <div className={Style.member}>
              <span>
                {props.privateMode === true
                  ? '******* '
                  : props.club_size + ' '
                }
                Member</span>
            </div>
            <div className={Style.active_pools}>
              <span>
                {props.privateMode === true
                  ? '*******'
                  : '*****'
                }
              </span>
            </div>
            <div className={Style.member_since}>
              <span>2021-05-30</span>
            </div>
          </div>

          <div
            id={randomId}
            className="row_none bg-white"
            style={{ width: "max-content" }}
          >
            {props.club !== null ? (
              props.club.map((item) => {
                return (
                  <div key={item.id}>
                    <ClubRowSmall
                      width={props.width}
                      setWidth={props.setWidth}
                      privateMode={props.privateMode}
                      {...item}
                    ></ClubRowSmall>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const ClubRowSmall = (props) => {
  const randomId = props.POL_ID + "_" + props.tier + "_" + makeid(7);
  const randomIdSvg = props.POL_ID + "_" + props.tier + "_" + makeid(9);
  const randomIdSvgTwo = props.POL_ID + "_" + props.tier + "_2_" + makeid(9);

  return (
    <>
      <div className="row_small">
        <div style={{ minWidth: props.width, display: "flex" }}>
          <div
            style={props.tier ? { paddingLeft: 40 * props.tier } : null}
            className="d-flex align-items-center w-100"
          >
            {props.club !== null ? (
              <div
                className={Style.plus_border}
                onClick={() =>
                  showMember(
                    randomId,
                    randomIdSvg,
                    randomIdSvgTwo,
                    props.width,
                    props.setWidth
                  )
                }
              >
                {props.tier === 1 ? (
                  <div className={Style.vertical_left_initial}></div>
                ) : (
                  <div className={Style.vertical_left}></div>
                )}
                <div
                  className={Style.pre_line}
                  style={{ left: props.tier * 40 * -1 - 26 }}
                ></div>
                <div className={Style.orizontal}></div>

                {(() => {
                  let elements = [];
                  for (let i = 0; i < props.tier; i++) {
                    elements.push(
                      <div
                        className={Style.orizontal}
                        style={{ transform: `translateX(${-40 * i}px)` }}
                        key={i}
                      ></div>
                    );
                  }
                  return elements;
                })()}

                <FontAwesomeIcon
                  id={randomIdSvg}
                  icon={faPlus}
                  className={Style.text_icon_plus}
                />
                <FontAwesomeIcon
                  style={{ display: "none" }}
                  id={randomIdSvgTwo}
                  icon={faMinus}
                  className={Style.text_icon_plus}
                />
              </div>
            ) : (
              <div className="position-relative">
                <div className={Style.plus_border_first}>
                  {props.tier === 1 ? (
                    <div className={Style.vertical_left_initial}></div>
                  ) : (
                    <div className={Style.vertical_left}></div>
                  )}
                  <div className={Style.icon_circle}>
                    <div className={Style.circle_final}></div>
                  </div>
                  {(() => {
                    let elements = [];
                    for (let i = 0; i < props.tier; i++) {
                      elements.push(
                        <div
                          className={Style.orizontal_circle}
                          style={{ transform: `translateX(${-40 * i}px)` }}
                          key={i}
                        ></div>
                      );
                    }
                    return elements;
                  })()}
                </div>

                <div className={Style.vertical_left_last}></div>
                <div
                  className={Style.pre_line_last}
                  style={{ left: props.tier * 40 * -1 - 6 }}
                ></div>
              </div>
            )}
            <div>
              {
                props.avatar !== null
                  ?
                  <div className={Style.avatar} style={{ backgroundImage: `url(${props.avatar})` }}></div>
                  :
                  <div className={Style.avatarPlaceholder} style={{ backgroundImage: `url(${AvatarMember})` }}></div>
              }
            </div>
            <div className="d-flex flex-column ms-4">
              <span className={Style.title_member}>

                {props.privateMode === true
                  ? '***************'
                  : (props.first_name + " " + props.last_name).length > 20
                    ? (props.first_name + " " + props.last_name).slice(0, 20) +
                    "..."
                    : props.first_name + " " + props.last_name
                }
                <div className={Style.button_more_folder + " opened_menu"} onClick={(e) => seeMenuFolder(e)}>
                  <div className={Style.setting_popup_folder + " opened_menu"}>
                    <h2 className='mb-3'>SETTINGS</h2>
                    <p style={{ cursor: 'not-allowed' }}><FontAwesomeIcon icon={faUser} className='me-2' />Visit Profile Member</p>
                  </div>
                  <div className={Style.circle_folder + " opened_menu"}>
                    <FontAwesomeIcon className="opened_menu" icon={faEllipsisVertical} />
                  </div>
                </div>
              </span>

              <span className={Style.username_member}>
                {props.privateMode === true
                  ? '@*************'
                  : '@' + props.username
                }
              </span>

              <span className={Style.id_member}>
                {props.privateMode === true
                  ? 'ID: **********'
                  : 'ID: ' + props.POL_ID
                }
              </span>
            </div>
          </div>
        </div>

        <div className={Style.coins}>
          <img src={BasicMember} alt="" />
        </div>
        <div className={Style.personal_poll}>
          {props.privateMode === true
            ? '*******'
            : '*****'
          }
          <img src={PoliantCoinSmall} alt="" />
        </div>
        <div className={Style.ratio}>
          <span>
            {props.privateMode === true
              ? '******* '
              : '***** '
            }
            in POLI</span>
        </div>
        <div className={Style.status}>
          <span className="badge-table bg-success mb-1 mt-1 ms-0">
            {props.privateMode === true
              ? '*******'
              : '*****'
            }
          </span>
        </div>
        <div className={Style.member}>
          <span>
            {props.privateMode === true
              ? '******* '
              : props.club_size + ' '
            }
            Member</span>
        </div>
        <div className={Style.active_pools}>
          <span>
            {props.privateMode === true
              ? '*******'
              : '*****'
            }
          </span>
        </div>
        <div className={Style.member_since}>
          <span>2021-05-30</span>
        </div>
      </div>

      <div id={randomId} className="row_none bg-white">
        {props.club !== null ? (
          props.club.map((item) => {
            return (
              <div key={item.id}>
                <ClubRowSmallTwo
                  width={props.width}
                  setWidth={props.setWidth}
                  privateMode={props.privateMode}
                  {...item}
                ></ClubRowSmallTwo>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const ClubRowSmallTwo = (props) => {
  const randomId = props.POL_ID + "_" + props.tier + "_" + makeid(7);
  const randomIdSvg = props.POL_ID + "_" + props.tier + "_" + makeid(9);
  const randomIdSvgTwo = props.POL_ID + "_" + props.tier + "_2_" + makeid(9);

  return (
    <>
      <div className="row_small">
        <div style={{ minWidth: props.width, display: "flex" }}>
          <div
            style={props.tier ? { paddingLeft: 40 * props.tier } : null}
            className="d-flex align-items-center w-100"
          >
            {props.club !== null ? (
              <div
                className={Style.plus_border}
                onClick={() =>
                  showMember(
                    randomId,
                    randomIdSvg,
                    randomIdSvgTwo,
                    props.width,
                    props.setWidth
                  )
                }
              >
                {props.tier === 1 ? (
                  <div className={Style.vertical_left_initial}></div>
                ) : (
                  <div className={Style.vertical_left}></div>
                )}
                <div
                  className={Style.pre_line}
                  style={{ left: props.tier * 40 * -1 - 26 }}
                ></div>
                <div className={Style.orizontal}></div>
                {(() => {
                  let elements = [];
                  for (let i = 0; i < props.tier; i++) {
                    elements.push(
                      <div
                        className={Style.orizontal}
                        style={{ transform: `translateX(${-40 * i}px)` }}
                        key={i}
                      ></div>
                    );
                  }
                  return elements;
                })()}
                <FontAwesomeIcon
                  id={randomIdSvg}
                  icon={faPlus}
                  className={Style.text_icon_plus}
                />
                <FontAwesomeIcon
                  style={{ display: "none" }}
                  id={randomIdSvgTwo}
                  icon={faMinus}
                  className={Style.text_icon_plus}
                />
              </div>
            ) : (
              <div className="position-relative">
                <div className={Style.plus_border_first}>
                  {props.tier === 1 ? (
                    <div className={Style.vertical_left_initial}></div>
                  ) : (
                    <div className={Style.vertical_left}></div>
                  )}
                  <div className={Style.icon_circle}>
                    <div className={Style.circle_final}></div>
                  </div>
                  {(() => {
                    let elements = [];
                    for (let i = 0; i < props.tier; i++) {
                      elements.push(
                        <div
                          className={Style.orizontal_circle}
                          style={{ transform: `translateX(${-40 * i}px)` }}
                          key={i}
                        ></div>
                      );
                    }
                    return elements;
                  })()}
                </div>

                <div
                  className={Style.pre_line_last}
                  style={{ left: props.tier * 40 * -1 - 6 }}
                ></div>
              </div>
            )}
            <div>
              {
                props.avatar !== null
                  ?
                  <div className={Style.avatar} style={{ backgroundImage: `url(${props.avatar})` }}></div>
                  :
                  <div className={Style.avatarPlaceholder} style={{ backgroundImage: `url(${AvatarMember})` }}></div>
              }
            </div>
            <div className="d-flex flex-column ms-4">
              <span className={Style.title_member}>

                {props.privateMode === true
                  ? '***************'
                  : (props.first_name + " " + props.last_name).length > 20
                    ? (props.first_name + " " + props.last_name).slice(0, 20) +
                    "..."
                    : props.first_name + " " + props.last_name
                }
                <div className={Style.button_more_folder + " opened_menu"} onClick={(e) => seeMenuFolder(e)}>
                  <div className={Style.setting_popup_folder + " opened_menu"}>
                    <h2 className='mb-3'>SETTINGS</h2>
                    <p style={{ cursor: 'not-allowed' }}><FontAwesomeIcon icon={faUser} className='me-2' />Visit Profile Member</p>
                  </div>
                  <div className={Style.circle_folder + " opened_menu"}>
                    <FontAwesomeIcon className="opened_menu" icon={faEllipsisVertical} />
                  </div>
                </div>
              </span>

              <span className={Style.username_member}>
                {props.privateMode === true
                  ? '@*************'
                  : '@' + props.username
                }
              </span>

              <span className={Style.id_member}>
                {props.privateMode === true
                  ? 'ID: **********'
                  : 'ID: ' + props.POL_ID
                }
              </span>
            </div>
          </div>
        </div>

        <div className={Style.coins}>
          <img src={BasicMember} alt="" />
        </div>
        <div className={Style.personal_poll}>
          {props.privateMode === true
            ? '*******'
            : '*****'
          }
          <img src={PoliantCoinSmall} alt="" />
        </div>
        <div className={Style.ratio}>
          <span>
            {props.privateMode === true
              ? '******* '
              : '***** '
            }
            in POLI</span>
        </div>
        <div className={Style.status}>
          <span className="badge-table bg-success mb-1 mt-1 ms-0">
            {props.privateMode === true
              ? '*******'
              : '*****'
            }
          </span>
        </div>
        <div className={Style.member}>
          <span>
            {props.privateMode === true
              ? '******* '
              : props.club_size + ' '
            }
            Member</span>
        </div>
        <div className={Style.active_pools}>
          <span>
            {props.privateMode === true
              ? '*******'
              : '*****'
            }
          </span>
        </div>
        <div className={Style.member_since}>
          <span>2021-05-30</span>
        </div>
      </div>

      <div id={randomId} className="row_none bg-white">
        {props.club !== null ? (
          props.club.map((item) => {
            return (
              <div id={randomId} key={item.id}>
                <ClubRowSmall
                  width={props.width}
                  setWidth={props.setWidth}
                  {...item}
                ></ClubRowSmall>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const ClubMember = (props) => {

  const [width, setWidth] = useState(400);
  const [contractSigned, setContractSigned] = useState(false);

  useEffect(() => {

    const getSignatureCheck = async () => {
      const contractCheck = await axios.get(
        Config.base_url + "my-profile/header-info/",
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      setContractSigned(contractCheck.data.contract_status.Intermediary_Agreement);
    };
    getSignatureCheck();

  }, []);

  return (
    <>
      <Row className="mt-5">
        <Col md={7}>
          <h2 className={Style.title_section}>Your Club Member’s</h2>
          <small className={Style.subtitle_function}>
            Last referral: {props.lastMember}.
          </small>
        </Col>
      </Row>


      <div className={Style.list_member}>

        {props.data.map((el, index) => {
          return (
            <div key={el.id} style={{ marginTop: 25, borderRadius: 20 }}>
              <ClubRow index={index} width={width} setWidth={setWidth} {...el} privateMode={props.privateMode} setPercentageSingle={props.setPercentageSingle} setSingleId={props.setSingleId} setSingleName={props.setSingleName} setSingleUsername={props.setSingleUsername} setSingleAvatar={props.setSingleAvatar} setSinglePolIdUser={props.setSinglePolIdUser} contractSigned={contractSigned} />
            </div>
          );
        })}
      </div>

    </>
  );
};

export default ClubMember;
