import React, { useState, useEffect } from "react";

// Libraries
import { useNavigate } from "react-router-dom";
import useQuery from "@hybris-software/use-query";
import { PermissionRoute } from "@hybris-software/use-auth";

// Components
import Loader from "../Components/Loader/Loader";
import StatsClub from "../Components/YourClub/StatsClub/StatsClub";
import ClubMember from "../Components/YourClub/ClubMember/ClubMember";
import ChangeRatio from "../Components/YourClub/ChangeRatio/ChangeRatio";
import TwoFactorPopup from "../Components/MyProfile/TwoFactorPopup/TwoFactorPopup";

const YourClub = () => {
  const navigate = useNavigate();
  const [viewData, setViewData] = useState("");
  const [privateMode, setPrivateMode] = useState(false);
  const [maxPercGain, setMaxPercGain] = useState(null);
  const [percentageSingle, setPercentageSingle] = useState(null);
  const [singleId, setSingleId] = useState(null);
  const [singleName, setSingleName] = useState(null);
  const [singleUsername, setSingleUsername] = useState(null);
  const [singleAvatar, setSingleAvatar] = useState(null);
  const [singlePolIdUser, setSinglePolIdUser] = useState(null);
  const [popupState, setPopupState] = useState("");


  useEffect(() => { document.title = "Poliant | Referral"; }, []);

  const viewDataApi = useQuery({
    url: "my-profile/your-club/",
    method: "GET",
    executeImmediately: false,
    onSuccess: (data) => {
      setViewData(data.data);
      setMaxPercGain(data.data.stats.perc_gain_value)
    }
  })

  return (
    <PermissionRoute
      forLoggedUser={true}
      loader={<Loader />}
      unAuthorizedAction={() => navigate("/auth/login")}
      permissionController={(data) => {
        if (data.data.status === "ACTIVE") {
          viewDataApi.executeQuery();
          return { value: true }
        }
        else {
          return { value: false, status: data.data.status }
        }
      }}
      forbiddenAction={(data) => {
        if (data.status === "PENDING") { navigate("/auth/register-survey"); }
        if (data.status === "PENDING_SURVEYED") { navigate("/auth/register-survey-sent"); }
        if (data.status === "PENDING_EXTRA_DATA") { navigate("/auth/register-complete"); }
        if (data.status === "PENDING_TERMS") { navigate("/auth/accept-terms-and-conditions"); }
        if (data.status === "PENDING_NDA") { navigate("/auth/sign-non-disclosure-agreement"); }
        if (data.status === "DELETED") { navigate("/auth/login"); }
      }}
    >
      {viewData ? (
        <>
          <TwoFactorPopup popupState={popupState} />

          <ChangeRatio maxPercGain={maxPercGain} percentageSingle={percentageSingle} setPercentageSingle={setPercentageSingle} singleId={singleId} singleName={singleName} singleUsername={singleUsername} singleAvatar={singleAvatar} singlePolIdUser={singlePolIdUser} setPopupState={setPopupState} />
          <StatsClub {...viewData.stats} privateMode={privateMode} setPrivateMode={setPrivateMode} />
          <ClubMember data={viewData.your_club} privateMode={privateMode} lastMember={viewData.stats.last_user} setPercentageSingle={setPercentageSingle} setSingleId={setSingleId} setSingleName={setSingleName} setSingleUsername={setSingleUsername} setSingleAvatar={setSingleAvatar} setSinglePolIdUser={setSinglePolIdUser} />
        </>
      ) : (
        <Loader />
      )}
    </PermissionRoute>
  );
};

export default YourClub;
