import React from 'react'

// Components
import { Outlet } from 'react-router-dom';

// Assets
import ShapeOne from '../../Assets/img/shapes/shape1.webp';
import ShapeTwo from '../../Assets/img/shapes/shape2.webp';
import ShapeThree from '../../Assets/img/shapes/shape3.webp';
import ShapeFour from '../../Assets/img/shapes/shape4.webp';
import ShapeFive from '../../Assets/img/shapes/shape5.webp';


// Styles
import Style from './AuthLayout.module.css';

const NewAuthLayout = () => {
    return (
        <div className={Style.viewport}>
            <img className={Style.shape} src={ShapeOne} alt="" />
            <img className={Style.shape} src={ShapeTwo} alt="" />
            <img className={Style.shape} src={ShapeThree} alt="" />
            <img className={Style.shape} src={ShapeFour} alt="" />
            <img className={Style.shape} src={ShapeFive} alt="" />
            <div className={Style.viewportLayover}></div>


            <div className={Style.boxForm}>

                <div className={Style.container}>
                    <div className={Style.content}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewAuthLayout
