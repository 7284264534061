import React, { useState, useEffect } from 'react'

// Components
import { Row, Col } from 'react-bootstrap'
import Loader from '../../Loader/Loader'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

// Assets
import LockIcon from '../../../Assets/img/lockIcon.png'

// Utils
import Config from '../../../config'

// Styles
import Style from './ContractAccess.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const ContractAccess = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [authenticated, setAuthenticated] = useState(false);
    const [dataContract, setDataContract] = useState(null);
    const [readContract, setReadContract] = useState(false);
    const [signContract, setSignContract] = useState(false);
    const [contractUrl, setContractUrl] = useState(null);

    useEffect(() => {
        document.title = "Poliant | IP Licensing Agreement";

        const token = localStorage.getItem("token");
        const url = Config.base_url + "contract/ip_licensing/";
        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Token ${token}`,
            },
        };
        axios
            .get(url, config)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setDataContract(response.data)
                    setAuthenticated(true);
                    axios
                        .get(response.data.original_file, { responseType: 'blob' })
                        .then(response => {
                            return new File([response.data], `${response.data.name}.pdf`, { type: "application/pdf" })   
                        })
                        .then(file => {
                            setContractUrl(URL.createObjectURL(file));
                        })
                }
            })
            .catch((error) => {
                if (error.response.status === 500) {
                    setStep(2);
                    setAuthenticated(true);
                } else {
                    setAuthenticated(false)
                }
            });

    }, []);

    function signIp() {
        setAuthenticated(false)

        const data = {}
        const url = Config.base_url + "contract/ip_licensing/";
        const token = localStorage.getItem("token");
        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Token ${token}`,
            },
        };
        axios
            .post(url, data, config)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            })
            .catch((error) => {
                setStep(2);
                setAuthenticated(true);

            });
    }

    return (
        <>
            {authenticated
                ?
                <>
                    <div className={Style.lockBox}>
                        <img src={LockIcon} alt="" />
                        <div>
                            <h3>This page is still blocked! To unblock the page you must sign the IP Licensing Agreement.</h3>
                        </div>
                    </div>
                    {step === 1 && (
                        <div className={Style.box_sign}>

                            <h2 className={Style.sign_text}>To sign this contract correctly, you should follow these guidelines:</h2>

                            <hr />
                            <h2 className={Style.sign_text}>Document to be signed</h2>
                            <Row>
                                <Col sm={12} className={Style.column}>
                                    <div className={Style.single_file}>
                                        <div className='d-flex align-items-center'>
                                            <img src={""} alt="" />
                                            <div>
                                                <span>{dataContract.name} - Poliant</span>
                                                <span className={Style.information}>{dataContract.last_update} / {dataContract.original_file_size}</span>
                                            </div>
                                        </div>
                                        <div className={Style.download_contract}>
                                            <a href={contractUrl} download={dataContract.name + '.pdf'} target='_blank' rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faDownload} />
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            </Row >
                            <hr />

                            <h3 className={Style.sign_text}>View the entire contract to accept the Intellectual Property Rights License Agreement</h3>

                            <div className={Style.miniReaderContainer}>
                                <object data={contractUrl} type="application/pdf" className={Style.miniReaderContent} >
                                </object>
                            </div>

                            <div className='d-flex align-content-center mb-2 mt-3'>
                                <input type="checkbox" name="read_contract" id="read_contract" defaultChecked={readContract} onChange={(e) => setReadContract(e.target.checked)} />
                                <label className={Style.label_sign} htmlFor="read_contract">I declare that I have read the Intellectual Property Rights License Agreement</label>
                            </div>
                            <div className='d-flex align-content-center mb-2 mt-1'>
                                <input type="checkbox" name="sign_contract" id="sign_contract" defaultChecked={signContract} onChange={(e) => setSignContract(e.target.checked)} />
                                <label className={Style.label_sign} htmlFor="sign_contract">I declare that I wish to digitally sign the contract Intellectual Property Rights License Agreement</label>
                            </div>

                            <div className='text-center'>
                                {readContract && signContract
                                    ? <button className={Style.button} onClick={(e) => {
                                        e.currentTarget.setAttribute("disabled", "")
                                        signIp();
                                    }}>Sign Document Digitally</button>
                                    : <button disabled className={Style.button}>Sign Document Digitally</button>
                                }
                            </div>

                        </div>
                    )}

                    {step === 2 && (
                        <div className={Style.box_allow}>
                            <div className='text-center'>
                                <h1 className='mb-4'>Error!</h1>
                                <p className='mb-2'>An error occurred during the procedure, or it was interrupted. Please try again later.</p>
                                <button className={Style.sign_in_button} style={{ margin: '15px 5px 5px' }} onClick={() => window.location.reload()}>
                                    Try Again
                                </button>
                                <div>or</div>
                                <button className={Style.sign_in_button} style={{ margin: '5px' }} onClick={() => navigate("/my-profile")}>
                                    Back to Profile
                                </button>
                            </div>
                        </div>
                    )}
                </>
                :
                <section>
                    <div className={Style.center_div}>
                        <Loader />
                        <div>Loading...</div>
                    </div>
                </section>
            }
        </>
    )
}

export default ContractAccess