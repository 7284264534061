import React from 'react';
import ReactDOM from 'react-dom/client';
import Config from './config';

//LIbraires
import { ThemeProvider } from '@hybris-software/ui-kit';
import { AuthProvider } from '@hybris-software/use-auth';
import { generateApiClient, ApiProvider } from '@hybris-software/use-query';

// Icons
import {RiErrorWarningLine} from 'react-icons/ri';
// Applications
import App from './App';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './Assets/css/commons.css';
import './index.css';
import Theme from './Assets/css/Theme.module.css';

const apiClient = generateApiClient({
  baseUrl: Config.base_url,
  authorizationHeader: "Authorization",
  authorizationPrefix: "Token",
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={{
      inputField: {
        baseClassName: Theme.baseinput,
        successClassName: Theme.successInput,
        errorClassName: Theme.errorInput,
        errorIcon: <RiErrorWarningLine className={Theme.alertIcon}/>
      }
    }}>
      <AuthProvider apiClient={apiClient} authUrl="auth/user/">
        <ApiProvider apiClient={apiClient}>
          <App />
        </ApiProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);