import React from 'react'
import Style from './PopupKycReset.module.css'
import axios from 'axios'

const PopupKycReset = (props) => {

    function closePop() {
        let twoStepVerification = document.getElementById('kyc_confirm');
        let layover = document.getElementById('layover');
        twoStepVerification.style.display = 'none';
        layover.style.display = 'none';
    }

    function resetKyc() {

        var data;

        if (props.popupState.type === "PERSONAL") {
            data = {
                first_name: props.popupState.first_name,
                last_name: props.popupState.last_name,
                phone: props.popupState.phone,
                type: props.popupState.type,
                company_name: props.popupState.company_name,
                company_registration_country: props.popupState.company_registration_country,
                vat: props.popupState.vat,
                companyAddress: props.popupState.companyAddress,
                country: props.popupState.country,
                city: props.popupState.city,
                state: props.popupState.state,
                address: props.popupState.address,
                zipcode: props.popupState.zipcode,
                country_billing: props.popupState.country_billing,
                city_billing: props.popupState.city_billing,
                state_billing: props.popupState.state_billing,
                billing_address: props.popupState.billing_address,
                zipcode_billing: props.popupState.zipcode_billing,
                citizenship: props.popupState.citizenship,
                profession: props.popupState.profession,
                document_type: props.popupState.document_type,
                document_number: props.popupState.document_number,
                issued_by: props.popupState.issued_by,
                issuing_date: props.popupState.issuing_date,
                expire_date: props.popupState.expire_date,
                politically_exposed: props.popupState.politically_exposed,
            }
        } else {
            data = {
                first_name: props.popupState.first_name,
                last_name: props.popupState.last_name,
                phone: props.popupState.phone,
                type: props.popupState.type,
                company_name: props.popupState.company_name,
                company_registration_country: props.popupState.company_registration_country,
                vat: props.popupState.vat,
                company_address: props.popupState.company_address,
                country: props.popupState.country,
                city: props.popupState.city,
                state: props.popupState.state,
                zipcode: props.popupState.zipcode,
                citizenship: props.popupState.citizenship,
                profession: props.popupState.profession,
                document_type: props.popupState.document_type,
                document_number: props.popupState.document_number,
                issued_by: props.popupState.issued_by,
                issuing_date: props.popupState.issuing_date,
                expire_date: props.popupState.expire_date,
                politically_exposed: props.popupState.politically_exposed,
            }
        }

        const url = props.popupState.endpoint;
        const token = localStorage.getItem("token");
        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Token ${token}`,
            },
        };
        axios
            .post(url, data, config)
            .then((response) => {
                if (response.status === 200) {
                    if (props.popupState.method === "DELETE_ACCOUNT") {
                        localStorage.clear('')
                        window.location.reload();
                    } else {
                        // localStorage.setItem('user_status', 'PENDING_NDA');
                        window.location.reload();
                    }
                }
            })
            .catch((error) => {
                // setError(error.response.data.Error);
            });
    }

    return (
        <>
            <div id='kyc_confirm' className={Style.position_auth_popup}>
                <div className={Style.body_popup}>
                    <h2>{props.popupState.title}</h2>
                    <p>{props.popupState.subtitle}</p>

                    {/* <div className='error_input'>{error}</div>
                    <div className='error_input'>{codeResend}</div> */}

                    <div className='d-flex'>
                        <button className={Style.button_cancel} onClick={() => closePop()}>Cancel</button>
                        <button className={Style.button_confirm} onClick={() => resetKyc()}>Confirm</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PopupKycReset