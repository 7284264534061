import React, { useState, useEffect } from 'react'

// Libraries
import { useNavigate } from 'react-router-dom';
import useQuery from '@hybris-software/use-query';
import { AuthRoute } from "@hybris-software/use-auth";

// Components
import AuthLoader from '../../AuthLoader/AuthLoader';

// Images
import LogoPoliant from "../../../Assets/img/logos/poliant_logo.webp";

// Styles
import Style from './AcceptTermsAndConditions.module.css'

//! TO CHANGE
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import Button from '../../Commons/Button/Button';

const AcceptTermsAndConditions = () => {
    const navigate = useNavigate();
    const [buttonTerms, setButtonTerms] = useState(false);

    useEffect(() => { document.title = "Poliant | Terms & Conditions"; }, [])

    function handleScroll(e) {
        const bottom = e.target.scrollHeight - (e.target.scrollTop + 1500) < e.target.clientHeight;
        if (bottom) {
            setButtonTerms(true);
        }
    }

    function scrollToBottom() {
        const element = document.getElementById("terms");
        element.scrollTop = element.scrollHeight;
        setButtonTerms(true);
    }

    const agreeTermsAndConditions = useQuery({
        url: "accounts/accept-terms/",
        method: "POST",
        executeImmediately: false,
        onSuccess: () => {
            navigate("/");
        }
    })

    return (
        <AuthRoute
            forLoggedUser={true}
            loader={<AuthLoader />}
            action={() => { navigate("/") }}
        >
            <section>
                <img className={Style.logo} src={LogoPoliant} alt="Poliant logo" />

                <div className={Style.termsAndConditions}>
                    <div className={Style.container}>

                        <div>
                            <div id='terms' className={Style.card} onScroll={(e) => handleScroll(e)}>
                                <section id='terms' className={Style.terms} >
                                    <h1>Terms &amp; Condition</h1>

                                    <ul className={Style.index}>
                                        <li>
                                            <a href="#definitions">
                                                Definitions
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#generalTerms">
                                                General terms &amp; applicable law
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#accountSetup">
                                                Account setup
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#access">
                                                Access
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#accountSecurity">
                                                Account Security
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#poliantServices">
                                                Poliant services
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#restrictions">
                                                Restrictions
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#riskDisclosure">
                                                Risk disclosure
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#suspension">
                                                SUSPENSION, FREEZING, RESTRICTION, CANCELATION, DEACTIVATION OR TERMINATION
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#deathUser">
                                                DEATH OF USER
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#liquidation">
                                                LIQUIDATION OF USER
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#unclaimed">
                                                UNCLAIMED PROPERTY
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#forceMajeure">
                                                FORCE MAJEURE CLAUSE
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#limitation">
                                                LIMITATION OF LIABILITY
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#disclaimer">
                                                DISCLAIMER OF WARRANTIES
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#indemnification">
                                                INDEMNIFICATION
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#complaintsResolution">
                                                COMPLAINTS RESOLUTION
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#dispute">
                                                DISPUTE RESOLUTION PROCESS
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#classAction">
                                                CLASS ACTION WAIVER
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#privacy">
                                                PRIVACY POLICY
                                            </a>
                                        </li>
                                    </ul>

                                    <hr />

                                    <div className='mt-4'>
                                        <h4 className='mb-3'>Terms and Condition</h4>
                                        <h6 className='mb-4'>Last Updated 28 July 2022</h6>
                                        <p>This is a Terms and Condition Agreement ("Terms of Use") between User ("User", "you" or "your") and POLIANT ("we", "us" or "our"). By accessing, downloading, using or clicking the "<strong>Sign Up</strong>" button or by accessing, downloading, using or clicking on any POLIANT services, you agree that you have read, understood and accepted all of the Terms of Use stipulated in this agreement and you are legally bound by these Terms of Use of service as well as our <strong>Privacy Policy</strong> &amp; <strong>Cookie Policy</strong></p>
                                        <p>Please read these <strong>Terms of Use</strong> carefully as they govern your use of POLIANT services and it clearly explains how your account and our services work.</p>
                                        <p>Especially by making use of POLIANT services, you acknowledge and agree that;</p>
                                        <ul className={Style.letterList}>
                                            <li>You are aware of the risks associated with transactions in virtual goods and their derivatives;</li>
                                            <li>You assume all risks associated with the use of the services offered on the POLIANT website including related to transactions in virtual currencies and their derivatives offered by third parties where there is a substantial risk of losing money by buying, selling, holding or investing in virtual currencies;</li>
                                            <li>You formally agree that POLIANT will not be held liable, as it does not provide virtual asset trading services, but provides the platform, software, and website, where third parties offer their services and products under their direct responsibility and independently and untethered from POLIANT.</li>
                                            <li>You agree to release in whole and in part POLIANT from being held liable for risks or negative results related to the use of third party products and not offered directly by POLIANT.</li>
                                        </ul>
                                        <p>These Terms of Use may be made available in several languages; all versions are legally binding, but in the event of inconsistency between the English version and a translated version, the English version prevails.</p>
                                    </div>

                                    <div id='definitions' className='pt-5'>
                                        <h3 className='mb-4'>1. Definitions</h3>
                                        <p>1.1 "<strong>POLIANT</strong>" is a digital service provider, which has created an eco-system in which partners, suppliers and third parties offer their products.
                                            POLIANT operates as an online service provider, is a software house that has created an ecosystem and which everyone can use freely, and is regulated by the laws and regulations of the British Virgin Islands ("BVI"). POLIANT is connected to several markets, produces quality software covering several platforms (iOS, Windows, Mac), and supports all the features necessary to be identified as an excellent software product.
                                        </p>
                                        <p>1.2 "<strong>Virtual Products</strong>" means digital representations of digital assets that can be digitally bartered or transferred and can be used for clearing purposes through the form of barter, exchange between individuals, or clearing. Virtual assets do not include digital representations of fiat currencies, securities and other financial assets that are already covered elsewhere in the FATF Recommendations.</p>
                                        <p>1.3 "<strong>Virtual Asset Service Providers</strong>" means any natural or legal person who is not covered elsewhere under the recommendations, and as a business conduct one or more of the following activities or operations for or on behalf of another natural or legal person:</p>
                                        <ul>
                                            <li>1.3.1 Exchange between virtual assets and fiat currencies;</li>
                                            <li>1.3.2 Exchange between one or more forms of virtual assets;</li>
                                            <li>1.3.3 Safekeeping and/or administration of virtual assets or instruments enabling control over virtual assets;</li>
                                            <li>1.3.4 Participation in and provision of financial services related to an issuer’s offer and/or sale of a virtual asset.</li>
                                        </ul>
                                        <p>1.4 "<strong>Personal Data</strong>" means any information in respect of commercial transactions, which:</p>
                                        <ul>
                                            <li>1.4.1 is being processed wholly or partly by means of equipment operating automatically in response to instructions given for that purpose;</li>
                                            <li>1.4.2 is recorded with the intention that it should wholly or partly be processed by means of such equipment;</li>
                                            <li>1.4.3 is recorded as part of a relevant filing system or with the intention that it should form part of a relevant filing system,</li>
                                        </ul>
                                        <p>that relates directly or indirectly to a data subject, who is identified or identifiable from that information, or from that and other information in the possession of a data user, including any sensitive personal data and expression of opinion about the data subject;</p>
                                        <p>1.5 "<strong>Authorized person of the Entity</strong>" means any natural person appointed by the entity under a letter of authorization to <strong>(a)</strong>sign in, <strong>(b)</strong> open an account and <strong>(c)</strong> to carry out transactions in POLIANT.</p>
                                        <p>1.6 "<strong>User(s)</strong>" means any natural person who has attained the age of 18 with legal competence and/or any legal entity with legal capacity, successfully creates an account in POLIANT Website and completes the process of Know Your Client ("KYC"). Such User is considered to be the client of POLIANT</p>
                                        <p>1.7 "<strong>Services</strong>" connecting the Clients to technological tools through the use of the Platform by POLIANT providing the Collaboration Partner services that enable (i) e-banking, (ii) exchange, (iii) crypto-wallets, (iv) payment cards (both virtual and physical), (v) token staking and (vi) Marketplace in a single Platform.</p>
                                        <p>1.8 "<strong>Website</strong>" A Web site is a set of related Web pages, i.e., a hypertext structure of information documents that resides, via hosting, on a Web server, reachable by the end user through a common search engine and/or Web address through the use of Web browsers.</p>
                                        <p>1.9 "<strong>Platform</strong>" a publicly accessible internet-based information system that is branded as "POLIANT", available via the Website and/ or App and operated or managed by POLIANT Technologies Limited.</p>
                                        <p>1.10 "<strong>Eco System</strong>" In the context of the Web, the digital Ecosystem denotes a community of individuals who, through digital tools like programs and software, interact with each other, exchanging information to expand their knowledge, skills and contacts, thus seeking to better meet their needs and requirements.</p>
                                        <p>1.11 "<strong>Authorized person of the Entity</strong>"means any natural person appointed by the entity under a letter of authorization to <strong>(a)</strong>sign in, <strong>(b)</strong> open an account and <strong>(c)</strong> to carry out transactions in POLIANT</p>
                                        <p>1.12 "<strong>Letter of Authorization</strong>"means the letter provided by any legal entity which attests that the individual filling up the KYC has the legal authority to open the corporate account at POLIANT, sign in and carry out transactions on behalf of the entity. Such letters shall be signed, stamped, and dated (not less than 6-month-old). The letter shall include the company's full name and full names of UBO, who is authorizing the "Authorized person of the Entity". In the event of any changes of the Authorized person of the Entity, the entity shall report to POLIANT in writing immediately without fail.</p>
                                        <p>1.13 "<strong>Document(s)</strong>" includes:</p>
                                        <ul>
                                            <li>1.13.1 any medium in which data is recorded, whether printed, or on tape, or film or by electronic means, or otherwise;</li>
                                            <li>1.13.2 map, diagram, photograph, film, microfilm, video-tape, sound recording, or machine-readable record;</li>
                                            <li>1.13.3 any record which is capable of being produced from</li>
                                            <li>1.13.4 a machine-readable record by means of equipment or a programme, or a combination of both, or </li>
                                            <li>1.13.5 any equipment or a programme, or a combination of both, and is used for that purpose by the public body or private body which holds the record;</li>
                                        </ul>
                                        <p>1.14 "<strong>Transaction</strong>" refers to the transfer of currency between two virtual wallets. A transaction is submitted to a public ledger to await confirmation before the trade can be completed. During a transaction, an encrypted electronic signature based on a mathematical formula is required as proof of ownership. The confirmation process is conducted by people called miners or validators. <br />We would like to remind you that transactions are not offered or conducted by POLIANT but managed and offered by the individual companies in the POLIANT ecosystem.
                                        </p>
                                        <p>1.15 "<strong>Public Ledger</strong>" means, once a transaction is confirmed by a miner or validator, it is stored in a public ledger called a blockchain. The public ledger verifies ownership of the virtual asset and ensures the legitimacy of record keeping due to its immutability.<br />We would like to remind you that transactions are not offered or carried out by POLIANT but managed and offered by the individual companies in the POLIANT eco system who are the sole managers and owners of the Ledgers used.
                                        </p>
                                        <p>1.16 "<strong>Validating</strong>" means, the process of confirming transactions before they can be added to the public ledger. There are different types of validating, based on the virtual asset. The most famous is bitcoin mining, used for bitcoin, the first and best-known virtual asset. A bitcoin miner must be able to solve a computational puzzle, called proof-of-work, to prevent the exploitation of virtual asset mining. Miners receive a virtual asset commission for their work.<br />We would like to remind you that transaction validation is not offered or carried out by POLIANT but managed and offered by the individual companies in the POLIANT eco system who are the sole managers and owners of the Ledgers used and the validation systems.</p>
                                        <p>1.17 "<strong>GDPR</strong>" means the General Data Protection Regulation which is Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons regarding the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC (General Data Protection Regulation) (Text with EEA relevance), OJ L 119, 4.5.2016. If your country of residence is based within the European Economic Area ("EEA") and the GDPR applies to our processing of your personal data (within the meaning of GDPR), If your Country of Residence is outside of the EEA, provisions related to the GDPR shall not apply to your use of the Services.<br />We would like to remind you that POLIANT only takes care of customer authentication when accessing the platform and not when using third-party products or software, which are their direct data controllers.</p>
                                        <p>1.18 "<strong>Arbitration</strong>" means a process of settling a dispute between parties before an arbitral tribunal, and includes any arbitration mutually agreed by both parties.</p>
                                        <p>1.19 "<strong>Prohibited Use or Business</strong>" refers to:</p>
                                        <ul>
                                            <li>1.19.1 any unlawful activity which violates any law of BVI and International forum</li>
                                            <li>1.19.2 any abusive activity such as transmitting or uploading any material to the site that contains viruses, Trojan horses, worms, or any other harmful programs,</li>
                                            <li>1.19.3 any use of fraud activity which operates to defraud POLIANT services</li>
                                            <li>1.19.4 any use of gambling activity</li>
                                            <li>1.19.5 any activity in engagement with involving items that infringe or violate any of the copyright, trademark, right of publicity or privacy or any other proprietary right, name, logo or brand of the POLIANT account</li>
                                            <li>1.19.6 any restricted financial services,</li>
                                            <li>1.19.7 any sales, distribution or access to counterfeit software</li>
                                            <li>1.19.8 any sales or resale of brand name or designer products, or services that are illegally imported or exported or which are stolen,</li>
                                            <li>1.19.9 any business related to marijuana dispensary, sale of tobacco, e-cigarettes, e-liquid, online prescription or pharmaceutical services, age restricted goods or services, weapons and munitions, gunpowder and other explosives, fireworks, toxic, inflammable and radioactive materials</li>
                                            <li>1.19.10 any sales of narcotics, controlled substances or any equipment or tools designed for making or using drugs</li>
                                            <li>1.19.11 any business related to products that make health claims that have not been approved or verified by the applicable local or national governmental authority</li>
                                            <li>1.19.12 any sale of a legal substance that provides the same effect of an illegal drug</li>
                                            <li>1.19.13 any services or business related to pornography and other obscene materials, sites offering any services related to prostitution and sexual related services.</li>
                                            <li>1.19.14 any business which has financial risk, legal liability or violations of any regulations</li>
                                        </ul>
                                    </div>

                                    <div id='generalTerms' className='pt-5'>
                                        <h3 className='mb-4'>2. GENERAL TERMS &amp; APPLICABLE LAW</h3>
                                        <p>2.1 <strong>Legality</strong> - These Terms of Use constitute a legal agreement and creates a binding contract between you and POLIANT.</p>
                                        <p>2.2 <strong>Eligibility</strong></p>
                                        <ul>
                                            <li>2.2.1 <strong>Retail Client</strong> - the User who is a natural person who has attained the age of 18 with legal capacity and sufficient authorization to enter into these Terms. The Users who are citizens or residents of the USA, China and UAE are not eligible to use POLIANT Account due to internal policy decisions.</li>
                                            <li>2.2.2 <strong>Corporate Client</strong> - the User who is a legal entity and has sufficient authorization to enter into these Terms. The <strong>Authorized person of the Entity</strong> shall submit the Letter of Authorization in order to sign up with POLIANT. The entities which are registered in the USA, China and UAE are not eligible to use POLIANT Account due to internal policy decisions.</li>
                                        </ul>
                                        <p>2.3 <strong>Supplementary Terms</strong> - These Terms of Use between you and POLIANT do not cover all rights and obligations. Therefore, the Privacy Policy, Cookies Policy and all other agreements entered into separately between you and POLIANT are deemed to be considered as Supplementary Terms that are an integral part of these Terms of Use and shall have the same legal effect.</p>
                                        <p>2.4 <strong>Changes to the Terms of Use</strong> - POLIANT Terms of Use are reviewed regularly and updated to the applicable laws and regulations. POLIANT reserves the right to change or modify these Terms of Use at its discretion at any time and it will notify the updated version at the top of this page. When an updated version of the Terms of Use is released, and if the User continues access to POLIANT means that the User agrees and consents to the Updated Content of the Terms of Use.</p>
                                        <p><strong>THEREFOR, YOU ARE RECOMMENDED TO FREQUENTLY REVIEW THESE TERMS OF USE TO ENSURE YOUR UNDERSTANDING OF THE TERMS OF USE THAT APPLY TO YOUR ACCESS TO AND USE OF POLIANT SERVICES.</strong></p>
                                        <p>2.5 <strong>User Restrictions</strong> - POLIANT reserves the right to choose markets and jurisdictions to conduct business and shall restrict or refuse some Users due to Internal policy decisions and shall restrict or refuse Users who are included in any Anti-money Laundering High risk Jurisdictions as per FATF and Basel recommendations or any economic sanctions lists, such as United Nations Security Council Sanctions List, and The Office of Foreign Assets Control of the US Department of the Treasury.</p>
                                        <p>2.6 These Terms are governed by and shall be construed in accordance with the laws of British Virgin Islands.</p>
                                    </div>

                                    <div id='accountSetup' className='pt-5'>
                                        <h3 className='mb-4'>3. ACCOUNT SETUP</h3>
                                        <p>3.1 <strong>Registration of POLIANT Account:</strong> In order to sign up a POLIANT account, you must register in the POLIANT services. By using the POLIANT account, you agree and represent that you will use the POLIANT account for yourself, and not on behalf of any third party, unless you have obtained prior legal approval from POLIANT. When you register in POLIANT, you shall undergo a certain onboarding process to ensure our internal verification and you must provide a strong password, and accept these Terms of Use, Privacy Policy and other POLIANT Rules.</p>
                                        <p>3.2 <strong>Identity Verification:</strong> POLIANT has implemented a fully complaint and advanced User onboarding and AML Screening system ("KYC System") that is designed to facilitated the process of User registration and identity verification in order to assess, manage and mitigate any money laundering or terrorist financing risks associated with the User. In order to conduct the Identity verification POLIANT may insist you to complete the following KYC process:</p>
                                        <ul>
                                            <li>3.2.1 Account Creation &amp; Registration</li>
                                            <li>3.2.2 Sanction &amp; PEP Screening</li>
                                            <li>3.2.3 Document Verification</li>
                                            <li>3.2.4 Identity Verification</li>
                                            <li>3.2.5 Liveness check and Face Match</li>
                                            <li>3.2.6 Known Face Search</li>
                                            <li>3.2.7 Video Calls and Interviews (as necessary)</li>
                                            <li>3.2.8 Email, Phone and Address verification</li>
                                        </ul>
                                        <p>In providing us with the above-mentioned information, you confirm that the information is accurate and authentic. Further you agree to keep us updated if any of the information you provide changes. If there are any grounds for believing that any of the information you provided is incorrect, false, outdated or incomplete, POLIANT reserves the right to send you a letter of demand for corrections, suspend, freeze or terminate all/part of the POLIANT services we provide you.</p>
                                        <p><strong>If POLIANT is unable to reach you with the contact information you provided, you shall be fully liable for any loss or expense caused to POLIANT during your use of POLIANT services.</strong></p>
                                        <p>3.3 POLIANT notifies that by accepting the Terms of Use, you authorize us to make inquiries, whether directly or through third parties, that we may consider necessary to verify your identity or to protect you and/or us against fraud or any other financial crime, and to take action we reasonably deem necessary based on the results of such inquiries. Further, you expressly acknowledge and agree that your personal information may be disclosed to credit reference and fraud prevention or financial crime institutions or authorities and these institutions or authorities may respond to our inquiries in full.</p>
                                    </div>

                                    <div id='access' className='pt-5'>
                                        <h3 className='mb-4'>4. ACCESS</h3>
                                        <p>The POLIANT Services can be accessed directly using the <a href="http://platform.poliant.com/" className='text-primary'>www.platform.poliant.com</a> site and POLIANT mobile application. Access to POLIANT services may become degraded or unavailable and may also lead to support response time delays. POLIANT shall not be liable for any losses for the users.</p>
                                    </div>

                                    <div id='accountSecurity' className='pt-5'>
                                        <h3 className='mb-4'>5. Account Security</h3>
                                        <p>5.1 POLIANT has been committed to maintain the security of User entrusted data, and has implemented industry standard protection.</p>
                                        <p>5.2 You shall agree to treat your access credentials (Username &amp; Password) as confidential information, and not to disclose such information to any third party.</p>
                                        <p>5.3 You also agree to be solely responsible for taking the necessary security measures to protect your account and personal information.</p>
                                        <p>5.4 In order to circumvent loss, misuse, unauthorized acquisition or alteration of your data, Users are requested to choose the strongest password and to take appropriate steps to logout from POLIANT at the end of each visit.</p>
                                        <p>5.5 In the event of any unauthorized access, the POLIANT insists the User to notify the customer support immediately.</p>
                                        <p>5.6 POLIANT will never under any circumstances ask you for Username, IDs, Passwords, any authentication codes, and we assume no responsibility for any loss that you may sustain due to compromise of account login credentials due to no fault of POLIANT and/or failure or act on any notices or alerts that we may send you.</p>
                                    </div>

                                    <div id='poliantServices'>
                                        <h3 className='mb-4'>6. POLIANT SERVICES</h3>
                                        <p>6.1 <strong>Services provided by POLIANT: </strong>Services provided by POLIANT: POLIANT's services ("Services") refer to various services provided to you in your Virtual Area.<br />Activities not operated by POLIANT such as cryptocurrency trading and exchange services, virtual asset and currency transactions are offered through service providers using the POLIANT ecosystem, therefore are not to be considered services offered by POLIANT</p>
                                        <p>6.2 <strong>License: </strong>POLIANT permits you a revocable, limited, non-transferable and non-sublicensable license to access and use Services through any compatible devices for your personal purposes. You are prohibited to use our services for resale or commercial purposes, including transactions on behalf of other persons or entities without Legal authorization from POLIANT.</p>
                                        <p>6.3 These Terms of Use grant limited license to access and use Services and hereby you agree that all the text, graphics, user interfaces, visual interface, photos, sounds, process flow diagrams, computer code, programs, software, products, information and documents, design, structure, layout of any content included in our Services are exclusively owned, controlled and/or licensed by only by POLIANT licensors or affiliates.</p>
                                    </div>

                                    <div id='restrictions' className='pt-5'>
                                        <h3 className='mb-4'>7. RESTRICTIONS</h3>
                                        <p>7.1 In the event of attempt to access any part of function of the properties without authorization or any networks of POLIANT services, violate any security or authentication measures or impersonate or otherwise manipulate identification, to disguise your identity or the origin of any messages or transmissions you sent to POLIANT or using our Services in any illegal manner, and creating a substantial doubt of any suspicious activity or suspicious transfer of virtual currency, the POLIANT shall take the below mentioned actions under relevant regulations without your consent or prior notice;</p>
                                        <ul>
                                            <li>7.1.1 Suspend the account temporarily</li>
                                            <li>7.1.2 Freezing your account</li>
                                            <li>7.1.3 Reporting the incident to the relevant authorities</li>
                                            <li>7.1.4 Deleting any information, you published that are found to be violations</li>
                                            <li>7.1.5 Terminate your account</li>
                                        </ul>
                                    </div>

                                    <div id='riskDisclosure' className='pt-5'>
                                        <h3 className='mb-4'>8. RISK DISCLOSURE</h3>
                                        <p>8.1 By accessing or using our Platform, you voluntarily choose to engage in the use of products on the POLIANT marketplace even if they turn out to be sophisticated and risky activities. You also acknowledge that you are aware of the inherent risks associated with the use of cryptographic and blockchain-based systems, including, but not limited to, risks of financial loss, technological malfunction, and hacking. We work hard to provide state-of-the-art systems and security. Nonetheless, some issues and risks are unavoidable, and if such issues or problems arise in connection with the use of our Platform, please remember that cryptocurrency depositing or trading activities are not handled by POLIANT but by the vendor chosen by the customer\user, and some issues may take days, weeks or months to resolve, and some issues may not be resolved at all, this of course will not depend on POLIANT but on the manufacturer of the product\supplier chosen by the Customer\User</p>
                                        <p>8.2 Although POLIANT does not offer services related to the cryptocurrency world, but some of the users of the POLIANT ecosystem certainly do, you further understand that the markets for digital assets are highly volatile due to factors including but not limited to, adoption, speculation, technology, security, and regulation. You acknowledge and accept that the cost and speed of transacting with cryptographic and blockchain-based systems are variable and may increase dramatically at any time. You further acknowledge and accept the risk that your digital assets may lose some or all of their value while they are supplied and you may suffer loss due to the fluctuation of prices of tokens in a trading pair or liquidity pool, and, especially in expert modes, experience significant price slippage and cost. You understand that anyone can create a token, including fake versions of existing tokens and tokens that falsely claim to represent projects, and acknowledge and accept the risk that you may mistakenly trade those or other tokens. You further acknowledge that we are not responsible for any of these variables or risks, and cannot be held liable for any resulting losses that you experience while accessing or using the Platform. Accordingly, you understand and agree to assume full responsibility for all of the risks of accessing and using the Platform.</p>
                                    </div>

                                    <div id='suspension' className='pt-5'>
                                        <h3 className='mb-4'>9. SUSPENSION, FREEZING, RESTRICTION, CANCELATION, DEACTIVATION OR TERMINATION</h3>
                                        <p>9.1 POLIANT may suspend, freeze, restrict, cancel, deactivate or terminate your access to any or all of the POLIANT services, If;</p>
                                        <p>9.2 we are required by any arbitration, court order or any binding order of a government authority;</p>
                                        <p>9.3 we reasonably suspect you to use your POLIANT account involved in connection with a <strong>Prohibited Use or Business</strong>;</p>
                                        <p>9.4 we reasonably suspect that any suspicious activity or suspicious transaction took place in your account;</p>
                                        <p>9.5 we find you to be violating or breaching any of the articles in the Terms of Use, Privacy Policy or any rules related to POLIANT:</p>
                                        <ul>
                                            <li>9.5.1 In the event of any suspension, freezing, restriction, cancellation, deactivation or termination of your POLIANT account and any POLIANT services, we will provide you with notice of our actions unless a court order or other legal process prohibits POLIANT from providing you with such notices.</li>
                                            <li>9.5.2 You acknowledge that POLIANT shall take decisions in regard to any suspension, freezing, restriction, cancellation, deactivation or termination and these may be based on any confidential criteria that are essential to POLIANT’s Risk Management and Security Protocols. Along with that, you further agree POLIANT is under no obligation to disclose the details of its risk management and security procedures to you.</li>
                                            <li>9.5.3 You shall cancel your POLIANT account at any time by selling or transferring your products and visiting our cancellation option. By canceling your POLIANT account, you authorize POLIANT to cancel or suspend any pending transactions at the time of cancellation. You will not be charged for canceling your POLIANT account, however you will be required to pay any outstanding amounts owed to POLIANT.</li>
                                        </ul>
                                    </div>

                                    <div id='deathUser' className='pt-5'>
                                        <h3 className='mb-4'>10. DEATH OF USER</h3>
                                        <p>In the event of a death of User, who is a natural legal person:</p>
                                        <p>10.1 by receiving a legal document confirming your death or any information leading us to believe that you have died, we will freeze your POLIANT Account and no transactions shall be permitted.</p>
                                        <p>10.2 Upon receiving the legal document such as Death Certificate, Probate document, or letter of administration or a valid Will or Similar testamentary document of the deceased’s account, POLIANT shall grant access to the designated fiduciary.</p>
                                        <p>10.3 If we determine, in our sole and absolute discretion, that there is uncertainty regarding the validity of the fiduciary designation, we reserve the right to require an order resolving such issue from a court of competent jurisdiction before taking any action relating to your POLIANT Account.</p>
                                    </div>

                                    <div id='liquidation' className='pt-5'>
                                        <h3 className='mb-4'>11. LIQUIDATION OF USER</h3>
                                        <p>In the event of liquidation of a Corporate Client which is a legal entity:</p>
                                        <p>11.1 Legal entity may upon any <strong>(a)</strong> voluntary or involuntary liquidation, <strong>(b) </strong>bankruptcy, or <strong>(c)</strong> liquidation by any court order or authority, shall request to claim the asset owned in the POLIANT account.</p>
                                        <p>11.2 In such circumstances, the Authorized person of the Entity shall have the right to claim such Virtual Products as mentioned in Clause 8 (8.1 and 8.2).</p>
                                        <p>11.3 Upon receiving the legal document related to the liquidation of the entity, submitted by the Authorized person of the Entity, the Corporate Client shall receive the right to sell or transfer the Virtual Products. By agreeing this term, you acknowledge that you are aware that POLIANT does not provide withdrawal of Virtual Products.</p>
                                    </div>

                                    <div id='unclaimed' className='pt-5'>
                                        <h3 className='mb-4'>12. UNCLAIMED PROPERTY</h3>
                                        <p>If POLIANT is holding Products (Virtual Area) in your Account and is unable to contact you and has no any record of your use of Services for several years, POLIANT may report these Products in your account as "<strong>Unclaimed Property</strong>" to the applicable authority in accordance to the relevant laws of BVI.</p>
                                    </div>

                                    <div id='forceMajeure' className='pt-5'>
                                        <h3 className='mb-4'>13. FORCE MAJEURE CLAUSE</h3>
                                        <p>In no event the POLIANT shall be liable for any inaccuracy, error, delay or omission, failure in performances or interruption of services of any transmission or delivery of information as in required by these Terms of Use or caused by, directly or indirectly, forces beyond its reasonable control of POLIANT including but not limited to any act of God, action of government, act of terrorists, war, fire, interruptions in telecommunications or internet services or network provider services, software malfunctions, strikes, work stoppages, accidents, civil or military disturbances or other catastrophe or any other causes beyond POLIANTS reasonable control.</p>
                                    </div>

                                    <div id='limitation' className='pt-5'>
                                        <h3 className='mb-4'>14. LIMITATION OF LIABILITY</h3>
                                        <p>In no event shall POLIANT, its affiliates and service providers or any of the respective officers, shareholders, members, directors, officers, employees, attorneys, agents, representatives, suppliers, joint ventures or contractors, be liable for any special, incidental, indirect, intangible, punitive, consequential damages or any lost profits, diminution in value, any loss, damage, breach of data arising out of Services , any performance or non-performance of Services or other product provided by or on behalf of POLIANT and its affiliates, whether under tort, contract, statute, strict liability, negligence or otherwise arising out of or in connection with authorized or unauthorized use of POLIANT website or Services or contract even if an authorized representative of POLIANT has been advised of or knew or should have known of the possibility of such damages, and notwithstanding the failure of any agreed or other remedy or compensation of its essential purpose, except to the extent of a final judicial determination that such damages were result of POLIANT’S gross negligence, fraud, willful misconduct or intentional violation of law. Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation may not apply to you.</p>
                                    </div>

                                    <div id='disclaimer' className='pt-5'>
                                        <h3 className='mb-4'>15. DISCLAIMER OF WARRANTIES</h3>
                                        <p>To the maximum extent permitted under applicable law, Services, POLIANT materials and any product, service or other item provided by or on behalf of POLIANT are offered on an "<strong>as is</strong>" and "<strong>as available</strong>" basis, without any representation or warranty, whether POLIANT expressly, impliedly or statutorily disclaims, and you waive, any and all other warranties of any kind, whether express or implied, including, without limitation, warranties of merchantability, fitness for a particular purpose, title or non-infringement or warranties arising from course of performance, course of dealing or usage in trade. Without limiting the foregoing, POLIANT does not represent or warrant that the site, POLIANT services or POLIANT materials are accurate, complete, reliable, current, error-free, or free of viruses or other harmful components. Further POLIANT does not guarantee that any order will be executed, accepted, recorded or remained open. Except for the express statements, agreements and rules set forth in these terms. You hereby, acknowledge that information you store or transfer through Services may become irretrievably lost or corrupted or temporarily unavailable due to a variety of causes, including software failures, protocol changes by third party providers, internet outages, force majeure event or other disasters including third party distributed denial of services (DDoS) attacks, scheduled or unscheduled maintenance, or other causes either within or outside our control. You are solely responsible for backing up and maintaining duplicate copies of any information you store or transfer through our services.</p>
                                    </div>

                                    <div id='indemnification' className='pt-5'>
                                        <h3 className='mb-4'>16. INDEMNIFICATION</h3>
                                        <p>You agree and acknowledge to indemnify and hold POLIANT, its affiliates, service providers, directors, agents, respective officers, employees, joint venturers, representatives, contractors, licensors from any and all claims, demands, loss, proceedings, suits or damages, cost or expenses including attorney’s fees, any fines, fees or penalties imposed by any regulatory authority arising out of or related to any violation or breach of this agreement or any applicable law, regulations or rights of any third party. In such circumstances, POLIANT reserves the right in sole discretion to control any action or proceedings in the manner it wishes to settle it.</p>
                                    </div>

                                    <div id='complaintsResolution' className='pt-5'>
                                        <h3 className='mb-4'>17. COMPLAINTS RESOLUTION</h3>
                                        <p>POLIANT has adopted this Complaints procedure in order to ensure a fair and quick process for handling all complaints that may arise.</p>
                                        <p>17.1 If you have any complaints or other inquiries, your first step is to contact us via email to <a href="mailto:info@poliant.com">info@poliant.com</a></p>
                                        <p>17.2 Your complaint should include the following details:</p>
                                        <ul>
                                            <li>17.2.1 Full Name</li>
                                            <li>17.2.2 Registered Email</li>
                                            <li>17.2.3 ID Account</li>
                                            <li>17.2.4 A summary of your compliant and the disputed amount</li>
                                        </ul>
                                        <p>17.3 We at POLIANT will acknowledge receipt of your compliant within Eight (8) working days from the receipt of your compliant</p>
                                        <p>17.4 In any exceptional circumstances, if the representative is unable to respond your complaint within 8 working days beyond the POLIANT’s Control, the representative will send you a communication indicating the reasons for any delay in answering your Complaint, and specifying the deadline by which the representative will respond to your Complaint, which will be no later than 14 working days from our receipt of your complaint.</p>
                                        <p>17.5 We shall make every effort to investigate your complaint and provide you with outcome of our investigation within one (1) month from the date you submitted your complaint to us.</p>
                                        <p>17.6 In the event if your complaint requires further investigation and we cannot resolve it within one (1) month, we will issue a holding response in writing or another durable medium. When a holding response is sent, it will indicate the causes of the delay.</p>
                                        <p>17.7 In any event, we shall provide you with the outcome of our investigation no later than two (2) months from the issuing of the holding response, depending on the complexity of the case and your cooperation.</p>
                                        <p>17.8 In the event, when we reach any outcome, we will inform you of it together with an explanation of our position and any remedy measure if applicable.</p>
                                        <p>17.9 Please note that POLIANT shall consider your complaint as closed and cease the relevant investigation in case you fail to respond to our officers within the period of one (1) month from the date of the submission of your complaint.</p>
                                        <p>IN ANY CIRCUMSTANCES, FAILURE TO FOLLOW THE ABOVE-MENTIONED COMPLAINTS RESOLUTION PROCESS OF THESE TERMS, BEFORE FILING AN ARBITRATION CLAIM OR SUIT IN ANY LOCAL SMALL CLAIMS COURT OR INSTITUTION, WE SHALL HAVE THE RIGHT TO ASK THE ARBITRATOR TO DISMISS YOUR FILING OF COMPLIANT, UNLESS YOU COMPLETE THE INTERNAL <strong>COMPLAINTS RESOLUTION</strong> PROCESS.
                                        </p>
                                    </div>

                                    <div id='dispute' className='pt-5'>
                                        <h3 className='mb-4'>18. DISPUTE RESOLUTION PROCESS</h3>
                                        <p>18.1 In the event, if you are not satisfied with the Complaint Resolution Process made, you shall have the right to refer your complaint to the Arbitration process.</p>
                                        <p>18.2 <strong>London Courts of International Arbitration (LCIA)</strong> shall be the Arbitrator and shall have the exclusive power, including limitation of any objections with respect to the existence, scope or validity of the Agreement to Arbitrate. Any dispute, controversy or claim arising out of or relating to this contract, or the breach, termination or invalidity thereof, shall be settled by LCIA.</p>
                                        <p>18.3 Institution and applicable procedure rule will be the LCIA Arbitration Rules(<a className='text-primary' href="https://www.lcia.org/Dispute_Resolution_Services/lcia-arbitration-rules-2020.aspx">https://www.lcia.org/Dispute_Resolution_Services/lcia-arbitration-rules-2020.aspx</a>) which came into effect from 1st October 2020. Where any agreement, submission or reference howsoever made or evidenced in writing (whether signed or not) provides in whatsoever manner for arbitration under the rules of or by the LCIA, between you and POLIANT, be in writing and shall be conducted in accordance with the LCIA Rules or such amended rules as the LCIA may have adopted hereafter to take effect before the commencement of the arbitration and that such LCIA Rules form part of this Terms of Use.</p>
                                        <p>18.4 The arbitration shall be conducted by a sole, neutral arbitrator and shall take place in BVI or LCIA London or another mutually agreeable location by both parties, in English Language.</p>
                                        <p>18.5 The arbitrator may award any relief that a court of competent jurisdiction could award and the arbitral decision may be enforced in the BVI courts. An arbitrator’s decision and judgment thereon will not have a precedential or collateral estoppel effect.</p>
                                        <p>18.6 In the event the Complaint Resolution and our proposed Arbitration provided fails, according to the Consumer Protection Act 2020 of the BVI, the User shall have the right to choose the settlement which is more appropriate and shall either accept our proposed arbitration and/or to commence an action, the User may choose a Court or the Tribunal of the BVI.</p>
                                    </div>

                                    <div id='classAction' className='pt-5'>
                                        <h3 className='mb-4'>19. CLASS ACTION WAIVER</h3>
                                        <p>19.1 You and POLIANT agree that, to the extent permissible by Law, all claims must be brought in a party’s individual capacity, and not on a class, representative or collective basis, and the parties hereby agree to waive all rights to have any dispute be brought, heard, administered, resolved or arbitrated on a class, collective, or representative basis.</p>
                                        <p>19.2 Disputes of more than one User cannot be arbitrated or consolidated with those of any other User. Combining or consolidating individual arbitrations into a single arbitration is not permitted without the consent of all parties, including POLIANT. Further, you acknowledge that, by agreeing to these terms, you and POLIANT are each waiving the right to a trial by jury and the right to participate in a class action.</p>
                                    </div>

                                    <div id='privacy' className='pt-5'>
                                        <h3 className='mb-4'>20. PRIVACY POLICY</h3>
                                        <p>We care about your data protection, its security and confidentiality. We at POLIANT reserves the right to collect, process, use or disclose your <strong>Personal data</strong> in accordance with the Terms of Use including Privacy Policy. Please visit our <strong>Privacy Policy</strong> to understand how we collect, store, share and protect your personal data.</p>
                                    </div>

                                </section>
                            </div>

                            <div className={Style.footerBox}>
                                <div className={Style.goDown}>
                                    <div>
                                        Click here
                                        <span className={Style.scollIcon}><FontAwesomeIcon icon={faArrowDown} onClick={() => scrollToBottom()} /></span>
                                    </div>
                                    <div>
                                        or scroll down to enable the "I Agree" button
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        className={Style.buttonTerms}
                                        isLoading={agreeTermsAndConditions.isLoading}
                                        disabled={!buttonTerms}
                                        onClick={() => agreeTermsAndConditions.executeQuery({
                                            accepted_terms: true,
                                        })}
                                    >
                                        I Agree
                                    </Button>
                                    <button className={Style.buttonTerms} onClick={() => navigate("/auth/logout")}>Log Out</button>
                                </div>

                                {/*Aggiungere errore chiamata  agreeTermsAndConditions.isError*/}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </AuthRoute>
    )
}

export default AcceptTermsAndConditions