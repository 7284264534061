import React from 'react';

// Libraries
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Layouts
import MainLayout from './Layouts/MainLayout';
import AuthLayout from './Layouts/AuthLayout/AuthLayout';

// Components
import Kyc from './Components/Kyc/Kyc';
import Login from './Components/Auth/Login/Login';
import Logout from './Components/Auth/Logout/Logout';
import Page404 from './Components/ServicePage/Page404';
import Page403 from './Components/ServicePage/Page403';
import Page502 from './Components/ServicePage/Page502';
import SignNda from './Components/Legal/SignNda/SignNda';
import ServicePageLayout from './Layouts/ServicePageLayout';
import SurveySent from './Components/Auth/SurveySent/SurveySent';
import Registration from './Components/Auth/Registration/Registration';
// import SingleUser from './Components/YourClub/SingleUser/SingleUser';
import ResetPassword from './Components/Auth/ResetPassword/ResetPassword';
import ForgotPassword from './Components/Auth/ForgotPassword/ForgotPassword';
import SignIntermediary from './Components/Legal/SignIntermediary/SignIntermediary';
import TermsAndCondition from './Components/Legal/TermsAndCondition/TermsAndCondition';
import RegistrationSurvey from './Components/Auth/RegistrationSurvey/RegistrationSurvey';
import CompanyRegistration from './Components/Auth/CompanyRegistration/CompanyRegistration';
import CompleteRegistration from './Components/Auth/CompleteRegistration/CompleteRegistration';
import AcceptTermsAndConditions from './Components/Legal/AcceptTermsAndConditions/AcceptTermsAndConditions';

// Views
import AddUser from './Views/AddUser';
import Referral from './Views/Referral';
import YourClub from './Views/YourClub';
import Contract from './Views/Contract';
import MyProfile from './Views/MyProfile';

const App = () => {
  return (
    <BrowserRouter>
    <Routes>

      <Route path='/auth' element={<AuthLayout />}>
        <Route path='kyc' element={<Kyc />} />
        <Route path='login' element={<Login />} />
        <Route path='logout' element={<Logout />} />
        <Route path='register' element={<Registration />} />
        <Route path='sign-non-disclosure-agreement' element={<SignNda />} />
        <Route path='reset-password' element={<ResetPassword />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='register-survey-sent' element={<SurveySent />} />
        <Route path='register-survey' element={<RegistrationSurvey />} />
        <Route path='register-company' element={<CompanyRegistration />} />
        <Route path='register-complete' element={<CompleteRegistration />} />
        <Route path='sign-intermediary' element={<SignIntermediary />} />
        <Route path='accept-terms-and-conditions' element={<AcceptTermsAndConditions />} />
      </Route>

      <Route path='/' element={<MainLayout />}>
        <Route path='' element={<MyProfile />} />
        <Route path='contract' element={<Contract />} />
        <Route path='referral' element={<Referral />} />
        <Route path='your-club' element={<YourClub />} />
        <Route path='add-new-user' element={<AddUser />} />
        <Route path='my-profile' element={<MyProfile />} />
        <Route path='terms-and-condition' element={<TermsAndCondition />} />
        {/* <Route path='member' element={<SingleUser />} /> */}
      </Route>

      <Route path='/service-page' element={<ServicePageLayout />}>
        <Route path='403' element={<Page403 />} />
        <Route path='404' element={<Page404 />} />
        <Route path='502' element={<Page502 />} />
      </Route>

    </Routes>
  </BrowserRouter>
  )
}

export default App