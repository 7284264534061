import React, { useState, useEffect } from "react";
import Style from "./Kyc.module.css";
import axios from "axios";
import Config from "../../config";

import SumsubWebSdk from '@sumsub/websdk-react'

const Kyc = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codeExist = urlParams.get("access");

    const [postApplicantIdStatus, setPostApplicantIdStatus] = useState(false);

    const applicantEmail = "";
    const applicantPhone = "";

    function applicantID(applicant) {
        const data = {
            applicant_id: applicant,
        }
        const url = Config.base_url + "accounts/applicant-id/";
        const token = localStorage.getItem("token");
        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Token ${token}`,
            },
        };
        axios
            .post(url, data, config)
            .then((response) => {

            })
            .catch((error) => {

            });
    }

    useEffect(() => {
        document.title = "Poliant | Kyc";
    }, []);


    return (
        <>
            {codeExist && (
                <>
                    <div id="kyc_popup" className={Style.position_kyc}>
                        <div className={Style.center_popup}>
                            <SumsubWebSdk
                                accessToken={codeExist}
                                // updateAccessToken={() => console.log("updateAccessToken")}
                                expirationHandler={() => Promise.resolve(codeExist)}
                                config={{
                                    lang: "en-EN",
                                    email: applicantEmail,
                                    phone: applicantPhone,
                                    i18n: {
                                        document: {
                                            subTitles: {
                                                IDENTITY: "Upload a document that proves your identity"
                                            }
                                        }
                                    },
                                    onMessage: (type, payload) => {
                                        // console.log("WebSDK onMessage", type, payload);
                                    },
                                    uiConf: {
                                        customCssStr: '../../../Assets/css/commons.css',
                                    },
                                    onError: (error) => {
                                        console.error("WebSDK onError", error);
                                    }
                                }}
                                options={{ addViewportTag: false, adaptIframeHeight: true }}
                                onMessage={(type, payload) => {
                                    if (payload.applicantId !== undefined && postApplicantIdStatus === false) {
                                        applicantID(payload.applicantId);
                                        setPostApplicantIdStatus(true);
                                    }
                                    // console.log(payload.reviewStatus);
                                    // console.log(payload);
                                }}
                                onApplicantLoaded={(applicant) => {
                                    // console.log('ONAPPLICANT');

                                    // console.log("onApplicantLoaded", applicant);
                                }}

                            // onError={(data) => console.log("onError", data)}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Kyc