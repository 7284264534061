import React, { useEffect } from "react";

// Libraries
import { AuthRoute } from "@hybris-software/use-auth"

// Components
import AuthLoader from "../../AuthLoader/AuthLoader";

// Images
import LogoPoliant from "../../../Assets/img/logos/poliant_logo.webp";

// Styles
import Style from "./SurveySent.module.css";



import LogoutImage from "../../../Assets/img/logout.png";
import { useNavigate, Link } from "react-router-dom";

const SurveySent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Poliant | Survey Sent";
  }, []);

  return (
    <AuthRoute
      forLoggedUser={true}
      loader={<AuthLoader />}
      action={() => { navigate("/") }}
    >
      <section>
        <img className={Style.logo} src={LogoPoliant} alt="Poliant logo" />

        <div className={Style.surveySent}>
          <div className={Style.container}>
            {/* Card */}
            <div className={Style.card}>
              {/* Icon and reminder */}
              <div className="text-center">
                <img
                  className={Style.img_max}
                  src={LogoutImage}
                  alt="Logout-icon"
                />
                <h2>Application sent successfully</h2>
                <p className="mb-3">
                  Thanks for using Poliant.
                  <br />
                  Thank you for choosing Poliant. The DAO will review your
                  application and you will receive status updates by e-mail.
                </p>

                {/* Return to sign in page */}
                {/* TO DO */}
                <Link className="text-white" to="/auth/login">
                  <button
                    className={Style.button}
                    onClick={() => localStorage.clear("")}
                  >
                    Back to Website
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AuthRoute>
  );
};

export default SurveySent;
