import React, { useState, useEffect } from 'react'

// Libraries
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useQuery from '@hybris-software/use-query';
import { AuthRoute } from "@hybris-software/use-auth";

// Components
import Button from '../../Commons/Button/Button';
import AuthLoader from '../../AuthLoader/AuthLoader';

// Images
import LogoPoliant from "../../../Assets/img/logos/poliant_logo.webp";

// Utils
import axios from 'axios';

// Styles
import Style from './SignNda.module.css'

//! TO CHANGE
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const SignNda = () => {
    const navigate = useNavigate();
    return (
        <AuthRoute
            forLoggedUser={true}
            loader={<AuthLoader />}
            action={() => { navigate("/") }}
        >
            <SignNdaContent />
        </AuthRoute>
    )
}

const SignNdaContent = () => {
    const navigate = useNavigate();
    const [stepNda, setStepNda] = useState(1);
    const [dataContract, setDataContract] = useState(null);
    const [contractUrl, setContractUrl] = useState(null);
    const [readContract, setReadContract] = useState(false);
    const [signContract, setSignContract] = useState(false);

    //+ Call to get All DATA CONTRACT
    const getNdaContract = useQuery({
        url: "contract/nda/",
        method: "GET",
        executeImmediately: true,
        onSuccess: (response) => {
            setDataContract(response.data)
            axios
                .get(response.data.original_file, { responseType: 'blob' })
                .then(response => {
                    return new File([response.data], `${response.data.name}.pdf`, { type: "application/pdf" })
                })
                .then(file => {
                    setContractUrl(URL.createObjectURL(file));
                })
        },
        onError: (error) => {
            stepNda(2);
        }
    })

    //+ Call to Sign NDA 
    const signNda = useQuery({
        url: "contract/nda/",
        method: "POST",
        executeImmediately: false,
        onSuccess: (response) => {
            navigate("/my-profile");
        },
        onError: (error) => {
            setStepNda(2);
        }
    })

    useEffect(() => { document.title = "Poliant | Terms & NDA"; }, [])

    return (
        <>
            {getNdaContract.isSuccess
                ?
                <section>
                    <img className={Style.logo} src={LogoPoliant} alt="Poliant logo" />
                    <div className={Style.terms}>
                        <div className={Style.container}>
                            {stepNda === 1
                                ?
                                <div className={Style.box_sign}>

                                    <h2 className={Style.sign_text}>To sign this contract correctly, you should follow these guidelines:</h2>

                                    <hr />
                                    <h2 className={Style.sign_text}>Document to be signed</h2>
                                    <Row>
                                        <Col sm={12} className={Style.column}>
                                            <div className={Style.single_file}>
                                                <div className='d-flex align-items-center'>
                                                    <img src={""} alt="" />
                                                    <div>
                                                        <span>{dataContract.name} - Poliant</span>
                                                        <span className={Style.information}>{dataContract.last_update} / {dataContract.original_file_size}</span>
                                                    </div>
                                                </div>
                                                <div className={Style.download_contract}>
                                                    <a href={contractUrl} download={dataContract.name + '.pdf'} target='_blank' rel="noopener noreferrer">
                                                        <FontAwesomeIcon icon={faDownload} />
                                                    </a>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row >
                                    <hr />

                                    <h3 className={Style.sign_text}>View the entire contract to accept Non-Disclosure Agreement:</h3>

                                    <div className={Style.miniReaderContainer}>
                                        <object data={contractUrl} type="application/pdf" className={Style.miniReaderContent} >
                                        </object>
                                    </div>

                                    <div className='d-flex align-content-center mb-2 mt-3'>
                                        <input type="checkbox" name="read_contract" id="read_contract" defaultChecked={readContract} onChange={(e) => setReadContract(e.target.checked)} />
                                        <label className={Style.label_sign} htmlFor="read_contract">I declare that I have read the Non-Disclosure Agreement</label>
                                    </div>
                                    <div className='d-flex align-content-center mb-2 mt-1'>
                                        <input type="checkbox" name="sign_contract" id="sign_contract" defaultChecked={signContract} onChange={(e) => setSignContract(e.target.checked)} />
                                        <label className={Style.label_sign} htmlFor="sign_contract">I declare that I wish to digitally sign the contract Non-Disclosure Agreement</label>
                                    </div>


                                    <Button
                                        disabled={readContract && signContract ? false : true}
                                        isLoading={signNda.isLoading}
                                        onClick={() => signNda.executeQuery()}
                                    >
                                        Sign Document Digitally
                                    </Button>


                                </div>
                                :
                                <div className={Style.box_allow}>
                                    <div className='text-center'>
                                        <h1 className='mb-4'>Error!</h1>
                                        <p className='mb-2'>An error occurred during the procedure, or it was interrupted. Please try again later.</p>
                                        <button className={Style.sign_in_button} style={{ margin: '15px 5px 5px', width: '150px' }} onClick={() => window.location.reload()}>
                                            Try Again
                                        </button>
                                        <div>or</div>
                                        <button className={Style.sign_in_button} style={{ margin: '5px', width: '150px' }} onClick={() => navigate("/auth/logout")}>
                                            Logout
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </section>
                :
                <AuthLoader text={"Generating contract..."} />
            }
        </>
    )




}

export default SignNda