import React, { useState } from "react";
import Style from "./Wallets.module.css";
import { Row, Col } from "react-bootstrap";
import axios from "axios";

import Config from "../../../config";

const Wallets = (props) => {
	const [checkConfirm, setCheckConfirm] = useState(false);
	const [step, setStep] = useState(1);
	const [error, setError] = useState("");
	const [errorTwo, setErrorTwo] = useState("");


	const [ercWallet, setErcWallet] = useState(
		props.usdt_erc20 ? props.usdt_erc20 : ""
	);
	const [trcWallet, setTrcWallet] = useState(
		props.usdt_trc20 ? props.usdt_trc20 : ""
	);

	//* Cambiare endpoint
	const datas = {
		title: "Confirm wallet address change with 2-step Verification",
		subtitle:
			"We have sent you an email with the two-factor authentication code to confirm the change",
		button: "Confirm Address Change",
		endpoint: Config.base_url + "accounts/otp-verification/",
		method: "CHANGE_WALLET",
	};

	function startEmailVerification() {

		setCheckConfirm(true);

		var addresses = {
			usdt_erc20: ercWallet,
			usdt_trc20: trcWallet,
		};

		const url = Config.base_url + "my-profile/change-wallet/";

		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Token ${localStorage.getItem("token")}`,
			},
		};

		axios
			.post(url, addresses, config)
			.then((response) => {
				if (response.status === 200) {
					const verificationBox = document.getElementById("two_step_verified");
					const layover = document.getElementById("layover");
					verificationBox.style.display = "block";
					layover.style.display = "block";
					props.setPopupState(datas);
				}
			})
			.catch((error) => {
				setCheckConfirm(false);
				setStep(1);
				setError(error.response.data.usdt_erc20);
				setErrorTwo(error.response.data.usdt_trc20);
			});
	}

	return (
		<>
			<section id="wallets_profile">
				<div style={{ paddingTop: 24 }}></div>
				<div className={Style.card_profile}>
					<h2 className={Style.card_title}>Wallets</h2>

					{step === 1 && (
						<>
							<p className={Style.card_info_text}>
								Enter your wallets addresses here
							</p>

							<Row className="mb-4">
								<Col sm={3} className={Style.label_box}>
									<label htmlFor="usdt_erc20" className={Style.col_form_label}>
										USDT ERC20
									</label>
								</Col>
								<Col sm={9}>
									<div className="input-group input-group-sm-vertical">
										<input
											type="text"
											className={Style.form_control}
											value={ercWallet}
											onChange={(e) => setErcWallet(e.target.value)}
											name="usdt_erc20"
											id="usdt_erc20"
											placeholder="Enter your USDT ERC20 address"
											aria-label="Enter your USDT ERC20 address"
										/>
									</div>
									<div className="error_input">{error}</div>
								</Col>
								<Col
									sm={3}
									className={Style.label_box}
									style={{ marginTop: "20px" }}
								>
									<label htmlFor="usdt_trc20" className={Style.col_form_label}>
										USDT TRC20
									</label>
								</Col>
								<Col sm={9} style={{ marginTop: "20px" }}>
									<div className="input-group input-group-sm-vertical">
										<input
											type="text"
											className={Style.form_control}
											value={trcWallet}
											onChange={(e) => setTrcWallet(e.target.value)}
											name="usdt_trc20"
											id="usdt_trc20"
											placeholder="Enter your USDT TRC20 address"
											aria-label="Enter your USDT TRC20 address"
										/>
									</div>
									<div className="error_input">{errorTwo}</div>
								</Col>
							</Row>

							<Row className="mt-4 mb-2">
								<Col sm={12} className={Style.position_button}>

									<button
										className={Style.button}
										onClick={() => setStep(2)}
									>
										Save changes
									</button>

								</Col>
							</Row>
						</>
					)}

					{step === 2 && (
						<>

							<p className={Style.card_info_text_confirm}>
								Are you sure you want to make these changes?
							</p>

							<Row className="mt-4 mb-2">
								<Col sm={12} className={Style.position_button}>

									<button
										className={Style.button_cancel}
										onClick={() => setStep(1)}
									>
										Cancel
									</button>

									{checkConfirm
										?
										<button
											className={Style.button}
											disabled
										>
											Waiting...
										</button>
										:
										<button
											className={Style.button}
											onClick={() => startEmailVerification()}
										>
											Confirm
										</button>
									}

								</Col>
							</Row>

						</>
					)}



				</div>
			</section>
		</>
	);
};

export default Wallets;
