import React from "react";

// Images
import CustomLoader from "../../Assets/img/loader.gif";
import ShapeOne from '../../Assets/img/shapes/shape1.webp';
import ShapeTwo from '../../Assets/img/shapes/shape2.webp';
import ShapeFive from '../../Assets/img/shapes/shape5.webp';
import ShapeFour from '../../Assets/img/shapes/shape4.webp';
import ShapeThree from '../../Assets/img/shapes/shape3.webp';

//Style 
import Style from "./AuthLoader.module.css";

const Loader = ({ text }) => {
    return (
        <div className={Style.loader}>
            <img className={Style.shape} src={ShapeOne} alt="" />
            <img className={Style.shape} src={ShapeTwo} alt="" />
            <img className={Style.shape} src={ShapeThree} alt="" />
            <img className={Style.shape} src={ShapeFour} alt="" />
            <img className={Style.shape} src={ShapeFive} alt="" />
            <div className={Style.viewportLayover}></div>

            <div className={Style.center}>
                <img src={CustomLoader} className="m-auto w-50" alt="" />
                {text && (
                    <div>{text}</div>
                )}
            </div>

        </div>
    );
};

export default Loader
