import React, { useState } from 'react'

// Libraries
import useQuery from '@hybris-software/use-query'
import { useTable, usePagination } from 'react-table'

// Components
import Button from "../../Commons/Button/Button";

// Images
import CopyIcon from '../../../Assets/img/icon/copy-icon.png'
import CloseIcon from '../../../Assets/img/icon/close-icon.png'
import IconConfirm from '../../../Assets/img/icon/icon_step_two_factor.png'
import NoList from '../../../Assets/img/icon/no-list.png'

// Styles
import styled from 'styled-components'
import Style from './SettingReferral.module.css'

//! TO CHANGE
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faPenToSquare } from '@fortawesome/free-solid-svg-icons'

import axios from 'axios'
import Config from '../../../config'

const Styles = styled.div`
  padding: 1rem 0 0rem;
  overflow-x: auto;


  table {
    border-spacing: 0;
    border: 1px solid black;
    width: 100%;
    font-size: 13px;
    font-weight: 600;

    tr {

      :last-child {
        td {
          
        }
      }
    }

    th {
        border: none;
        background-color: #F5F7FA;
        color: #748194;
        font-weight: 600;
        border-radius: 5px;
        padding: 10px;
        min-width: 120px;

        :first-child {
            min-width: 15px;
        }
        :last-child {
            text-align: right;
        }
        
    }

    td {
        padding: 10px;
        border-bottom: 1px solid #EFEFEF;
        background-color: white;
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
        color: #000000;

        :nth-child(2) {
            color: #C079FF;
            cursor: pointer;
            font-weight: 600;
            font-size: 13px;
            line-height: 17px;            
        }

        :last-child {
            border-right: 0;
            text-align: right;
            padding-right: 10px;
        border-bottom: 1px solid #EFEFEF;

        }
    }
    td img {
        height: 16px;
        margin-right: 7px;
    }
    td svg {
        height: 14px;
        margin-right: 4px;
    }
  }
`
const InnerStyles = styled.div` 
  padding: 1rem 0 0rem;
  overflow-x: auto;
  margin-bottom: 3rem;


  table {
    border-spacing: 0;
    border: 1px solid black;
    width: 100%;
    font-size: 13px;
    font-weight: 600;
    text-align: center;

    tr {
      :last-child { 
          td {
          border-bottom: 0;
        }
      }
    }

    th {
        border: none;
        background-color: #F5F7FA;
        color: #748194;
        font-weight: 600;
        border-radius: 5px;
        padding: 10px 0;

        :first-child {
            min-width: 15px;
        }
        :last-child {
           
        }
        
    }

    td {
        padding: 10px 0;
        border-bottom: 1px solid #EFEFEF;
        background-color: white;
        color: #B6C2D2;
        font-weight: 600;
        font-size: 13px;
        line-height: 17px;

    }
    td img {
        height: 16px;
        margin-right: 7px;
    }
    td svg {
        height: 14px;
        margin-right: 4px;
    }
  }
`

function closeSetting() {
    const mainContent = document.getElementsByClassName('main_content');
    mainContent[0].classList.remove('unset_scroll');
    document.getElementById('referral_setting').style.display = 'none';
    document.getElementById('layover').style.display = 'none';
}
function closeAddReferral() {
    document.getElementById('add_new_referral').style.display = 'none';
    document.getElementById('add_new_ref_layover').style.display = 'none';
}

function closeAddReferralConfirm() {
    document.getElementById('add_new_referral_confirm').style.display = 'none';
    document.getElementById('add_new_ref_confirm_layover').style.display = 'none';
}

function openAddReferral() {
    document.getElementById('add_new_referral').style.display = 'block';
    document.getElementById('add_new_ref_layover').style.display = 'block';
    document.getElementById('add_new_referral_confirm').style.display = 'none';
    document.getElementById('add_new_ref_confirm_layover').style.display = 'none';
}

function openConfirmation() {
    //! aggiungere controlli input prima di passare alla conferma
    document.getElementById('add_new_referral_confirm').style.display = 'block';
    document.getElementById('add_new_ref_confirm_layover').style.display = 'block';
}


function Table({ columns, data }) {

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable({
        columns,
        data,
        initialState: { pageSize: 5 },
    },
        usePagination
    )

    // Render the UI for your table
    return (
        <>
            <table {...getTableProps()} style={{ border: 'none' }}>

                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps()}
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>

            </table>
            <div className="pagination">

                <div className='magic'>

                    <div>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                        </button>{' '}

                        <span>
                            {' '}<strong>
                                {pageIndex + 1}
                            </strong>{' '}
                        </span>

                        <button onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                        </button>{' '}
                    </div>

                    <div className={Style.button_setting_table}>
                        <button onClick={() => closeSetting()}>Cancel</button>
                        {/* <button>Confirm</button> */}
                    </div>
                </div>

            </div>
        </>
    )
}
function InnerTable({ columns, data }) {

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable({
        columns,
        data,
        initialState: { pageSize: 5 },
    },
        usePagination
    )

    // Render the UI for your table
    return (
        <>
            <table {...getTableProps()} style={{ border: 'none' }}>

                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps()}
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>

            </table>
            <div className="pagination">

                <div className='magic'>

                    <div>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                        </button>{' '}

                        <span>
                            {' '}<strong>
                                {pageIndex + 1}
                            </strong>{' '}
                        </span>

                        <button onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                        </button>{' '}
                    </div>
                </div>

            </div>
        </>
    )
}
const SettingReferral = () => {

    const [viewData, setViewData] = useState("");
    const [refDefault, setRefDefault] = useState("");
    const [rangeValue, setRangeValue] = useState("");
    const [note, setNote] = useState('');
    const [percMin, setPercMin] = useState(null);
    const [percMax, setPercMax] = useState(null);
    const [listMember, setListMember] = useState(null);

    useQuery({
        url: "referral/referral-page/",
        method: "GET",
        executeImmediately: true,
        onSuccess: (response) => {
            setViewData(response.data.referralTable);
            setPercMin(response.data.referralPercValues.perc_gain_min);
            setPercMax(response.data.referralPercValues.perc_gain_max);
            setRangeValue(response.data.referralPercValues.perc_gain_min)
        }
    })

    //+ Open Default Popup setting 
    function openDefaultSetting(e) {
        document.getElementById('default_confirm').style.display = 'block';
        document.getElementById('default_confirm_layover').style.display = 'block';
        setRefDefault(e.currentTarget.id);
    }

    //+ Open Delete referral Popup 
    function openDelete(e) {
        document.getElementById('delete_referral').style.display = 'block';
        document.getElementById('default_confirm_layover').style.display = 'block';
        setRefDefault(e);
    }
    function getListMember(id) {
        const url = Config.base_url + "referral/referral-members/?code=" + id;
        const token = localStorage.getItem("token");
        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Token ${token}`,
            },
        };
        axios
            .get(url, config)
            .then((response) => {
                setListMember(response.data.referral_list);
            })
            .catch((error) => {
            });
    }

    function openListMember(e) {
        document.getElementById('list_member').style.display = 'block';
        document.getElementById('default_confirm_layover').style.display = 'block';
        getListMember(e);

    }

    function openEditNote(e) {
        document.getElementById('edit_note').style.display = 'block';
        document.getElementById('default_confirm_layover').style.display = 'block';
        setRefDefault(e.currentTarget.id);

    }

    function copyToClipboardOne() {
        var tooltip = document.getElementById("myTooltip_one");
        tooltip.innerHTML = "Copied";
    }
    function outFuncOne() {
        var tooltip = document.getElementById("myTooltip_one");
        tooltip.innerHTML = "Copy to clipboard";
    }

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                accessor: 'col0',
            },
            {
                Header: 'ID',
                accessor: 'col1',
            },
            {
                Header: 'You receive/your member receive',
                accessor: 'col2',
            },
            {
                Header: 'Member',
                accessor: 'col3',
            },
            {
                Header: 'Note',
                accessor: 'col4',
            },
            {
                Header: 'Action',
                accessor: 'col5',
            },
        ],
        []
    );

    let data = React.useMemo(
        () =>
            [],
        []);

    data = [];

    for (let i = 0; i < viewData.length; i++) {
        data.push({
            col0:
                <>
                    {viewData[i].is_default
                        ? <div className={Style.circle_default}></div>
                        : <div className={Style.circle_not_default}></div>
                    }
                </>,
            col1:
                <>
                    <div id='link_referral_to_copy_one' className={Style.box_referral_link}>
                        <div className={Style.tooltip}>
                            <span className={Style.tooltiptext} id="myTooltip_one">Copy to clipboard</span>
                            <img src={CopyIcon} alt='copy'
                                onClick={() => {
                                    copyToClipboardOne();
                                    navigator.clipboard.writeText(viewData[i].referral_id)
                                }}
                                onMouseOut={() => outFuncOne()} /> {viewData[i].referral_id}
                        </div>

                    </div>
                    {/* <img src={CopyIcon} alt='copy' onClick={() => navigator.clipboard.writeText(viewData[i].referral_id)} /> {viewData[i].referral_id} */}
                </>
            ,
            col2: <>
                {
                    viewData[i].you_receive !== undefined && viewData[i].your_member_receive !== undefined
                        ? (viewData[i].you_receive).toFixed(1) + "% / " + (viewData[i].your_member_receive) + "%"
                        : null
                }
            </>,
            col3:
                <>
                    {viewData[i].member
                        ? <span id={viewData[i].referral_id} onClick={(e) => openListMember(e.currentTarget.id)} className={Style.list_member}><FontAwesomeIcon icon={faList} /> {viewData[i].member} </span>
                        : <span className={Style.list_member_minus}><FontAwesomeIcon icon={faList} /> 0 </span>
                    }
                </>,
            col4:
                <>
                    {viewData[i].note !== ''
                        ? <span id={viewData[i].referral_id} className={Style.list_member} onClick={(e) => openEditNote(e)}><FontAwesomeIcon icon={faPenToSquare} className={Style.note_icon} /> {viewData[i].note}</span>
                        : <span id={viewData[i].referral_id} className={Style.list_member_minus} onClick={(e) => openEditNote(e)}><FontAwesomeIcon icon={faPenToSquare} className={Style.note_icon} /> --</span>
                    }

                </>,
            col5:
                <>
                    {viewData[i].is_default
                        ? <span className={Style.now_default_code}>Default Referral Link</span>
                        :
                        <>
                            <span className={Style.set_default_referral} id={viewData[i].referral_id} onClick={(e) => openDefaultSetting(e)}>Set as Default</span>
                            <span className={Style.delete_referral} id={viewData[i].referral_id} onClick={(e) => openDelete(e.currentTarget.id)}>Delete</span>
                        </>
                    }
                </>,
        })

    }

    return (
        <>
            <>
                <section id='referral_setting' className={Style.referral_setting}>
                    {viewData && viewData !== 'You don\'t have any referrals yet' ? (
                        <>
                            <div id='default_confirm_layover' className={Style.layover_default}></div>
                            <PopUpSetDefault refDefault={refDefault} />
                            <DeletePopUp refDefault={refDefault} />
                            <EditNotePopUp refDefault={refDefault} />
                            <ListMemberData refDefault={refDefault} listMember={listMember} setListMember={setListMember} />

                            <div id='add_new_ref_layover' className={Style.layover_add_ref}></div>
                            <AddNewReferrel rangeValue={rangeValue} note={note} setNote={setNote} setRangeValue={setRangeValue} percMin={percMin} percMax={percMax} />

                            <div className='text-right pb-2'>
                                <img style={{ cursor: 'pointer' }} src={CloseIcon} alt="Close" onClick={() => closeSetting()} />
                            </div>
                            <div className={Style.title_setting}>

                                <div>
                                    <h2>Select Referral</h2>
                                </div>
                                <button className={Style.add_new_referral_link} onClick={() => openAddReferral()}>Add new referral</button>

                            </div>
                            <p className={Style.remaining_code_text}>Remaining number of promotional links to be created: {30 - viewData.length}</p>

                            <Styles>
                                <Table columns={columns} data={data} />
                            </Styles>
                        </>

                    ) : (
                        <>
                            <div id='add_new_ref_layover' className={Style.layover_add_ref}></div>
                            <AddNewReferrel rangeValue={rangeValue} note={note} setNote={setNote} setRangeValue={setRangeValue} percMin={percMin} percMax={percMax} />

                            <div className='text-right pb-2'>
                                <img style={{ cursor: 'pointer' }} src={CloseIcon} alt="Close" onClick={() => closeSetting()} />
                            </div>
                            <div className={Style.title_setting}>

                                <div>
                                    <h2>Select Referral</h2>
                                </div>
                                <button onClick={() => openAddReferral()}>Add new referral</button>

                            </div>
                            <p>You have no promotional links<br /></p>
                        </>
                    )}
                </section>
            </>
        </>

    )
}

const AddNewReferrel = (props) => {

    let minePerc = (props.percMax - props.rangeValue).toFixed(1) + '%'
    const [isDefault, setIsDefault] = useState(false);

    return (
        <>
            <div id='add_new_referral'>
                <div className={Style.box_add_ref}>
                    <div id='add_new_ref_confirm_layover' className={Style.layover_box_add_ref}></div>
                    <ConfirmNewReferral {...props} minePerc={minePerc} isDefault={isDefault} />

                    <div className='text-right pb-2'>
                        <img style={{ cursor: 'pointer' }} src={CloseIcon} alt="Close" onClick={() => closeSetting()} />
                    </div>

                    {/* Title */}
                    <h5>Add new Referral</h5>
                    <p>Your basic reward for the Arbitrage Pool is: {props.percMax}%</p>

                    {/* Input note */}
                    <label htmlFor='note'>Note</label>
                    <div className='d-flex'>
                        <input id='note' type="text" className='form-control' value={props.note} onChange={(e) => props.setNote(e.target.value)} minLength={1} maxLength={20} placeholder='Enter 1 to 20 characters' />
                    </div>

                    <p className='mt-4 mb-1'>Set the Rewards Ratio, Arbitrage Pool</p>
                    {/* Input % */}

                    <div className="input-group input-group-sm-vertical mb-4 chrome">
                        <input type="range" className={Style.range_input} min={props.percMin} step={0.1} max={props.percMax} value={props.rangeValue} onChange={(e) => props.setRangeValue(e.target.value)}
                            name="rangenewreferral" id="rangenewreferral" readOnly />
                    </div>
                    <div className={Style.set_ratio}>
                        <div className='d-flex align-items-center'>
                            <label className='me-2' htmlFor='mine'>Mine:</label>
                            <input readOnly id='mine' type="text" className='form-control' placeholder={minePerc} />
                        </div>
                        <div className='d-flex align-items-center'>
                            <label className='me-2 ms-1' htmlFor='member'>Member:</label>
                            <input id='member' type="text" className='form-control' placeholder={props.rangeValue + '%'} onChange={(e) => {
                                props.setRangeValue(e.target.value.replace(',', '.'));
                            }} />
                        </div>
                    </div>

                    <input className='mt-4' type="checkbox" name="default" id="set_as_default_crated" defaultChecked={isDefault} onChange={(e) => setIsDefault(e.target.checked)} />
                    <label className={Style.label_set_default} htmlFor='set_as_default_crated'>Set as default referral</label>


                    <div className={Style.boxButtonReferralLeft}>
                        <button onClick={() => closeAddReferral()}>Cancel</button>
                        <Button
                            className={Style.confirmButtonReferral}
                            onClick={() => openConfirmation()}
                        >
                            {props.rangeValue > props.percMax ? 'Error' : 'Confirm'}
                        </Button>
                    </div>

                </div>
            </div >
        </>
    )
}

const ConfirmNewReferral = (props) => {

    let data = {
        note: props.note,
        is_default: props.isDefault,
        perc_gain_value: props.rangeValue,
    }

    const createNewReferral = useQuery({
        url: "referral/add-new-referral/",
        method: "POST",
        executeImmediately: false,
        onSuccess: () => {
            window.location.reload();
        },
        onError: () => {
            //! Set message error
            alert('Something went wrong');
            window.location.reload();
        }
    })

    return (
        <>
            <div id='add_new_referral_confirm' className={Style.confirm_new_referral}>
                <img src={IconConfirm} alt="" />

                <p className='mt-4 mb-4'>Are you sure you want to create this Referral Link?<br /> This referral link will be valid especially for the Arbitrage Pool.<br /> You will receive {props.minePerc} Rewards and your user will receive {props.rangeValue}%.<br /> This link will also become the default link and you will find it in your dashboard.</p>

                <div className={Style.boxReferralButtons}>
                    <button onClick={() => closeAddReferralConfirm()}>Cancel</button>
                    <Button
                        className={Style.confirmButtonReferral}
                        isLoading={createNewReferral.isLoading}
                        onClick={() => createNewReferral.executeQuery(data)}
                    >
                        Confirm
                    </Button>
                </div>

            </div>
        </>
    )
}

const PopUpSetDefault = (props) => {


    function closeDefaultConfirm() {
        document.getElementById('default_confirm').style.display = 'none';
        document.getElementById('default_confirm_layover').style.display = 'none';
    }

    let data = {
        code: props.refDefault,
    }

    const setDefaultReferral = useQuery({
        url: "referral/set-default-referral/",
        method: "POST",
        executeImmediately: false,
        onSuccess: () => {
            window.location.reload();
        },
        onError: () => {
            //! Set message error
            alert('Something went wrong');
            window.location.reload();
        }
    })

    return (
        <>
            <div id='default_confirm' className={Style.confirm_new_referral}>
                <img src={IconConfirm} alt="" />

                <p>Once you have set "{props.refDefault}" as your default referral ID, the corresponding commission report and referral links will be displayed on your homepage. Are you sure you want to set this as your default referral ID?</p>

                <div className={Style.boxReferralButtons}>
                    <button onClick={() => closeDefaultConfirm()}>Cancel</button>
                    <Button
                        className={Style.confirmButtonReferral}
                        isLoading={setDefaultReferral.isLoading}
                        onClick={() => setDefaultReferral.executeQuery(data)}
                    >
                        Confirm
                    </Button>
                </div>

            </div>
        </>
    )
}

const DeletePopUp = (props) => {

    function closeDeleteConfirm() {
        document.getElementById('delete_referral').style.display = 'none';
        document.getElementById('default_confirm_layover').style.display = 'none';
    }
    let data = {
        code: props.refDefault,
    }

    const deleteReferral = useQuery({
        url: "referral/delete-referral/",
        method: "POST",
        executeImmediately: false,
        onSuccess: () => {
            window.location.reload();
        },
        onError: () => {
            //! Set message error
            alert('Something went wrong');
            window.location.reload();
        }
    })

    return (
        <>
            <div id='delete_referral' className={Style.confirm_new_referral}>
                <img src={IconConfirm} alt="" />

                <p>Are you sure you want to delete the Refferal Code "{props.refDefault}" Please note that you will no longer find the analytycs of the member list and referral link notes in this section. The link will no longer work after deactivation. </p>

                <div className={Style.boxReferralButtons}>
                    <button onClick={() => closeDeleteConfirm()}>Cancel</button>
                    <Button
                        className={Style.confirmButtonReferral}
                        isLoading={deleteReferral.isLoading}
                        onClick={() => deleteReferral.executeQuery(data)}
                    >
                        Confirm
                    </Button>

                </div>

            </div>
        </>
    )
}

const EditNotePopUp = (props) => {

    const [note, setNote] = useState('');

    let data = {
        code: props.refDefault,
        note: note,
    }

    function closeEdit() {
        document.getElementById('edit_note').style.display = 'none';
        document.getElementById('default_confirm_layover').style.display = 'none';
    }

    const editNoteReferral = useQuery({
        url: "referral/change-referral-note/",
        method: "POST",
        executeImmediately: false,
        onSuccess: () => {
            window.location.reload();
        },
        onError: () => {
            //! Set message error
            alert('Something went wrong');
            window.location.reload();
        }
    })

    return (
        <>
            <div id='edit_note' className={Style.confirm_new_referral} style={{ textAlign: 'left' }}>
                <span className={Style.close_button_improved} onClick={() => closeEdit()}>
                    <img src={CloseIcon} alt="Close" />
                </span>

                <h2 className={Style.note_title}>Edit Note</h2>
                {/* //! Se è presente gia una nota passarla come placeholder */}
                <div className={Style.input_note_box}>
                    <input id='member' type="text" className='form-control' maxLength={20} value={note} onChange={(e) => setNote(e.target.value)} placeholder='New Note' />
                </div>

                <div className={Style.boxButtonReferralLeft}>
                    <button onClick={() => closeEdit()}>Cancel</button>
                    <Button
                        className={Style.confirmButtonReferral}
                        isLoading={editNoteReferral.isLoading}
                        onClick={() => editNoteReferral.executeQuery(data)}
                    >
                        Confirm
                    </Button>
                </div>

            </div>
        </>
    )
}

const ListMemberData = (props) => {

    function closeMemberList() {
        document.getElementById('list_member').style.display = 'none';
        document.getElementById('default_confirm_layover').style.display = 'none';
        props.setListMember(null);
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Your member ID',
                accessor: (row) => row.pol_id,
            },
            {
                Header: 'Name',
                accessor: (row) => row.full_name,
            },
            {
                Header: 'Data ',
                accessor: (row) => row.date_joined,
            },
        ],
        []
    )

    return (
        <div id='list_member' className={Style.list_popup} style={{ textAlign: 'left' }}>
            <span className={Style.close_button_improved} onClick={() => closeMemberList()}>
                <img src={CloseIcon} alt="Close" />
            </span>

            <h2 className={Style.note_title}>Member list</h2>

            <InnerStyles>
                {(props.listMember !== null && props.listMember !== undefined)
                    ? <InnerTable columns={columns} data={props.listMember} />
                    : <>
                        <div className={Style.no_list_data}>
                            <img src={NoList} alt="Empty List" />
                            <p>No information</p>
                        </div>
                    </>
                }
            </InnerStyles>
        </div>
    )
}

export default SettingReferral