import React from 'react'
import Style from './ServicePage.module.css'
import Logo from '../../Assets/img/logos/poliant_logo_black.webp'
import ImageService from '../../Assets/img/400.png'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Page404 = () => {
    return (
        <>
            <div className={Style.fullPage}>

                <div className={Style.logo}>
                    <img src={Logo} alt="" />
                </div>

                <div className={Style.content}>
                    <Container>
                        <Row>
                            <Col md={6} className={Style.left}>
                                <img src={ImageService} alt="" />
                            </Col>
                            <Col md={6} className={Style.right}>
                                <h1>404</h1>
                                <p>
                                    Sorry, the page you're looking for <br />
                                    cannot be found.
                                </p>
                                <Link to="/my-profile">
                                    <button className={Style.button}>Go back to the App</button>
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className={Style.footer}>
                    <div className={Style.copyright}>Copyright Poliant &reg; 2022</div>
                    {/* <div className={Style.footer_link}>
                        <a href="#!">Poliant Support</a>
                        <span> - </span>
                        <a href="#!">Poliant Status</a>
                        <span> - </span>
                        <a href="#!">Get Help</a>
                    </div> */}
                </div>

            </div>
        </>
    )
}

export default Page404