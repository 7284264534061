import React, { useState, useEffect } from "react";

// Libraries
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useQuery from "@hybris-software/use-query";
import { PermissionRoute } from "@hybris-software/use-auth";

// Components
import Loader from "../Components/Loader/Loader";
import ContractList from "../Components/Contract/ContractList";

const Contract = () => {
  const navigate = useNavigate();
  const [viewData, setViewData] = useState(null);

  useEffect(() => { document.title = "Poliant | Contracts"; }, []);

  //+ Call data contract page 
  const viewDataApi = useQuery({
    url: "contract/contract-page/",
    method: "GET",
    executeImmediately: false,
    onSuccess: (data) => {
      setViewData(data.data);
    }
  })

  return (
    <PermissionRoute
      forLoggedUser={true}
      loader={<Loader />}
      unAuthorizedAction={() => navigate("/auth/login")}
      permissionController={(data) => {
        if (data.data.status === "ACTIVE") {
          viewDataApi.executeQuery();
          return { value: true }
        }
        else {
          return { value: false, status: data.data.status }
        }
      }}
      forbiddenAction={(data) => {
        if (data.status === "PENDING") { navigate("/auth/register-survey"); }
        if (data.status === "PENDING_SURVEYED") { navigate("/auth/register-survey-sent"); }
        if (data.status === "PENDING_EXTRA_DATA") { navigate("/auth/register-complete"); }
        if (data.status === "PENDING_TERMS") { navigate("/auth/accept-terms-and-conditions"); }
        if (data.status === "PENDING_NDA") { navigate("/auth/sign-non-disclosure-agreement"); }
        if (data.status === "DELETED") { navigate("/auth/login"); }
      }}
    >
      {viewData && (
        <Row>
          <ContractList {...viewData} />
        </Row>
      )}
    </PermissionRoute>
  );
};

export default Contract;
