import React, { useState, useEffect } from 'react'

// Libraries
import { Row, Col } from 'react-bootstrap'

// Images
import PdfImage from '../../Assets/img/icon/pdf-icon.png'
import CloseIcon from '../../Assets/img/icon/close-icon.png'

// Styles
import Style from './ContractList.module.css'

//! TO CHANGE
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faEye, faFileSignature, faDownload, faFolder, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'

import axios from 'axios'
import Config from '../../config'

function makeid(length) {
    var result = "";
    var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function checkContract() {
    const layover = document.getElementById('layover');
    const popupGoSign = document.getElementById("popupGoSign");
    popupGoSign.style.display = "block";
    layover.style.display = 'block';

}

const SingleReceipts = (props) => {

    const getReceipt = async (id) => {
        const response = await axios.get(`${Config.base_url}contract/receipts/${id}/url/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            }
        })
        window.open(response.data.url, '_blank', 'noopener noreferrer')
    }

    return (
        <Row>
            <Col sm={12} className={Style.column}>
                <div className={Style.single_file}>
                    <div className='d-flex align-items-center'>
                        <img src={PdfImage} alt="" />
                        <div>
                            <span className={Style.nomeCompleteFile}>{props.name.length > 50 ? props.name.slice(0, 50) + '..' : props.name}
                                {/* {props.pool === 'ARBITRAGE' && (
                                    <span className='badge bg-success'>Signed</span>
                                )}
                                {props.pool === 'ARBITRAGE' && (
                                    <span className='badge bg-review'>Under review</span>
                                )} */}
                            </span>
                            <span className={Style.information}>{props.date}</span>
                        </div>
                    </div>
                    <div className={Style.button_more + ' button_sign close_all_sing'} onClick={() => {

                        if (document.getElementById(props.randomId).classList.contains('show')) {
                            document.getElementById(props.randomId).classList.remove('show');
                        }
                        else {
                            if (document.getElementsByClassName('show').length > 0) {
                                document.getElementsByClassName('show')[0].classList.remove('show');
                            }
                            document.getElementById(props.randomId).classList.add('show');
                        }
                    }} onMouseUp={(e) => e.stopPropagation()}>
                        <div className={Style.setting_popup + ' setting_sign'} id={props.randomId}>
                            <h2 className='mb-3'>SETTINGS</h2>

                            <p onClick={() => getReceipt(props.id)}>
                                <FontAwesomeIcon icon={faDownload} className='me-2' /> Download
                            </p>

                        </div>
                        <div className={Style.icon + ' more_icon_sign close_all_sing'} >
                            More <FontAwesomeIcon className='close_all_sing' icon={faChevronDown} />
                        </div>
                    </div>
                </div>
            </Col>
        </Row >
    )
}
const SingleFile = (props) => {
    const getContract = async (id, isSigned, windowReference) => {
        const contractFileVersion = isSigned ? 'signed' : 'original'
        const response = await axios.get(`${Config.base_url}contract/contracts/${id}/url/${contractFileVersion}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            }
        })
        windowReference.location = response.data.url + "&download=1";
    }

    const openContract = async (id, isSigned, windowReference) => {
        const contractFileVersion = isSigned ? 'signed' : 'original'
        const response = await axios.get(`${Config.base_url}contract/contracts/${id}/url/${contractFileVersion}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            }
        })
        windowReference.location = response.data.url;
    }

    function signNow(id, originalFile, creationDate, originalFileSize, fileName) {
        if (props.contractSigned === true) {
            props.setIdentified(id);
            props.setPdf((originalFile).replace('http:', 'https:'));
            props.setData(creationDate);
            props.setSize(originalFileSize);
            props.setName(fileName);
            const mainContent = document.getElementsByClassName("main_content");
            mainContent[0].classList.add('unset_scroll');
            document.getElementById('layover').style.display = 'block';
            document.getElementById('sign_contract').style.display = 'block';
        } else {
            const layover = document.getElementById('layover');
            const popupGoSign = document.getElementById("popupGoSign");
            popupGoSign.style.display = "block";
            layover.style.display = 'block';
        }
    }

    return (
        <Row>
            <Col sm={12} className={Style.column}>
                <div className={Style.single_file}>
                    <div className='d-flex align-items-center'>
                        <img src={PdfImage} alt="" />
                        <div>
                            <span className={Style.nomeCompleteFile}>{props.name.length > 50 ? props.name.slice(0, 50) + '..' : props.name} - Poliant - #{props.serial_code}
                                {props.status === 'NOT_SIGNED' && (
                                    <span className='badge bg-info-2'>Not signed</span>
                                )}
                                {props.status === 'SIGNED' && (
                                    <span className='badge bg-success'>Signed</span>
                                )}
                                {props.status === 'UNDER_REVIEW' && (
                                    <span className='badge bg-review'>Under review</span>
                                )}
                                {props.status === 'REJECTED_REVISION' && (
                                    <span className='badge bg-danger-2'>Rejected revision</span>
                                )}
                                {props.status === 'EXPIRED' && (
                                    <span className='badge bg-danger'>Expired</span>
                                )}
                                {props.status === 'INVALID' && (
                                    <span className='badge bg-black'>Invalid</span>
                                )}
                            </span>
                            <span className={Style.information}>{props.creation_date} / {props.signed_file ? props.signed_file_size : props.original_file_size}</span>
                        </div>
                    </div>
                    <div className={Style.button_more + ' button_sign close_all_sing'} onClick={() => {

                        if (document.getElementById(props.randomId).classList.contains('show')) {
                            document.getElementById(props.randomId).classList.remove('show');
                        }
                        else {
                            if (document.getElementsByClassName('show').length > 0) {
                                document.getElementsByClassName('show')[0].classList.remove('show');
                            }
                            document.getElementById(props.randomId).classList.add('show');
                        }
                    }} onMouseUp={(e) => e.stopPropagation()}>
                        <div className={Style.setting_popup + ' setting_sign'} id={props.randomId}>
                            <h2 className='mb-3'>SETTINGS</h2>
                            {props.contractSigned === true
                                ?
                                <p onClick={() => {
                                    const windowReference = window.open();
                                    openContract(props.id, props.signed_file != null, windowReference)
                                }}>
                                    <FontAwesomeIcon icon={faEye} className='me-2' /> Open
                                </p>
                                :
                                <p onClick={() => checkContract()}>
                                    <FontAwesomeIcon icon={faEye} className='me-2' /> Open
                                </p>
                            }

                            {props.status === 'SIGNED' || props.status === 'EXPIRED' || props.status === 'INVALID'
                                ? null
                                : <p onClick={() => signNow(props.id, props.original_file, props.creation_date, props.original_file_size, props.name)}>
                                    <FontAwesomeIcon icon={faFileSignature} className='me-2' /> Sign Now
                                </p>
                            }


                            <p onClick={() => {
                                const windowReference = window.open();
                                getContract(props.id, props.signed_file != null, windowReference);
                            }}>
                                <FontAwesomeIcon icon={faDownload} className='me-2' /> Download
                            </p>


                        </div>
                        <div className={Style.icon + ' more_icon_sign close_all_sing'} >
                            More <FontAwesomeIcon className='close_all_sing' icon={faChevronDown} />
                        </div>
                    </div>
                </div>
            </Col>
        </Row >
    )
}

const Files = (props) => {
    const [showedElement, setShowedElement] = useState('empty');

    const emptyDataMessage = (isActive) => {
        if (isActive === 'General Contract' || isActive === 'Custom Contract') {
            return 'No contracts';
        } else if (isActive === 'Pool Receipts') {
            return 'No receipts';
        } else {
            return 'No files';
        }
    }

    return (
        <>
            <small className={Style.path_dir}>
                <a href="#!" onClick={() => props.setIsActive(null)}>Documents</a> /
                <a href="#!">{props.isActive}</a> /
                <a href="#!" className={Style.active_folder}>Files</a>
            </small>
            <h2 className={Style.title}>Files</h2>


            {props.isActive === "General Contract" && (
                <>
                    {/* <span className={Style.titleLegend}>Legend:</span> */}
                    <Row>
                        <Col md={6} className='mb-4'>
                            <div className={Style.legendContract}>
                                <div className={Style.divisor}>
                                    <span className='badge bg-info-2 ms-0'>Not signed</span>
                                    <div className={Style.description}>Contract generated correctly but not signed</div>
                                </div>
                                <div className={Style.divisor}>
                                    <span className='badge bg-success ms-0'>Signed</span>
                                    <div className={Style.description}>Correctly signed contract</div>
                                </div>
                                <div className={Style.divisor}>
                                    <span className='badge bg-danger ms-0'>Expired</span>
                                    <div className={Style.description}>Expired contract for change of personal data or change of contract</div>
                                </div>
                                <div className={Style.divisor}>
                                    <span className='badge bg-black ms-0'>Invalid</span>
                                    <div className={Style.description}>Contract not generated correctly</div>
                                </div>

                            </div>
                        </Col>
                        <Col md={6} className='mb-4'>
                            <div className={Style.legendContract}>
                                <div className={Style.divisor}>
                                    <span className={Style.badgeAcr}>NDA</span>
                                    <div className={Style.description}>NON-DISCLOSURE AGREEMENT</div>
                                </div>
                                <div className={Style.divisor}>
                                    <span className={Style.badgeAcr}>IPLA</span>
                                    <div className={Style.description}>IP LICENSING AGREEMENT (INTELLECTUAL PROPERTY RIGHTS)</div>
                                </div>
                                <div className={Style.divisor}>
                                    <span className={Style.badgeAcr}>IA</span>
                                    <div className={Style.description}>INTERMEDIARY AGREEMENT</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>
            )}

            <div className={Style.box_files}>
                {
                    props.data.length > 0
                        ?
                        props.data.map((item, index) => {
                            const randomId = 'contract_popup_' + index + '_' + makeid(6);
                            return (

                                props.isActive === "General Contract"
                                    ?
                                    <SingleFile showedElement={showedElement} setShowedElement={setShowedElement} randomId={randomId} key={item.id} {...item} setIdentified={props.setIdentified} setPdf={props.setPdf} setName={props.setName} setData={props.setData} setSize={props.setSize} setSignActive={props.setSignActive} contractSigned={props.contractSigned} />
                                    : props.isActive === "Pool Receipts"
                                        ?
                                        <SingleReceipts key={item.id} {...item} randomId={randomId} />
                                        :
                                        <>{"  "}Loading</>


                            )
                        })
                        :
                        <div style={{ padding: '10px 20px' }}>{emptyDataMessage(props.isActive)}</div>
                }
            </div>
        </>
    )
}

const ContractList = (props) => {
    const [isActive, setIsActive] = useState(null);
    const [identified, setIdentified] = useState(null);

    const [pdf, setPdf] = useState(null);
    const [name, setName] = useState(null);
    const [data, setData] = useState(null);
    const [size, setSize] = useState(null);

    const [contractSigned, setContractSigned] = useState(null);

    useEffect(() => {
        const getSignatureCheck = async () => {
            const contractCheck = await axios.get(
                Config.base_url + "my-profile/header-info/",
                {
                    headers: {
                        Authorization: `Token ${localStorage.getItem("token")}`,
                    },
                }
            );
            setContractSigned(contractCheck.data.contract_status.Intermediary_Agreement);
        };
        getSignatureCheck();
    }, []);

    return (
        <>
            <ViewContract identified={identified} pdf={pdf} name={name} data={data} size={size} />
            <small className={Style.path_dir}>
                <a href="#!">Contract</a>
                {isActive === null
                    ? <>/ <a href="#!" onClick={() => setIsActive(null)} className={Style.active_folder}>Documents</a></>
                    : <>
                        / <a href="#!" onClick={() => setIsActive(null)}>Documents</a>
                        / <a href="#!" className={Style.active_folder}>Folders</a >
                    </>
                }
            </small>
            <h2 className={Style.title}>Folders</h2>
            <Row>
                <Col className='d-flex mb-4 flex-wrap'>

                    <div className='position-relative'>
                        <div className={(isActive === 'General Contract') ? Style.folder_box_active : Style.folder_box} onClick={() => setIsActive('General Contract')}>
                            General Contract
                        </div>
                        <div id='general_box_menu' className={Style.button_more_folder} onClick={(e) => seeMenuFolder(e)}>
                            <div id='general_contract' className={Style.setting_popup_folder} onMouseUp={(e) => e.stopPropagation()}>
                                <h2 className='mb-3'>SETTINGS</h2>
                                <p onClick={() => setIsActive('General Contract')}><FontAwesomeIcon icon={faFolder} className='me-2' /> Open</p>
                                {contractSigned === true
                                    ? <p><FontAwesomeIcon icon={faDownload} className='me-2' /> Download</p>
                                    : <p onClick={() => checkContract()}><FontAwesomeIcon icon={faDownload} className='me-2' /> Download</p>
                                }
                            </div>
                            <div id='general_circle' className={Style.circle_folder}>
                                <FontAwesomeIcon id="svg_general" icon={faEllipsisVertical} />
                            </div>
                        </div>
                    </div>

                    <div className="position-relative">
                        <div className={(isActive === 'Pool Receipts') ? Style.folder_box_active : Style.folder_box} onClick={() => setIsActive('Pool Receipts')}>
                            Pool Receipts
                        </div>
                        <div id='pool_box_menu' className={Style.button_more_folder} onClick={(e) => seeMenuFolder(e)}>
                            <div id='pool_contract' className={Style.setting_popup_folder} onMouseUp={(e) => e.stopPropagation()}>
                                <h2 className='mb-3'>SETTINGS</h2>
                                <p onClick={() => setIsActive('Pool Receipts')}><FontAwesomeIcon icon={faFolder} className='me-2' /> Open</p>
                                {/*<p><FontAwesomeIcon icon={faDownload} className='me-2' /> Download</p>*/}
                            </div>
                            <div id='pool_circle' className={Style.circle_folder}>
                                <FontAwesomeIcon id='svg_pool' icon={faEllipsisVertical} />
                            </div>
                        </div>
                    </div>

                    <div className="position-relative">
                        <div className={(isActive === 'Custom Contract') ? Style.folder_box_active : Style.folder_box} onClick={() => setIsActive('Custom Contract')}>
                            Custom Contract
                        </div>
                        <div id='custom_box_menu' className={Style.button_more_folder} onClick={(e) => seeMenuFolder(e)}>
                            <div id='custom_contract' className={Style.setting_popup_folder} onMouseUp={(e) => e.stopPropagation()}>
                                <h2 className='mb-3'>SETTINGS</h2>
                                <p onClick={() => setIsActive('Custom Contract')}><FontAwesomeIcon icon={faFolder} className='me-2' /> Open</p>
                                <p><FontAwesomeIcon icon={faDownload} className='me-2' /> Download</p>
                            </div>
                            <div id='custome_circle' className={Style.circle_folder}>
                                <FontAwesomeIcon id='svg_custom' icon={faEllipsisVertical} />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            {
                isActive === 'General Contract' && (
                    <Files isActive={isActive} setIsActive={setIsActive} data={props.general_contract} setIdentified={setIdentified} setPdf={setPdf} setName={setName} setData={setData} setSize={setSize} contractSigned={contractSigned} />
                )
            }

            {
                isActive === 'Pool Receipts' && (
                    <Files isActive={isActive} setIsActive={setIsActive} data={props.pool_receipts} setIdentified={setIdentified} setPdf={setPdf} setName={setName} setData={setData} setSize={setSize} contractSigned={contractSigned} />
                )
            }

            {
                isActive === 'Custom Contract' && (
                    <Files isActive={isActive} setIsActive={setIsActive} data={props.custom_contract} setIdentified={setIdentified} setPdf={setPdf} setName={setName} setData={setData} setSize={setSize} contractSigned={contractSigned} />
                )
            }
        </>
    )

    function seeMenuFolder(e) {
        var element = e.currentTarget.children[0];
        element.classList.toggle('show_folder');
    }
}

const ViewContract = (props) => {
    var data = {
        id: props.identified,
    }
    function callSignContract() {
        const url = Config.base_url + "contract/sign-contract/";
        const token = localStorage.getItem("token");

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        };
        axios
            .post(url, data, config)
            .then((response) => {
                if (response.status === 200) {
                    window.location.reload();
                }
            })
            .catch((error) => {
                alert('Not Signed or alredy signed');
            });
    }
    const [readContract, setReadContract] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);

    return (
        <div id='sign_contract' style={{ display: 'none' }} className={Style.box_sign}>

            <span className={Style.close_button} onClick={() => {
                const mainContent = document.getElementsByClassName("main_content");
                mainContent[0].classList.remove('unset_scroll');
                document.getElementById('sign_contract').style.display = 'none';
                document.getElementById('layover').style.display = 'none';

            }}>
                <img src={CloseIcon} alt="Close" />
            </span>

            <h2 className={Style.sign_text}>To sign this contract correctly, you should follow these guidelines:</h2>

            <hr />
            <h2 className={Style.sign_text}>Document to be signed</h2>
            <Row>
                <Col sm={12} className={Style.column}>
                    <div className={Style.single_file}>
                        <div className='d-flex align-items-center'>
                            <img src={PdfImage} alt="" />
                            <div>
                                <span>{props.name} - Poliant</span>
                                <span className={Style.information}>{props.data} / {props.size}</span>
                            </div>
                        </div>
                        <div className={Style.download_contract}>
                            <a href={props.pdf} target='_blank' rel="noopener noreferrer" download>
                                <FontAwesomeIcon icon={faDownload} />
                            </a>
                        </div>
                    </div>
                </Col>
            </Row >
            <hr />

            <h3 className={Style.sign_text}>View the entire contract to accept the terms and conditions:</h3>

            <div className={Style.miniReaderContainer}>
                <object data={props.pdf} type="application/pdf" className={Style.miniReaderContent} >
                </object>
            </div>

            <div className='d-flex align-content-center mb-2 mt-3'>
                <input type="checkbox" name="read_contract" id="read_contract" defaultChecked={readContract} onChange={(e) => setReadContract(e.target.checked)} />
                <label className={Style.label_sign} htmlFor="read_contract">I have read and understood the contents of the contract and decide to digitally sign this document.</label>
            </div>

            <div className='d-flex align-content-center mb-2'>
                <input type="checkbox" name="sign_contract_sign" id="sign_contract_sign" defaultChecked={acceptTerms} onChange={(e) => setAcceptTerms(e.target.checked)} />
                <label className={Style.label_sign} htmlFor="sign_contract_sign">By signing this document I automatically accept our Privacy Policy and Terms &amp; Conditions.</label>
            </div>

            <div className='text-center'>
                {readContract && acceptTerms
                    ? <button className={Style.button} onClick={() => callSignContract()}>Sign Document Digitally</button>
                    : <button disabled className={Style.button}>Sign Document Digitally</button>
                }
            </div>

        </div>
    )

}

export default ContractList