import React, { useState, useEffect } from "react";

// Libraries
import useQuery from "@hybris-software/use-query";
import useForm from "@hybris-software/use-ful-form";
import { Link, useNavigate } from "react-router-dom";
import { AuthRoute } from "@hybris-software/use-auth";
import { InputField } from "@hybris-software/ui-kit";

// Components
import TwoFactor from "../TwoFactor/TwoFactor";
import Button from "../../Commons/Button/Button";
import AuthLoader from "../../AuthLoader/AuthLoader";

// Images
import LogoPoliant from "../../../Assets/img/logos/poliant_logo.webp";

// Styles
import Style from "./Login.module.css";

const Login = () => {
	const navigate = useNavigate();
	const [rememberMe, setRememberMe] = useState(true);

	useEffect(() => {
		document.title = "Poliant | Login";
	}, [navigate]);

	//+ Form datas  
	const form = useForm({
		inputs: {
			username: {
				required: true,
				value: localStorage.username || "",
				formatter: (v) => (v)
			},
			password: {
				nature: "password",
				required: true,
				validator: null,
			}
		}
	})

	//+ Call Login 
	const loginApi = useQuery({
		url: "accounts/login/",
		method: "POST",
		executeImmediately: false,
		onSuccess: (response) => {
			localStorage.setItem("rememberMe", rememberMe); // Set remember me
			if (localStorage.rememberMe === "false" || null) {
				setRememberMe(false);
				localStorage.removeItem("username");
			}
			if (localStorage.rememberMe === "true") {
				setRememberMe(true);
				localStorage.setItem("username", form.values["username"]);
			} else {
				localStorage.removeItem("username");
			}
			localStorage.setItem("token", response.data.key); // Set Token
		},
		onError: () => {
			localStorage.removeItem("token"); // Remove Token
		}
	})

	return (
		<>
			<AuthRoute
				forLoggedUser={false}
				loader={<AuthLoader />}
				action={() => { navigate("/") }}
			>
				{/* Login */}
				{!loginApi.isSuccess && (
					<section>
						<img className={Style.logo} src={LogoPoliant} alt="Poliant logo" />
						<div className={Style.login}>
							<div className={Style.container}>

								<div className={Style.card}>
									{/* Header & goSign Up */}
									<div className={Style.header}>
										<h2>Sign in</h2>
										<p>Don't have an account yet?</p>
										<div className={Style.signUp}>
											<Link to="/auth/register">Sign up here</Link>
										</div>
									</div>
									{/* Email */}
									<InputField
										baseClassName={Style.input}
										label={"Your Email / Username"}
										placeholder={"email@address.com"}
										errorIconVisibility={true}
										required={true}
										successIconVisibility={true}
										{...form.getInputProps("username")}
									/>
									{/* Password & Forgot */}
									<div className={Style.forgotPasswordBox}>
										<InputField
											type={"password"}
											label={"Password"}
											required={true}
											errorIconVisibility={true}
											successIconVisibility={true}
											placeholder={"10+ characters required"}
											{...form.getInputProps("password")}
										/>
										<Link to="/auth/forgot-password" className={Style.forgotPassword}>
											Forgot Password?
										</Link>
									</div>
									{/* Remember */}
									<input
										className="mt-1 me-2"
										type="checkbox"
										id="remember"
										defaultChecked={rememberMe}
										onChange={(e) => setRememberMe(e.target.checked)}
									/>
									<label htmlFor="remember" className={Style.remember}>Remember me</label>
									{/* Button Login */}
									<Button
										isLoading={loginApi.isLoading}
										onClick={() => loginApi.executeQuery(form.getApiBody())}
										disabled={!form.isValid()}
									>
										Sign in
									</Button>
									{/* Error */}
									<div className={Style.error}>{loginApi.isError && (loginApi.error.response.data.Error || "Something went wrong")}</div>
								</div>
							</div>
						</div>
					</section>
				)}
				{/* Otp */}
				{loginApi.isSuccess && <TwoFactor />}
			</AuthRoute>

		</>
	)
}

export default Login