import React, { useEffect } from "react";

// Libraries
import { useNavigate } from "react-router-dom";
import useQuery from "@hybris-software/use-query";
import useForm from "@hybris-software/use-ful-form";
import { InputField } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";

// Components
import Button from "../../Commons/Button/Button";
import AuthLoader from "../../AuthLoader/AuthLoader";

// Images
import LogoPoliant from "../../../Assets/img/logos/poliant_logo.webp";

// Validator
import ValidatePassword from "../../../Utils/validatePassword";

// Styles
import Style from "./ResetPassword.module.css";

const ResetPassword = () => {
  const navigate = useNavigate();

  useEffect(() => { document.title = "Poliant | Reset Password"; }, []);

  //+ Parameters code referral
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const codeExist = urlParams.get("token");

  //+ Call Reset Password 
  const resetPassword = useQuery({
    url: "accounts/forgot-password/confirm/",
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      navigate("/auth/login");
    },
    onError: () => {
    }
  });

  //+ Form datas 
  const form = useForm({
    inputs: {
      password: {
        nature: "password",
        required: true,
        errorOnEveryChange: true,
        validator: ValidatePassword,
      },
      confirmPassword: {
        nature: "confirmPassword",
        required: true,
        sendToApi: false,
        errorOnEveryChange: true,
      }
    }
  });

  const securityLevelPassword = form.getInputProps("password").errorDetails;

  return (
    <PermissionRoute
      forLoggedUser={false}
      loader={<AuthLoader />}
      unAuthorizedAction={() => navigate("/auth/login")}
      permissionController={() => {
        if (codeExist === null || codeExist === "") { return { value: false } }
        else { return { value: true } }
      }}
      forbiddenAction={() => navigate("/auth/login")}
    >
      <section>
        <img className={Style.logo} src={LogoPoliant} alt="Poliant logo" />

        <div className={Style.resetPassword}>
          <div className={Style.container}>
            <div className={Style.card}>

              <h2 className={Style.header}>Reset new password</h2>

              <InputField
                className={
                  securityLevelPassword !== null
                    ? Style[securityLevelPassword.security]
                    : Style.null
                }
                type="password"
                label={"New password"}
                placeholder={"10+ character required"}
                errorIconVisibility={true}
                successIconVisibility={true}
                required
                {...form.getInputProps("password")}
              />

              <InputField
                type="password"
                label={"Confirm password"}
                placeholder={"10+ character required"}
                errorIconVisibility={true}
                successIconVisibility={true}
                required={true}
                {...form.getInputProps("confirmPassword")}
              />

              <Button
                className={Style.button}
                disabled={!form.isValid()}
                isLoading={resetPassword.isLoading}
                onClick={() => resetPassword.executeQuery({ ...form.getApiBody(), token: codeExist })}
              >
                Reset Password
              </Button>
              <div className={Style.error}>{resetPassword.isError && (resetPassword.error.response.data.Error || "Something went wrong")}</div>
            </div>
          </div>
        </div>
      </section>
    </PermissionRoute>
  );
};

export default ResetPassword;
