import React, { useState, useEffect } from 'react'
import Style from './HeroReferral.module.css'
import { Row, Col, Container } from 'react-bootstrap'
import QrIcon from '../../../Assets/img/icon/qr-icon.png'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import Config from '../../../config.js'

const HeroReferral = () => {

    const [viewCard, setViewCard] = useState('');
    const [contractSigned, setContractSigned] = useState(null);

    const navigate = useNavigate();

    function openSetting() {
        if (contractSigned === true) {
            const layover = document.getElementById('layover');
            const settingReferral = document.getElementById('referral_setting');
            const mainContent = document.getElementsByClassName("main_content");
            mainContent[0].classList.add('unset_scroll');
            settingReferral.style.display = 'block';
            layover.style.display = 'block';
            document.getElementById('add_new_referral').style.display = 'none';
            document.getElementById('add_new_ref_layover').style.display = 'none';
        } else {
            const layover = document.getElementById('layover');
            const popupGoSign = document.getElementById("popupGoSign");
            popupGoSign.style.display = "block";
            layover.style.display = 'block';
        }
    }
    function openQr() {
        const mainContent = document.getElementsByClassName("main_content");
        mainContent[0].classList.add('unset_scroll');
        const layover = document.getElementById('layover');
        const qrCode = document.getElementById('qr_code');
        qrCode.style.display = 'block';
        layover.style.display = 'block';
    }
    function openQrBackbround() {
        const mainContent = document.getElementsByClassName("main_content");
        mainContent[0].classList.add('unset_scroll');
        const layover = document.getElementById('layover');
        const qrCodeBack = document.getElementById('qr_code_background');
        qrCodeBack.style.display = 'block';
        layover.style.display = 'block';
    }
    function copyToClipboard() {
        var copyText = document.getElementById("link_referral_to_copy").firstChild.nodeValue;
        navigator.clipboard.writeText(copyText);

        var tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Copied";
    }
    function outFunc() {
        var tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Copy to clipboard";
    }
    function copyToClipboardOne() {
        var copyText = document.getElementById("copy_id").firstChild.nodeValue;
        navigator.clipboard.writeText(copyText);

        var tooltip = document.getElementById("tool_copy");
        tooltip.innerHTML = "Copied";
    }
    function outFuncOne() {
        var tooltip = document.getElementById("tool_copy");
        tooltip.innerHTML = "Copy to clipboard";
    }

    useEffect(() => {
        if (localStorage.token) {
            const getViewData = async () => {
                const response = await axios.get(
                    Config.base_url + "referral/referral-page/",
                    { headers: { Authorization: `Token ${localStorage.getItem("token")}` } }
                );
                setViewCard(response.data.referralCard);
            };
            getViewData();
        } else {
            navigate('/auth/login');
        }

        const getSignatureCheck = async () => {
            const contractCheck = await axios.get(
                Config.base_url + "my-profile/header-info/",
                {
                    headers: {
                        Authorization: `Token ${localStorage.getItem("token")}`,
                    },
                }
            );
            setContractSigned(contractCheck.data.contract_status.Intermediary_Agreement);
        };
        getSignatureCheck();

    }, [navigate]);

    return (
        <>
            <section className={Style.referral_hero}>
                <Container>
                    <Row>
                        <Col className='d-flex justify-content-end'>
                            {/* //! TODO on click open add user */}
                            <Link to={'/add-new-user'}>
                                <button className={Style.add_user}>Add new user</button>
                            </Link>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col sm={12} md={12} lg={6}>
                            <div className={Style.box_title}>
                                <h2>Build your Club with Poliant. Earn Rewards</h2>
                                <p className='m-auto mt-5 mb-5'>Earn rewards for each Pooling transaction on Poliant.</p>
                                <a href="#!" className='text-primary'>Visualize referral rules</a>
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={6} className='mt-sm-3'>
                            <Row>
                                <Col className={Style.box_referral}>
                                    <Row>
                                        <Col className='d-flex justify-content-between'>
                                            <span>Default Referral ID</span>
                                            <span className={Style.link_referral}><span onClick={() => openSetting()}>Change referral settings</span></span>
                                        </Col>
                                    </Row>
                                    <Row className={Style.box_referral_values}>
                                        <Col sm={6} className={Style.data_values}>
                                            <div>You receive</div>
                                            <div>
                                                {viewCard.you_receive
                                                    ? (viewCard.you_receive).toFixed(1) + ' %'
                                                    : viewCard.you_receive === 0
                                                        ? '0.0 %'
                                                        : '*****'
                                                }
                                            </div>
                                        </Col>
                                        <Col sm={6} className={Style.data_values}>
                                            <div>Your members receive</div>
                                            <div>
                                                {viewCard.your_member_receive
                                                    ? viewCard.your_member_receive + '   %'
                                                    : '*****'
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className={Style.referral_id_box}>
                                        <Col sm={5} className={Style.data_values}>
                                            <div>Referral ID</div>
                                        </Col>
                                        <Col sm={7} className={Style.data_values}>
                                            <div id='copy_id' className={Style.box_referral_link}>
                                                {
                                                    viewCard.referral_id
                                                        ? viewCard.referral_id
                                                        : '*****'
                                                }
                                                <div className={Style.tooltip}>
                                                    <span className={Style.tooltiptext} id="tool_copy">Copy to clipboard</span>
                                                    <FontAwesomeIcon icon={faCopy} onClick={() => copyToClipboardOne()} onMouseOut={() => outFuncOne()} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className={Style.referral_id_box}>
                                        <Col sm={5} className={Style.data_values}>
                                            <div>Referral Link</div>
                                        </Col>
                                        <Col sm={7} className={Style.data_values_link}>
                                            <div id='link_referral_to_copy' className={Style.box_referral_link}>
                                                {
                                                    viewCard.referral_link
                                                        ? Config.base_url_referral + viewCard.referral_id
                                                        : '*****'
                                                }
                                                <div className={Style.tooltip}>
                                                    <span className={Style.tooltiptext} id="myTooltip">Copy to clipboard</span>
                                                    <FontAwesomeIcon icon={faCopy} onClick={() => copyToClipboard()} onMouseOut={() => outFunc()} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className={Style.button_box}>

                                        <Col sm={9} className={Style.data_values} onClick={() => { viewCard === 'You don\'t have any referrals yet' ? openSetting() : openQrBackbround() }}>
                                            <div>
                                                {(viewCard === 'You don\'t have any referrals yet')
                                                    ? 'Set Default referral'
                                                    : 'Join the Club'
                                                }
                                            </div>
                                        </Col>
                                        <Col sm={{ offset: 1, span: 2 }} className={Style.data_values} onClick={() => openQr()}>
                                            <img src={QrIcon} alt="" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    )
}

export default HeroReferral