import React, { useState, useEffect } from "react";

// Libraries
import { useNavigate } from "react-router-dom";
import { AuthRoute } from "@hybris-software/use-auth"

//Components
import AuthLoader from "../../AuthLoader/AuthLoader";

// Images
import LogoPoliant from "../../../Assets/img/logos/poliant_logo.webp";

// Styles
import Style from "./RegistrationSurvey.module.css";



import axios from "axios";
import Config from "../../../config.js";

const RegistrationSurvey = () => {
  const navigate = useNavigate();
  const [terms, setTerms] = useState(false);
  const [sendInvitation, setSendInvitation] = useState(false);

  const [question, setQuestion] = useState({
    firstQuestion: "",
    secondQuestion: "",
    thirdQuestion: "",
    fourthQuestion: "",
    fifthQuestion: "",
  });

  useEffect(() => {
    document.title = "Poliant | Registration Survey";
  }, []);

  let answers = {
    answer_1: question.firstQuestion,
    answer_2: question.secondQuestion,
    answer_3: question.thirdQuestion,
    answer_4: question.fourthQuestion,
    answer_5: question.fifthQuestion,
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setSendInvitation(true);

    const url = Config.base_url + "accounts/questionary/";

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(url, answers, config)
      .then((response) => {
        if (response.status === 201) {
          localStorage.removeItem("user_status");
          navigate("/auth/register-survey-sent");
        }
      })
      .catch((error) => {
        setSendInvitation(false);
      });
  };

  return (
    <AuthRoute
      forLoggedUser={true}
      loader={<AuthLoader />}
      action={() => { navigate("/") }}
    >
      <section>
        <img className={Style.logo} src={LogoPoliant} alt="Poliant logo" />

        <div className={Style.survey} id="registration_form">
          <div className={Style.container}>
            {/* Card */}
            <div className={Style.card}>

              <div className="text-center">
                <h1 className={Style.h1_registration}>Apply to join Poliant</h1>
              </div>

              {/* Form input survey */}
              <div>
                <div className="form">
                  {/* one */}
                  <label htmlFor="one" className={Style.question}>
                    How did you meet Poliant?
                  </label>
                  <div className="d-flex position-relative">
                    <textarea
                      name=""
                      id="one"
                      cols="20"
                      rows="3"
                      className={Style.form_control}
                      placeholder="Open answer..."
                      maxLength={20}
                      onChange={(e) => setQuestion(oldQuestion => ({
                        ...oldQuestion,
                        firstQuestion: e.target.value
                      })
                      )}
                    ></textarea>
                    <span className={Style.number_char}>
                      {question.firstQuestion.length}/20
                    </span>
                  </div>

                  {/* two */}
                  <label htmlFor="two" className={Style.question}>
                    For which products or services are you interested in
                    Poliant?
                  </label>
                  <div className="d-flex position-relative">
                    <textarea
                      name=""
                      id="two"
                      cols="20"
                      rows="3"
                      className={Style.form_control}
                      placeholder="Open answer..."
                      maxLength={20}
                      onChange={(e) => setQuestion(oldQuestion => ({
                        ...oldQuestion,
                        secondQuestion: e.target.value
                      })
                      )}
                    ></textarea>
                    <span className={Style.number_char}>
                      {question.secondQuestion.length}/20
                    </span>
                  </div>

                  {/* third */}
                  <label htmlFor="three" className={Style.question}>
                    Are you already in the crypto market? If yes, for how long?
                  </label>
                  <div className="d-flex position-relative">
                    <textarea
                      name=""
                      id="three"
                      cols="20"
                      rows="3"
                      className={Style.form_control}
                      placeholder="Open answer..."
                      maxLength={20}
                      onChange={(e) => setQuestion(oldQuestion => ({
                        ...oldQuestion,
                        thirdQuestion: e.target.value
                      })
                      )}
                    ></textarea>
                    <span className={Style.number_char}>
                      {question.thirdQuestion.length}/20
                    </span>
                  </div>

                  {/* fourth */}
                  <label htmlFor="four" className={Style.question}>
                    What do you do? What is your current profession?
                  </label>
                  <div className="d-flex position-relative">
                    <textarea
                      name=""
                      id="four"
                      cols="20"
                      rows="3"
                      className={Style.form_control}
                      placeholder="Open answer..."
                      maxLength={20}
                      onChange={(e) => setQuestion(oldQuestion => ({
                        ...oldQuestion,
                        fourthQuestion: e.target.value
                      })
                      )}
                    ></textarea>
                    <span className={Style.number_char}>
                      {question.fourthQuestion.length}/20
                    </span>
                  </div>

                  {/* five */}
                  <label htmlFor="five" className={Style.question}>
                    Why would DAO agree to let you join Poliant?
                  </label>
                  <div className="d-flex position-relative">
                    <textarea
                      name=""
                      id="five"
                      cols="20"
                      rows="3"
                      className={Style.form_control}
                      placeholder="Open answer..."
                      maxLength={20}
                      onChange={(e) => setQuestion(oldQuestion => ({
                        ...oldQuestion,
                        fifthQuestion: e.target.value
                      })
                      )}
                    ></textarea>
                    <span className={Style.number_char}>
                      {question.fifthQuestion.length}/20
                    </span>
                  </div>
                </div>
                {/* Terms*/}
                <div className="mt-3 mb-2">
                  <input
                    className="mt-3 m-2 mb-0 ms-0"
                    type="checkbox"
                    name=""
                    defaultChecked={terms}
                    onChange={(e) => setTerms(e.target.checked)}
                  />
                  <span className={Style.remember}>
                    I accept the{" "}
                    <a href="#!" className="text-primary">
                      Term and Conditions
                    </a>{" "}
                    and{" "}
                    <a href="#!" className="text-primary">
                      Policy Privacy
                    </a>
                  </span>
                </div>

                {question.firstQuestion !== "" &&
                  question.secondQuestion !== "" &&
                  question.thirdQuestion !== "" &&
                  question.fourthQuestion !== "" &&
                  question.fifthQuestion !== "" &&
                  terms === true
                  ? sendInvitation === false
                    ? <button id="registration_button_submit_reset" className={Style.sign_in_button} onClick={(e) => onSubmit(e)}>Send Application</button>
                    : <button id="registration_button_submit_reset" className={Style.sign_in_button}>Application sending...</button>
                  : <button disabled id="registration_button_submit_reset" className={Style.sign_in_button} type="submit">Send Application</button>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </AuthRoute >
  );
};

export default RegistrationSurvey;
