import React, { useEffect, useState } from "react";

// Libraries
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useQuery from "@hybris-software/use-query";
import { PermissionRoute } from "@hybris-software/use-auth";

// Components
import Loader from "../Components/Loader/Loader";
import QrCode from "../Components/Referral/QrCode/QrCode";
import HeroReferral from "../Components/Referral/HeroReferral/HeroReferral";
import ReferralTable from "../Components/Referral/ReferralTable/ReferralTable";
import ContractAccess from "../Components/Referral/ContractAccess/ContractAccess";
import SettingReferral from "../Components/Referral/SettingReferral/SettingReferral";
import QrCodeBackground from "../Components/Referral/QrCodeBackground/QrCodeBackground";
import AnalyticsReferral from "../Components/Referral/AnalyticsReferral/AnalyticsReferral";

const Referral = () => {
  const navigate = useNavigate();
  const [viewData, setViewData] = useState("");
  const [contractSigned, setContractSigned] = useState(null);
  // const [isBroker, setIsBroker] = useState(null);

  useEffect(() => { document.title = "Poliant | Referral"; }, []);

  const signatureCheck = useQuery({
    url: "my-profile/header-info/",
    method: "GET",
    executeImmediately: false,
    onSuccess: (data) => {
      setContractSigned(data.data.contract_status.Ip_Licensing_Agreement);

    }
  })
  //+ Call dati 
  const viewDataApi = useQuery({
    url: "referral/referral-page/",
    method: "GET",
    executeImmediately: false,
    onSuccess: (data) => {
      setViewData(data.data);
    }
  })

  return (
    <PermissionRoute
      forLoggedUser={true}
      loader={<Loader />}
      unAuthorizedAction={() => navigate("/auth/login")}
      permissionController={(data) => {
        if (data.data.status === "ACTIVE") {
          signatureCheck.executeQuery();
          viewDataApi.executeQuery();
          return { value: true }
        }
        else {
          return { value: false, status: data.data.status }
        }
      }}
      forbiddenAction={(data) => {
        if (data.status === "PENDING") { navigate("/auth/register-survey"); }
        if (data.status === "PENDING_SURVEYED") { navigate("/auth/register-survey-sent"); }
        if (data.status === "PENDING_EXTRA_DATA") { navigate("/auth/register-complete"); }
        if (data.status === "PENDING_TERMS") { navigate("/auth/accept-terms-and-conditions"); }
        if (data.status === "PENDING_NDA") { navigate("/auth/sign-non-disclosure-agreement"); }
        if (data.status === "DELETED") { navigate("/auth/login"); }
      }}
    >
      {
        contractSigned === false
          ? <ContractAccess />
          : viewData && (
            <>
              <SettingReferral />
              <QrCode />
              <QrCodeBackground />
              <Row>
                <HeroReferral />
                <AnalyticsReferral />
                <ReferralTable />
              </Row>
            </>
          )
      }
    </PermissionRoute>
  );
};

export default Referral;
