import React, { useState, useEffect } from "react";
import Style from "./Header.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faSignOut,
  faUser,
  faBell,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import SidebarMobile from "../Sidebar/SidebarMobile";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Config from "../../../config";

import Clock from "react-live-clock";

const Header = () => {
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState(null);
  const [bitcoin, setBitcoin] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [linkQr, setLinkQr] = useState(null);

  useEffect(() => {
    getAvatar(Config.base_url + "my-profile/header-info/");
    getBtc("https://gateway.poliant.com/api/v1/btc-usd/");
    setInterval(() => {
      getBtc("https://gateway.poliant.com/api/v1/btc-usd/");
    }, 120000);
  }, []);

  function getBtc(url) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios.get(url, config).then((response) => {
      if (response.status === 200) {
        setBitcoin((response.data.btc_usd).toFixed(2));
      }
    });
  }
  function getAvatar(url) {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };

    axios.get(url, config).then((response) => {
      if (response.status === 200) {
        setAvatar(response.data.header.avatar);
        setQrCode(response.data.default_referral.qrcode);
        setLinkQr(response.data.default_referral.link);
      }
    });
  }

  function logout() {
    localStorage.clear('');
    navigate("/auth/logout");
  }


  return (
    <>
      <header>
        <div className={Style.header_container + ' ps-0'}>
          <div className={isOpen ? "menu_open" : "menu_closed"}>
            <SidebarMobile setIsOpen={setIsOpen} />
          </div>

          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faBars}
              className={Style.hambuger_menu}
              onClick={() => setIsOpen(!isOpen)}
            />
            <div>
              <input
                id="search"
                type="search"
                className="form-control"
                placeholder="Search..."
              />
            </div>
          </div>

          <div className={Style.header_conversion_value}>
            <div className={Style.header_values}>
              <span>S.Time</span>
              <span className={Style.header_value}>
                <Clock
                  format={"HH:mm:ss"}
                  ticking={true}
                  timezone={"Asia/Dhaka"}
                  style={{ color: "white" }}
                  className={Style.header_value}
                />
                {/* {serverTime ? serverTime + " UTC" : "*****"} */}
              </span>
            </div>
            <div className={Style.header_values}>
              <span>POLI/USDT</span>
              <span className={Style.header_value}>1,00 USDT</span>
            </div>
            <div className={Style.header_values}>
              <span>
                <span style={{ fontSize: 11 }}>BTC</span> /{" "}
                <span style={{ fontSize: 11 }}>USD</span>
              </span>
              <span className={Style.header_value}>
                {bitcoin ? bitcoin : "*****"}
              </span>
            </div>
          </div>

          <div className={Style.section_profile}>

            <div
              id="codelink_menu"
              className="mt-1"
              onClick={(e) => showMenuProfile(e, 1)}
            >
              <FontAwesomeIcon icon={faLink} className={Style.links} id="svg_codelink" />
              <div
                className={Style.setting_popup_qrcode}
                id="codelink"
                onMouseUp={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className={Style.qr_code}>

                  <p className={Style.link_text}>{linkQr}</p>


                  <div className={Style.button_copy} onClick={() => navigator.clipboard.writeText(linkQr)}>
                    Copy this link
                  </div>

                  <hr className="text-white" />

                  <h2 className={Style.text}>QR Code</h2>
                  <p className={Style.text_body}>Scan this QR code to start connecting</p>

                  <div className={Style.body_qr}>
                    <img className={Style.qrcode_img} src={qrCode} alt="" />

                  </div>

                </div>
              </div>

            </div>

            <div className={Style.new_notify}>
              <FontAwesomeIcon icon={faBell} className={Style.notification} />
              {/* <div className="blob red position-absolute"></div> */}
            </div>
            <div
              id="profile_avatar"
              className={Style.profile_icon}
              onClick={(e) => showMenuProfile(e, 0)}
              style={
                avatar !== null
                  ? { backgroundImage: `url(${avatar})` }
                  : { backgroundColor: "#edf2f8" }
              }
            >
              <div
                className={Style.setting_popup_header}
                id="menu_header"
                onMouseUp={(e) => {
                  e.stopPropagation();
                }}
              >
                <h2 className="mb-3">SETTINGS</h2>
                <Link to="/my-profile">
                  <p>
                    <FontAwesomeIcon
                      icon={faUser}
                      className="me-2"
                      style={{ width: 20 }}
                    />
                    Profile
                  </p>
                </Link>
                <p onClick={() => logout()}>
                  <FontAwesomeIcon
                    icon={faSignOut}
                    className="me-2"
                    style={{ width: 20 }}
                  />
                  Logout
                </p>
              </div>

              <div id="status_account" className={Style.status_account}></div>
            </div>
          </div>
        </div>
      </header>
    </>
  );

  function showMenuProfile(e, position) {
    if (e.currentTarget.children[position].classList.contains("show_header")) {
      e.currentTarget.children[position].classList.remove("show_header");
    } else {
      e.currentTarget.children[position].classList.add("show_header");
    }
  }
};

export default Header;
