import React, { useState } from 'react'

// Libraries
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import useQuery from "@hybris-software/use-query"
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

// Components
import Loader from '../Loader/Loader'

// Images
import CoverImage from '../../Assets/img/bg-cover-profile.png'

// Utils
import axios from 'axios'
import Config from "../../config"

// Styles
import 'react-phone-number-input/style.css'
import Style from './AddNewUser.module.css'

//! TO CHANGE
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

const AddNewUser = () => {

    //+ Call to get the Min and Max Value % 
    useQuery({
        url: 'accounts/add-user/',
        method: 'GET',
        executeImmediately: true,
        onSuccess: (response) => {
            setRangeValue(response.data.perc_gain_min)
            setMinePercentage(response.data.perc_gain_min);
            setMaxPercentage(response.data.perc_gain_value);
        }
    })

    const [minePercentage, setMinePercentage] = useState('');
    const [maxPercentage, setMaxPercentage] = useState('');
    const [rangeValue, setRangeValue] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [username, setUsername] = useState('');

    const [phoneNumber, setPhoneNumber] = useState('');

    const [companyName, setCompanyName] = useState('');
    const [companyCity, setCompanyCity] = useState('');
    const [companyState, setCompanyState] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [companyCountryReg, setCompanyCountryReg] = useState('');
    const [companyVat, setCompanyVat] = useState('');
    const [passwordAutoGenerated, setPasswordAutoGenerated] = useState(false);
    const [individualNation, setIndividualNation] = useState('');
    const [individualCity, setIndividualCity] = useState('');
    const [individualState, setIndividualState] = useState('');
    const [individualAddress, setIndividualAddress] = useState('');
    const [individualZipCode, setIndividualZipCode] = useState('');
    const [percentageRefferals, setPercentageRefferals] = useState('');
    const [terms, setTerms] = useState(false);

    const [billingAddress, setBillingAddress] = useState('');
    const [billingCity, setBillingCity] = useState('');
    const [billingState, setBillingState] = useState('');
    const [billingZipCode, setBillingZipCode] = useState('');
    const [billingCountry, setBillingCountry] = useState('');

    // const [date, setDate] = useState(null);

    const [checked, setChecked] = useState('personal');
    const [step, setStep] = useState(1);
    const [billingCheck, setBillingCheck] = useState(false);

    var minPerc = (maxPercentage - rangeValue).toFixed(1) + ' %';

    const [errori, setErrori] = useState('');
    const [cretion, setCreation] = useState(false);

    const [checkUsername, setCheckUsername] = useState(null);

    let data = {};

    function callPostNewUser(data) {
        setCreation(true);

        const url = Config.base_url + "accounts/add-user/";
        const token = localStorage.getItem("token");

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Token ${token}`,
            },
        };

        axios
            .post(url, data, config)
            .then((response) => {
                if (response.status === 201) {
                    setStep(5);
                }
            })
            .catch((error) => {
                setCreation(false);
                for (let k in error.response.data) {
                    setErrori(k + ': ' + error.response.data[k][0]);
                }
            });
    }

    function createUser() {

        if (checked === 'personal') {
            data = {
                type: 'PERSONAL',
                username: username,
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phoneNumber,
                company_name: '',
                company_address: '',
                company_registration_country: '',
                vat: '',
                state: individualState,
                country: individualNation,
                city: individualCity,
                address: individualAddress,
                zipcode: individualZipCode,
                perc_gain_value: rangeValue,
                // contract_date: date,
            }
            if (billingCheck) {
                data.state_billing = billingState;
                data.country_billing = billingCountry;
                data.city_billing = billingCity;
                data.billing_address = billingAddress;
                data.zipcode_billing = billingZipCode;
            } else {
                data.state_billing = '';
                data.country_billing = '';
                data.city_billing = '';
                data.billing_address = '';
                data.zipcode_billing = '';
            }
        } else {
            data = {
                type: 'COMPANY',
                username: username,
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phoneNumber,
                company_name: companyName,
                company_address: companyAddress,
                company_registration_country: companyCountryReg,
                city: individualCity,
                state: individualState,
                vat: companyVat,
                zipcode: individualZipCode,
                perc_gain_value: rangeValue,
                // contract_date: date,
            }
        }
        callPostNewUser(data);
    }


    function isMail(e) {
        var pattern = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");
        return pattern.test(e);
    }

    // Imposta la visualizzazione di un nuovo indirizzo di pagamento
    function newAd() {

        const billingDifferentAddress = document.getElementById('billingaddressinfo');
        const newAddress = document.getElementById('billing_different');
        //se è checheck billingDifferentAddress allora rimuove la classe e lo mette block
        if (billingDifferentAddress.checked) {
            newAddress.classList.remove('d-none');
            newAddress.classList.add('d-block');
        } else {
            newAddress.classList.remove('d-block');
            newAddress.classList.add('d-none');
        }
    }

    const selectionIndividual = () => {
        setChecked('personal');
        setStep(1);
        setCompanyAddress('');
        setCompanyCity('');
        setCompanyCountryReg('');
        setCompanyName('');
        setCompanyState('');
        setCompanyVat('');
    }

    const selectionCompany = () => {
        setChecked('company');
        setStep(1);
        setIndividualAddress('');
        setIndividualCity('');
        setIndividualNation('');
        setIndividualState('');
        setIndividualZipCode('');
    }

    function controlUsername(e) {
        const usernameRegex = /^[A-Za-z0-9._-]+$/;
        return usernameRegex.test(e);
    }

    return (
        <>
            <div className='px-3 pt-2 mb-5 pb-5'>
                {/* Menu */}
                {step !== 5 && (
                    <Row className={Style.menuPoint}>
                        <Col sm={12} md={(checked === 'personal') ? 3 : 4}>
                            <div className={step === 1 ? Style.active : ''}>
                                <div className={Style.box_step}>
                                    <div>1</div>
                                    Profile
                                    <p></p>
                                </div>
                            </div>
                        </Col>
                        <Col md={3} className={checked === 'company' ? 'd-none' : 'd-block'}>
                            <div className={step === 2 ? Style.active : ''}>
                                <div className={Style.box_step}>
                                    <div>2</div>
                                    Billing address
                                    <p></p>
                                </div>
                            </div>
                        </Col>
                        <Col md={(checked === 'personal') ? 3 : 4}>
                            <div className={step === 3 ? Style.active : ''}>
                                <div className={Style.box_step}>
                                    <div>3</div>
                                    Referral rate
                                    <p></p>
                                </div>
                            </div>
                        </Col>
                        <Col md={(checked === 'personal') ? 3 : 4}>
                            <div className={step === 4 ? Style.active : ''}>
                                <div className={Style.box_step_confirm}>
                                    <div>4</div>
                                    Confermation
                                    <p></p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}

                {/* Box */}
                <section id='add_new_user'>
                    <div className='pt-2'></div>

                    {/* Step 1 */}
                    <div className={step === 1 ? 'd-block' : 'd-none'}>
                        <div className={Style.card_profile}>


                            {/* //?TYPE */}
                            <Row className='mb-4'>
                                <Col sm={3} className={Style.label_box}>
                                    <label className={Style.col_form_label}>Account type</label>
                                </Col>
                                <Col sm={9}>
                                    <div className="input-group input-group-sm-vertical">
                                        <label className={Style.form_control + ' mb-0 me-3'} htmlFor="personal">
                                            <span className="form-check">
                                                <input type="radio" className="form-check-input" defaultChecked name="userAccountTypeRadio" id="personal" onClick={() => selectionIndividual()} />
                                                <span className="form-check-label">Individual</span>
                                            </span>
                                        </label>
                                        <label className={Style.form_control + ' mb-0 ms-3'} htmlFor="company">
                                            <span className="form-check">
                                                <input type="radio" className="form-check-input" name="userAccountTypeRadio" id="company" onClick={() => selectionCompany()} />
                                                <span className="form-check-label">Company</span>
                                            </span>
                                        </label>
                                    </div>
                                </Col>
                                <Col sm={{ offset: 3, span: 9 }}>
                                    <hr className='mb-3 mt-5' />
                                </Col>
                            </Row>

                            {/* //? First name */}
                            <Row className='mb-4'>
                                <Col sm={3} className={Style.label_box}>
                                    {checked === 'company' ? (
                                        <label htmlFor="firstNameLabel" className={Style.col_form_label}>CEO Full name *</label>
                                    ) : <label htmlFor="firstNameLabel" className={Style.col_form_label}>Full name *</label>}
                                </Col>
                                <Col sm={9}>
                                    <div className="input-group input-group-sm-vertical">
                                        <input type="text" className={Style.form_control} onChange={(e) => setFirstName(e.target.value)} name="firstName" id="firstNameLabel" placeholder="Your first name" aria-label="Your first name" />
                                        <input type="text" className={Style.form_control + ' ms-3'} onChange={(e) => setLastName(e.target.value)} name="lastName" id="lastNameLabel" placeholder="Your last name" aria-label="Your last name" />
                                    </div>
                                </Col>
                            </Row>
                            {/* //? USername */}
                            <Row className='mb-4'>
                                <Col sm={3} className={Style.label_box}>
                                    <label htmlFor="usernameNew" className={Style.col_form_label}>Username *</label>
                                </Col>
                                <Col sm={9}>
                                    <div className="input-group input-group-sm-vertical">
                                        <input style={{ textTransform: 'lowercase' }} type="text" className={Style.form_control} onChange={(e) => setUsername(e.target.value.toLowerCase())} onInput={(e) => setCheckUsername(controlUsername(e.target.value))} name="usernameNew" id="usernameNew" placeholder="Insert your username" aria-label="Insert your username" />
                                    </div>
                                    {checkUsername === false && (
                                        <div className='error_input'>Can only contain letters, numbers and the following special characters: '-' , "_" , "."</div>
                                    )}
                                </Col>
                            </Row>

                            {checked === 'company'
                                ?
                                <>

                                    <Row className='mb-4'>
                                        <Col sm={3} className={Style.label_box}>
                                            <label htmlFor="companynameinformation" className={Style.col_form_label}>Company Name *</label>
                                        </Col>
                                        <Col sm={9}>
                                            <div className="input-group input-group-sm-vertical">
                                                <input type="text" className={Style.form_control} onChange={(e) => setCompanyName(e.target.value)} name="companynameinformation" id="companynameinformation" placeholder="Company LTD" aria-label="Company LTD" />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mb-4'>
                                        <Col sm={3} className={Style.label_box}>
                                            <label htmlFor="nationinfocompany" className={Style.col_form_label}>Company Address *</label>
                                        </Col>
                                        <Col sm={9}>
                                            <div className="input-group input-group-sm-vertical">
                                                <input type="text" className={Style.form_control} onChange={(e) => setCompanyAddress(e.target.value)} name="nationinfocompany" id="nationinfocompany" placeholder="Insert Company Address" aria-label="Insert Company Address" />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mb-4'>
                                        <Col sm={3} className={Style.label_box}>
                                            <label htmlFor='zipinfo' className={Style.col_form_label}>Zip code *</label>
                                        </Col>
                                        <Col sm={9}>
                                            <div className="input-group input-group-sm-vertical">
                                                <input type="text" className={Style.form_control} onChange={(e) => setIndividualZipCode(e.target.value)} name="zipinfo" id="zipinfo" placeholder="Your zip code" aria-label="Your zip code" />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mb-4'>
                                        <Col sm={3} className={Style.label_box}>
                                            <label htmlFor="companycountryreg" className={Style.col_form_label}>Company’s Country of Registration *</label>
                                        </Col>
                                        <Col sm={9}>
                                            <div className="input-group input-group-sm-vertical">
                                                <input type="text" className={Style.form_control} onChange={(e) => setCompanyCountryReg(e.target.value)} name="companycountryreg" id="companycountryreg" placeholder="Insert Company Country Registration" aria-label="Insert Company Country Registration" />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mb-4'>
                                        <Col sm={{ offset: 3, span: 9 }}>
                                            <div className="input-group input-group-sm-vertical">
                                                <input type="text" className={Style.form_control} onChange={(e) => setCompanyCity(e.target.value)} name="companycountryreg" id="companycountryreg" placeholder="Insert your City" aria-label="Insert your City" />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mb-4'>
                                        <Col sm={{ offset: 3, span: 9 }}>
                                            <div className="input-group input-group-sm-vertical">
                                                <input type="text" className={Style.form_control} onChange={(e) => setCompanyState(e.target.value)} name="companycountryreg" id="companycountryreg" placeholder="Insert your State" aria-label="Insert your State" />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mb-4'>
                                        <Col sm={3} className={Style.label_box}>
                                            <label htmlFor="vatnumberinfo" className={Style.col_form_label}>VAT Number *</label>
                                        </Col>
                                        <Col sm={9}>
                                            <div className="input-group input-group-sm-vertical">
                                                <input type="text" className={Style.form_control} onChange={(e) => setCompanyVat(e.target.value)} name="vatnumberinfo" id="vatnumberinfo" placeholder="Insert VAT Number" aria-label="Insert VAT Number" />
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                                :
                                null
                            }

                            {/* //?Email */}
                            <Row className='mb-4'>
                                <Col sm={3} className={Style.label_box}>
                                    <label htmlFor="emailinformation" className={Style.col_form_label}>Email *</label>
                                </Col>
                                <Col sm={9}>
                                    <div className="input-group input-group-sm-vertical">
                                        <input type="email" className={Style.form_control} onChange={(e) => setEmail(e.target.value)} onInput={(e) => { setEmailError(isMail(e.target.value)) }} name="emailinformation" id="emailinformation" placeholder="Insert your email" aria-label="Insert your email" />
                                    </div>
                                    <div className={!emailError ? 'd-block error_input' : 'd-none'}>{!emailError && email !== '' ? 'Invalid Email' : ''}</div>
                                </Col>
                            </Row>

                            {/* //? Phone */}
                            <Row className='mb-5'>
                                <Col sm={3} className={Style.label_box}>
                                    <label htmlFor="phoneinfo" className={Style.col_form_label}>Phone</label>
                                </Col>
                                <Col sm={9}>
                                    <PhoneInput
                                        id='phone'
                                        className=''
                                        placeholder="Prefix and Number"
                                        value={phoneNumber}
                                        onChange={setPhoneNumber}
                                        error={phoneNumber ? (isValidPhoneNumber(phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'} />
                                </Col>
                            </Row>


                            {/* Contract date Optional Admin */}
                            {/* <Row className='mb-4'>
                                <Col sm={3} className={Style.label_box}>
                                    <label htmlFor="datepick" className={Style.col_form_label}>Contract Date <span style={{ color: '#00000066' }}>(Temporary field for admin only)</span></label>
                                </Col>
                                <Col sm={9}>
                                    <div className="input-group input-group-sm-vertical">
                                        <input type="date" className={Style.form_control} onChange={(e) => setDate(e.target.value)} name="datepick" id="datepick" />
                                    </div>
                                </Col>
                            </Row> */}

                            <Row>
                                <Col sm={{ offset: 3, span: 9 }}>
                                    <div>
                                        <input className='mt-3 m-2 ms-0' type="checkbox" name="autogenpassword" id="autogenpassword" defaultChecked={passwordAutoGenerated} onChange={(e) => setPasswordAutoGenerated(e.target.checked)} />
                                        <span className={Style.remember_billing}>I am aware that the password will be automatically generated by the system</span>
                                    </div>
                                </Col>
                            </Row>



                            <Row className='mt-4 mb-2'>
                                <Col sm={12} className={Style.position_button}>

                                    {/* Riaggiungere ad entrambi && emailError === true */}

                                    {checked === 'personal'
                                        ?
                                        <>
                                            {firstName !== '' && lastName !== '' && username !== '' && email !== '' && passwordAutoGenerated === true && checkUsername === true
                                                ?
                                                <button className={Style.button} onClick={() => setStep(2)}>Next <FontAwesomeIcon icon={faChevronRight} /></button>
                                                :
                                                <button disabled className={Style.button} onClick={() => setStep(2)}>Next <FontAwesomeIcon icon={faChevronRight} /></button>
                                            }
                                        </>
                                        :
                                        <>
                                            {firstName !== '' && lastName !== '' && username !== '' && email !== '' && passwordAutoGenerated === true && companyName !== '' && companyAddress !== '' && companyCountryReg !== '' && companyCity !== '' && companyState !== '' && companyVat !== '' && individualZipCode !== '' && checkUsername === true
                                                ?
                                                <button className={Style.button} onClick={() => setStep(3)}>Next <FontAwesomeIcon icon={faChevronRight} /></button>
                                                :
                                                <button disabled className={Style.button} onClick={() => setStep(3)}>Next <FontAwesomeIcon icon={faChevronRight} /></button>
                                            }

                                        </>
                                    }

                                </Col>
                            </Row>

                        </div>

                    </div>

                    {/* Step 2 */}
                    <div className={step === 2 ? 'd-block' : 'd-none'}>

                        <div className={Style.card_profile}>
                            {checked === 'personal'
                                ? <>
                                    <Row className='mb-3 mt-3'>
                                        <Col sm={3} className={Style.label_box}>
                                            <label htmlFor="nationinfo" className={Style.col_form_label}>Your Residence Detail</label>
                                        </Col>
                                        <Col sm={9}>
                                            <div className="input-group input-group-sm-vertical">
                                                <input type="text" className={Style.form_control} onChange={(e) => setIndividualNation(e.target.value)} name="nationinfo" id="nationinfo" placeholder="Insert your country" aria-label="Insert your country" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Col sm={{ offset: 3, span: 9 }}>
                                            <div className="input-group input-group-sm-vertical">
                                                <input type="text" className={Style.form_control} onChange={(e) => setIndividualCity(e.target.value)} name="cityinfo" id="cityinfo" placeholder="Insert your City" aria-label="Insert your City" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mb-4'>
                                        <Col sm={{ offset: 3, span: 9 }}>
                                            <div className="input-group input-group-sm-vertical">
                                                <input type="text" className={Style.form_control} onChange={(e) => setIndividualState(e.target.value)} name="stateinfo" id="stateinfo" placeholder="Insert your state" aria-label="Insert your state" />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mb-5'>
                                        <Col sm={3} className={Style.label_box}>
                                            <label htmlFor="addressinfo" className={Style.col_form_label}>Address line 1 </label>
                                        </Col>
                                        <Col sm={9}>
                                            <div className="input-group input-group-sm-vertical">
                                                <input type="text" className={Style.form_control} onChange={(e) => setIndividualAddress(e.target.value)} name="addressinfo" id="addressinfo" placeholder="Insert your Address" aria-label="Insert your Address" />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mb-4'>
                                        <Col sm={3} className={Style.label_box}>
                                            <label htmlFor='zipinfo' className={Style.col_form_label}>Zip code</label>
                                        </Col>
                                        <Col sm={9}>
                                            <div className="input-group input-group-sm-vertical">
                                                <input type="text" className={Style.form_control} onChange={(e) => setIndividualZipCode(e.target.value)} name="zipinfo" id="zipinfo" placeholder="Your zip code" aria-label="Your zip code" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr className='mt-3' />


                                    {/* //+ Different billing address */}
                                    <Row className='mb-5'>
                                        <Col sm={{ offset: 3, span: 9 }}>
                                            <div>
                                                <input className='mt-3 m-2 ms-0' type="checkbox" defaultChecked={billingCheck} onChange={(e) => setBillingCheck(e.target.checked)} name="billingaddressinfo" id="billingaddressinfo" onClick={() => newAd()} />
                                                <span className={Style.remember_billing}>Invoicing data are difference as in residence</span>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div id='billing_different' className='d-none'>
                                        <Row className='mb-3'>
                                            <Col sm={3} className={Style.label_box}>
                                                <label htmlFor="newnationinfo" className={Style.col_form_label}>Your Billing Detail *</label>
                                            </Col>
                                            <Col sm={9}>
                                                <div className="input-group input-group-sm-vertical">
                                                    <input type="text" className={Style.form_control} onChange={(e) => setBillingCountry(e.target.value)} name="newnationinfo" id="newnationinfo" placeholder="Insert your country" aria-label="Insert your country" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='mb-3'>
                                            <Col sm={3} className={Style.label_box}></Col>
                                            <Col sm={9}>
                                                <div className="input-group input-group-sm-vertical">
                                                    <input type="text" className={Style.form_control} onChange={(e) => setBillingCity(e.target.value)} name="newcityinfo" id="newcityinfo" placeholder="Insert your city" aria-label="Insert your city" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='mb-4'>
                                            <Col sm={3} className={Style.label_box}></Col>
                                            <Col sm={9}>
                                                <div className="input-group input-group-sm-vertical">
                                                    <input type="text" className={Style.form_control} onChange={(e) => setBillingState(e.target.value)} name="newstateinfo" id="newstateinfo" placeholder="Insert your city" aria-label="Insert your city" />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className='mb-4'>
                                            <Col sm={3} className={Style.label_box}>
                                                <label htmlFor="newaddressinfo" className={Style.col_form_label}>Address line 1 *</label>
                                            </Col>
                                            <Col sm={9}>
                                                <div className="input-group input-group-sm-vertical">
                                                    <input type="text" className={Style.form_control} onChange={(e) => setBillingAddress(e.target.value)} name="newaddressinfo" id="newaddressinfo" placeholder="Insert your Address" aria-label="Insert your Address" />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className='mb-4'>
                                            <Col sm={3} className={Style.label_box}>
                                                <label htmlFor='newzipinfo' className={Style.col_form_label}>Zip code *</label>
                                            </Col>
                                            <Col sm={9}>
                                                <div className="input-group input-group-sm-vertical">
                                                    <input type="text" className={Style.form_control} onChange={(e) => setBillingZipCode(e.target.value)} name="newzipinfo" id="newzipinfo" placeholder="Insert zip code" aria-label="Insert zip code" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                                :
                                null
                            }

                            {/* //? NEXT & PREV */}
                            <Row className='mt-5 mb-2'>
                                <Col sm={12} className={Style.position_doublue_button}>

                                    <button className={Style.prev_button} onClick={() => setStep(1)}> <FontAwesomeIcon icon={faChevronLeft} /> Previous step</button>

                                    {!billingCheck
                                        ?
                                        <button className={Style.button} onClick={() => setStep(3)}>Next <FontAwesomeIcon icon={faChevronRight} /></button>
                                        : billingCountry !== '' && billingCity !== '' && billingState !== '' && billingZipCode !== '' && billingAddress !== ''
                                            ?
                                            <button className={Style.button} onClick={() => setStep(3)}>Next <FontAwesomeIcon icon={faChevronRight} /></button>
                                            :
                                            <button disabled className={Style.button} >Next <FontAwesomeIcon icon={faChevronRight} /></button>
                                    }
                                </Col>
                            </Row>

                        </div>


                    </div>

                    {/* Step 3 */}
                    <div className={step === 3 ? 'd-block' : 'd-none'}>

                        <div className={Style.card_profile}>
                            <Row className='mb-0 mt-3'>
                                <Col sm={3} className={Style.label_box}>
                                    <label htmlFor="rangenewreferral" className={Style.col_form_label}>Set the Rewards Ratio, Arbitrage Pool</label>
                                </Col>
                                <Col sm={9} className='d-flex align-items-center'>
                                    <div className="input-group input-group-sm-vertical">
                                        <input type="range" className={Style.range_input} step={0.1} min={minePercentage} max={maxPercentage} value={rangeValue} onChange={(e) => { setRangeValue(e.target.value); document.getElementById('memberInput').value = '' }}
                                            name="rangenewreferral" id="rangenewreferral" readOnly />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mb-5'>
                                <Col sm={{ offset: 3, span: 9 }} className={Style.mineAndMember}>
                                    <div className='d-flex align-items-center text-silver'>
                                        Mine:
                                        <input type="text" className={Style.input_set_percentage + ' form-control mx-2 text-silver bold-700'} readOnly placeholder={minPerc} />
                                        in POLI
                                    </div>
                                    <div className='d-flex align-items-center text-silver'>
                                        Member:
                                        <input id='memberInput' type="text" placeholder={rangeValue + ' %'} className={Style.input_set_percentage + ' form-control mx-2 bold-700 '} onChange={(e) => setRangeValue(e.target.value.replace(',', '.'))} />
                                        in POLI
                                    </div>
                                </Col>
                            </Row>

                            <Row className='mb-5'>
                                <Col sm={{ offset: 3, span: 9 }}>
                                    <div>
                                        <input className='mt-3 m-2 ms-0' type="checkbox" name="setvalueofrate" id="setvalueofrate" defaultChecked={percentageRefferals} onChange={(e) => setPercentageRefferals(e.target.checked)} />
                                        <span className={Style.remember_billing}>I am aware that this confirmation is final and irreversible and I will not be able to diminish the value of the Arbitration Pool subsequently</span>
                                    </div>
                                </Col>
                            </Row>
                            <hr />

                            {/* //? NEXT & PREV */}
                            <Row className='mt-4 mb-2'>
                                <Col sm={12} className={Style.position_doublue_button}>

                                    <button className={Style.prev_button} onClick={(checked === 'personal') ? () => setStep(2) : () => setStep(1)}> <FontAwesomeIcon icon={faChevronLeft} /> Previous step</button>


                                    {percentageRefferals === true
                                        ?
                                        <button className={Style.button} onClick={() => setStep(4)}>Next  <FontAwesomeIcon icon={faChevronRight} /></button>
                                        :
                                        <button disabled className={Style.button} onClick={() => setStep(4)}>Next  <FontAwesomeIcon icon={faChevronRight} /></button>
                                    }

                                </Col>
                            </Row>

                        </div>


                    </div>

                    {/* Step 4 */}
                    <div className={step === 4 ? 'd-block' : 'd-none'}>

                        <div className={Style.card_profile_add}>
                            <div className={Style.banner_confirmation_user} style={{ backgroundImage: `url(${CoverImage})` }}>
                                <div className={Style.circle_photo} style={{ backgroundImage: `url(${CoverImage})` }}>
                                </div>
                            </div>

                            <div className='error_input mt-3 mb-3 text-center'>{errori}</div>



                            <Row className='mb-3'>
                                <Col sm={6} className={Style.label_confirmed_user}>
                                    {checked === 'personal' ? 'Full name:' : 'CEO Full name'}
                                </Col>
                                <Col sm={6} className={Style.text_confirmed_user}>
                                    {firstName + ' ' + lastName}
                                </Col>
                            </Row>

                            <Row className='mb-3'>
                                <Col sm={6} className={Style.label_confirmed_user}>Username:</Col>
                                <Col sm={6} className={Style.text_confirmed_user}>{username}</Col>
                            </Row>

                            <Row className='mb-3'>
                                <Col sm={6} className={Style.label_confirmed_user}>Email:</Col>
                                <Col sm={6} className={Style.text_confirmed_user}>{email}</Col>
                            </Row>

                            {phoneNumber !== null && phoneNumber !== '' && phoneNumber !== undefined && (
                                <Row className='mb-3'>
                                    <Col sm={6} className={Style.label_confirmed_user}>Phone:</Col>
                                    <Col sm={6} className={Style.text_confirmed_user}>{phoneNumber}</Col>
                                </Row>
                            )}

                            <Row className='mb-3'>
                                <Col sm={6} className={Style.label_confirmed_user}>Account type:</Col>
                                <Col sm={6} className={Style.text_confirmed_user}>
                                    {checked === 'personal' ? 'Individual' : 'Company'}
                                </Col>
                            </Row>

                            {checked === 'company'
                                ? <>
                                    <Row className='mb-3'>
                                        <Col sm={6} className={Style.label_confirmed_user}>Company Name:</Col>
                                        <Col sm={6} className={Style.text_confirmed_user}>{companyName}</Col>
                                    </Row>
                                </>
                                : null
                            }

                            {checked === 'personal' && individualNation !== '' && (
                                <Row className='mb-3'>
                                    <Col sm={6} className={Style.label_confirmed_user}>
                                        Country:
                                    </Col>
                                    <Col sm={6} className={Style.text_confirmed_user}>
                                        {individualNation}
                                    </Col>
                                </Row>
                            )}

                            {checked === 'company' && companyCountryReg !== '' && (
                                <Row className='mb-3'>
                                    <Col sm={6} className={Style.label_confirmed_user}>
                                        Country of Registration:
                                    </Col>
                                    <Col sm={6} className={Style.text_confirmed_user}>
                                        {companyCountryReg}
                                    </Col>
                                </Row>
                            )}


                            {checked === 'personal' && individualCity !== '' && (
                                <Row Row className='mb-3'>
                                    <Col sm={6} className={Style.label_confirmed_user}>City:</Col>
                                    <Col sm={6} className={Style.text_confirmed_user}>
                                        {individualCity}
                                    </Col>
                                </Row>
                            )}

                            {checked === 'company' && companyCity !== '' && (
                                <Row Row className='mb-3'>
                                    <Col sm={6} className={Style.label_confirmed_user}>City:</Col>
                                    <Col sm={6} className={Style.text_confirmed_user}>
                                        {companyCity}
                                    </Col>
                                </Row>
                            )}

                            {checked === 'personal' && individualState !== '' && (
                                <Row className='mb-3'>
                                    <Col sm={6} className={Style.label_confirmed_user}>State:</Col>
                                    <Col sm={6} className={Style.text_confirmed_user}>
                                        {individualState}
                                    </Col>
                                </Row>
                            )}
                            {checked === 'company' && companyState !== '' && (
                                <Row className='mb-3'>
                                    <Col sm={6} className={Style.label_confirmed_user}>State:</Col>
                                    <Col sm={6} className={Style.text_confirmed_user}>
                                        {companyState}
                                    </Col>
                                </Row>
                            )}

                            {checked === 'personal' && individualAddress !== '' && (
                                <Row className='mb-3'>
                                    <Col sm={6} className={Style.label_confirmed_user}>Address line 1:</Col>
                                    <Col sm={6} className={Style.text_confirmed_user}>
                                        {individualAddress}
                                    </Col>
                                </Row>
                            )}
                            {checked === 'company' && companyAddress !== '' && (
                                <Row className='mb-3'>
                                    <Col sm={6} className={Style.label_confirmed_user}>Address line 1:</Col>
                                    <Col sm={6} className={Style.text_confirmed_user}>
                                        {companyAddress}
                                    </Col>
                                </Row>
                            )}

                            {individualZipCode !== '' && (
                                <Row className='mb-3'>
                                    <Col sm={6} className={Style.label_confirmed_user}>Zip code:</Col>
                                    <Col sm={6} className={Style.text_confirmed_user}>{individualZipCode}</Col>
                                </Row>
                            )}

                            {checked === 'company' && (
                                <>
                                    <Row className='mb-3'>
                                        <Col sm={6} className={Style.label_confirmed_user}>VAT ID:</Col>
                                        <Col sm={6} className={Style.text_confirmed_user}>{companyVat}</Col>
                                    </Row>
                                </>
                            )}

                            <Row className='mb-3'>
                                <Col sm={6} className={Style.label_confirmed_user}>Rewards Ratio:</Col>
                                <Col sm={6} className={Style.text_confirmed_user}>{rangeValue} % in POLI</Col>
                            </Row>

                            <Row className='mb-3 mt-5 pt-3 text-center'>
                                <Col sm={12}>
                                    <div>
                                        <input className='mt-3 m-2 ms-0' type="checkbox" name="setvalueofrate" id="setvalueofrate" defaultChecked={terms} onChange={(e) => setTerms(e.target.checked)} />
                                        <span className={Style.remember_billing}>I accept the <a href="#!" className='text-primary'>Term and Conditions</a> and <a href="#!" className='text-primary'>Policy Privacy</a></span>
                                    </div>
                                </Col>
                            </Row>
                            <hr />

                            {/* //? NEXT & PREV */}
                            <Row className='mt-4 mb-2'>
                                <Col sm={12} className={Style.position_doublue_button}>

                                    <button className={Style.prev_button} onClick={() => setStep(3)}> <FontAwesomeIcon icon={faChevronLeft} /> Previous step</button>

                                    {terms === true
                                        ? cretion
                                            ? <button disabled className={Style.button} >Creation...</button>
                                            : <button className={Style.button} onClick={() => createUser()}>Add User</button>
                                        :
                                        <button disabled className={Style.button} >Add User</button>
                                    }
                                </Col>
                            </Row>

                        </div>


                    </div>

                    {/* Step 5 */}
                    <div className={step === 5 ? 'd-block' : 'd-none'}>

                        <div className={Style.card_profile_add_finish}>
                            <Loader />
                            <div className='text-center pb-4'>
                                Account created successfully
                            </div>

                            <hr />

                            {/* //? NEXT & PREV */}
                            <Row className='mt-4 pt-4 mb-2'>
                                <Col sm={12} className={Style.position_doublue_button_finish}>
                                    <button className={Style.button + ' mx-3'} onClick={() => window.location.reload()}>Add new User</button>
                                    <Link to="/your-club"> <button className={Style.button + ' mx-3'}>Go to your Club</button></Link>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </section>
            </div >
        </>
    )

}

export default AddNewUser