import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Style from "./CoverProfile.module.css";
import CoverImage from "../../../Assets/img/bg-cover-profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { faCamera, faPencil } from "@fortawesome/free-solid-svg-icons";

import Config from "../../../config";

function apiPostAvatarVisibilityUpdate(status) {
  const data = {
    visibility: status,
  };
  const url =
    Config.base_url + "my-profile/avatar-visibility/";

  const token = localStorage.getItem("token");

  const config = {
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Token ${token}`,
    },
  };

  axios
    .post(url, data, config)
    .then((response) => {
    })
    .catch((error) => {
    });
}


const CoverProfile = (props) => {

  const [avatarUpdate, setAvatarUpdate] = useState(null);
  const [bannerUpdate, setBannerUpdate] = useState(null);



  const [avatarVisibility, setAvatarVisibility] = useState(
    props.avatarVisibility
  );


  function apiPostUploadNewAvatar(e) {

    var picture = e.target.files[0];

    var formdata = new FormData();
    formdata.append("avatar", picture);

    const url =
      Config.base_url + "my-profile/upload-avatar/";
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Token ${token}`,
      },
    };

    axios
      .post(url, formdata, config)
      .then((response) => {
        if (response.status === 200) {
          setAvatarUpdate(response.data.avatar);
        }
      })
  }


  function apiPostUploadNewBanner(e) {

    var picture = e.target.files[0];

    var formdata = new FormData();
    formdata.append("banner", picture);

    const url =
      Config.base_url + "my-profile/upload-banner/";
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Token ${token}`,
      },
    };

    axios
      .post(url, formdata, config)
      .then((response) => {
        if (response.status === 200) {
          setBannerUpdate(response.data.banner);
        }
      })

  }

  return (
    <>
      <div className={Style.cover_profile}>
        <div className={Style.cover_image}>
          {/* background */}
          <div
            className={Style.banner}
            style={

              bannerUpdate === null
                ?
                props.profileBanner
                  ? { backgroundImage: `url(${props.profileBanner})` }
                  : { backgroundImage: `url(${CoverImage})` }
                : { backgroundImage: `url(${bannerUpdate})` }
            }
          ></div>
          {/* Foto profilo  TODO onclick*/}
          <div
            className={Style.circle_photo}
            style={
              avatarUpdate === null
                ?
                props.avatarLink
                  ? { backgroundImage: `url(${props.avatarLink})` }
                  : { backgroundImage: `url(${CoverImage})` }
                : { backgroundImage: `url(${avatarUpdate})` }
            }
          >
            <div className={Style.change_photo}>
              <label htmlFor="upload-photo" id="label_upload_avatar" className="w-100">
                <FontAwesomeIcon icon={faPencil} />
              </label>
              <input type="file" accept="image/png, image/jpeg, image/jpg" name="photo" id="upload-photo" onChange={(e) => { apiPostUploadNewAvatar(e) }} />
            </div>
          </div>
          {/* Button upload */}
          <div className={Style.button_upload}>
            <label htmlFor="upload-banner" className="mb-0" id="label_upload_banner">
              <FontAwesomeIcon icon={faCamera} />
              <span className="ms-1">Upload banner</span>
            </label>
            <input type="file" accept="image/png, image/jpeg, image/jpg" name="banner" id="upload-banner" onChange={(e) => apiPostUploadNewBanner(e)} />
          </div>
        </div>
        <div className="mt-4 text-center">
          <h6 className={Style.name_profile}>
            {props.first_name + " " + props.last_name}
          </h6>

          <div className={Style.information_account}>
            <span className={Style.nickname}>@{props.username}</span>
            <span className="mx-3">{props.POLID}</span>
            <span>Joined: {props.joinedDate}</span>
          </div>
        </div>
        <div>
          <h4 className={Style.information_photo_visible}>
            Who can see your profile photo?
          </h4>
          <Row>
            <Col sm={5} className="d-flex justify-content-between">
              <Form.Select
                value={avatarVisibility}
                onChange={(e) => {
                  let avatarStatus;
                  if (e.target.value === "true") {
                    setAvatarVisibility(true);
                    avatarStatus = true;
                  } else {
                    setAvatarVisibility(false);
                    avatarStatus = false;
                  }
                  apiPostAvatarVisibilityUpdate(avatarStatus);
                }}
              >
                <option value={true}>Anyone</option>
                <option value={false}>Only You</option>
              </Form.Select>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default CoverProfile;
