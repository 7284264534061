import React from 'react'
import Style from './ReferralTable.module.css'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import { useTable, usePagination } from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from 'react-bootstrap'



const Styles = styled.div`
  padding: 1rem 0 0rem;
  overflow-x: auto;


  table {
    border-spacing: 0;
    border: 1px solid black;
    width: 100%;
    font-size: 13px;
    font-weight: 600;

    thead{
        tr {
            border-top: none;
            text-align: center;
    
          :last-child {
            td {
              border-bottom: 0;
            }
          }
        }
    
        th {
            border: none;
            background-color: #FAFAFA;
            color: black;
            font-weight: 600;
            border-radius: 5px;
            padding: 13px 10px;
            min-width: 120px;
            text-align: center;
    
            :first-child {
                min-width: 15px;
                text-align: left;
            }
            :last-child {
                text-align: center;
            }
            
        }
    }

    tbody{

        tr {
            border-top: 1px solid #1f232880;

            :first-child {
                border-top: none;
            }
    
    
          :last-child {
            td {
              border-bottom: 0;
            }
          }
        }

        td {
            padding: 10px 0 10px 10px;
            background-color: transparent;
            text-align: center;

            :first-child {
                padding-left: 0px;
                text-align: left;
            }
    
            :last-child {
                border-left: 1px solid #1f232880;
                text-align: center;
                padding-right: 10px;
            }
        }
        td img {
            height: 16px;
            margin-right: 7px;
        }
        td svg {
            height: 14px;
            margin-right: 4px;
        }
    }
  }
`
function Table({ columns, data }) {

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable({
        columns,
        data,
        initialState: { pageSize: 5 },
    },
        usePagination
    )

    // Render the UI for your table
    return (
        <>
            <table {...getTableProps()} style={{ border: 'none' }}>

                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps()}
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>

            </table>
            <div className="pagination_right">

                <div className='magic'>

                    <div>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                        </button>{' '}

                        <span>
                            {' '}<strong>
                                {pageIndex + 1}
                            </strong>{' '}
                        </span>

                        <button onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                        </button>{' '}
                    </div>

                </div>

            </div>
        </>
    )
}

const ReferralTable = () => {

    const data = React.useMemo(
        () => [
            {
                col1:
                    <>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-primary'>*********</span>
                        </div>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-secondary'>********</span>
                        </div>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-info'>**************</span>
                        </div>
                    </>
                ,
                col2: 'POL2459',
                /* //! numero in span maggiore di 1 cambia il colore  */
                col3:
                    <>
                        <span className='badge-table bg-primary mb-1 mt-1'>***********</span>
                    </>,
                col4: <><span>*****</span></>,
                col5: <><span>2021-05-30 10:50:45</span></>
            },
            {
                col1:
                    <>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-primary'>*********</span>
                        </div>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-secondary'>********</span>
                        </div>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-info'>**************</span>
                        </div>
                    </>
                ,
                col2: 'POL2459',
                /* //! numero in span maggiore di 1 cambia il colore  */
                col3:
                    <>
                        <span className='badge-table bg-silver mb-1 mt-1'>**********</span>
                    </>,
                col4: <><span>*****</span></>,
                col5: <><span>2021-05-30 10:50:45</span></>
            },
            {
                col1:
                    <>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-primary'>*********</span>
                        </div>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-secondary'>********</span>
                        </div>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-info'>**************</span>
                        </div>
                    </>
                ,
                col2: 'POL2459',
                /* //! numero in span maggiore di 1 cambia il colore  */
                col3:
                    <>
                        <span className='badge-table bg-gold mb-1 mt-1'>****************</span>
                    </>,
                col4: <><span>*****</span></>,
                col5: <><span>2021-05-30 10:50:45</span></>
            },
            {
                col1:
                    <>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-primary'>*********</span>
                        </div>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-secondary'>********</span>
                        </div>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-info'>**************</span>
                        </div>
                    </>
                ,
                col2: 'POL2459',
                /* //! numero in span maggiore di 1 cambia il colore  */
                col3:
                    <>
                        <span className='badge-table bg-silver mb-1 mt-1'>**************</span>
                    </>,
                col4: <><span>*****</span></>,
                col5: <><span>2021-05-30 10:50:45</span></>
            },
            {
                col1:
                    <>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-primary'>*********</span>
                        </div>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-secondary'>********</span>
                        </div>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-info'>**************</span>
                        </div>
                    </>
                ,
                col2: 'POL2459',
                /* //! numero in span maggiore di 1 cambia il colore  */
                col3:
                    <>
                        <span className='badge-table bg-primary mb-1 mt-1'>***************</span>
                    </>,
                col4: <><span>*****</span></>,
                col5: <><span>2021-05-30 10:50:45</span></>
            },
            {
                col1:
                    <>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-primary'>*********</span>
                        </div>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-secondary'>********</span>
                        </div>
                        <div className='mb-1 mt-1'>
                            <span className='badge-table bg-info'>**************</span>
                        </div>
                    </>
                ,
                col2: 'POL2459',
                /* //! numero in span maggiore di 1 cambia il colore  */
                col3:
                    <>
                        <span className='badge-table bg-primary mb-1 mt-1'>*************</span>
                    </>,
                col4: <><span>*****</span></>,
                col5: <><span>2021-05-30 10:50:45</span></>
            },
        ],
        []
    )
    const columns = React.useMemo(
        () => [
            {
                Header: 'Active Pools',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'ID Member',
                accessor: 'col2',
            },
            {
                Header: 'Rank Status',
                accessor: 'col3',
            },
            {
                Header: 'Rewards Earned (POLI)',
                accessor: 'col4',
            },
            {
                Header: 'Data',
                accessor: 'col5',
            },
        ],
        []
    )


    return (
        <>
            <section className={Style.referral_table}>
                <Container className='position-relative pb-5'>
                    <h2>Referral</h2>
                    <div className={Style.filter_table}>
                        <button>All Pool</button>
                        <button>Arbitrage Pool</button>
                        <button>Liquidity Pool</button>
                        <button>FollowTheWhale Pool</button>
                    </div>

                    <Styles>
                        <Table columns={columns} data={data} />
                    </Styles>

                    <div className={Style.filter_table_text}>
                        <Row className='w-100'>
                            <Col xs={9}>
                                All data from the last day are displayed, except for the mining pool data. For a detailed history of transitions, tap on 'Export'.
                            </Col>
                            <Col xs={3} className={Style.export_button}>
                                <p>
                                    <FontAwesomeIcon icon={faUpRightFromSquare} /> Export
                                </p>
                            </Col>
                        </Row>
                    </div>

                </Container>
            </section>
        </>
    )
}

export default ReferralTable